import { TempCheckFor } from '../enums/TempCheckFor'
import { getTextAMorPM } from './hotFoodUtils'

export const getInstructionTitle = (tempCheckFor: TempCheckFor) => {
  switch (tempCheckFor) {
    case TempCheckFor.CabinetFirstAir:
      return 'First air cabinet temperature check'
    case TempCheckFor.CabinetFollowing:
      return 'Cabinet temperature check'
    case TempCheckFor.TurboServeFirstAir:
      return `${getTextAMorPM()} Turbo-serve temperature check`
    case TempCheckFor.TurboServeRegular:
      return `${getTextAMorPM()} Turbo-serve temperature check`
    case TempCheckFor.TurboServeCompletedAM:
      return `You’ve already completed your AM temperature check. 
        Please wait until past 12:00 to complete your PM check.`
    case TempCheckFor.TurboServeCompletedToday:
      return 'You have no more checks to complete today.'
    default:
      return ''
  }
}

export const getInstructionDescription = (tempCheckFor: TempCheckFor) => {
  switch (tempCheckFor) {
    case TempCheckFor.CabinetFirstAir:
      return `The first air temperature check should be completed and recorded using an air probe to ensure 
        that the cabinet is at the correct temperature 
        before displaying the first batch of products. 
        This is an air temperature check which should reach 75°C. 
        Do not allow the wire probe to touch the shelf or cabinet casing.`
    case TempCheckFor.CabinetFollowing:
      return `One product from each of the four corners of the cabinet/window 
        must be temperature checked every two hours. 
        Only record a temperature if a product is present. 
        If no product is present, 
        select N/A in the relevant area. 
        Each product should be at a minimum of 63°C.`
    case TempCheckFor.TurboServeFirstAir:
    case TempCheckFor.TurboServeRegular:
      return `Air Temp checks must be completed daily AM and PM. Please record the temperature of each shelf
        using the wire air probe making sure that the probe does not touch the shelf, cabinet casing or
        product. We'll let you know if there's an issue with the recorded temperature.`
    case TempCheckFor.TurboServeCompletedAM:
    case TempCheckFor.TurboServeCompletedToday:
    default:
      return ''
  }
}
