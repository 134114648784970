import React, { useContext, useEffect, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { Delete, ErrorCircle } from '@jsluna/icons'
import {
  ButtonGroupSecondary,
  ButtonGroupWrapper,
  Card,
  Container,
  FilledButton,
  GridItem,
  IconButton,
  OutlinedButton,
  SelectField,
} from '@jsluna/react'
import { useApiClient } from '../../../common/AppContext/appContext'
import BackButton from '../../../common/components/BackButton/BackButton'
import ConfirmationBanner from '../../../common/components/Banners/ConfirmationBanner'
import ErrorBanner from '../../../common/components/Banners/ErrorBanner'
import { ErrorMessage } from '../../../common/enums/ErrorMessage'
import ErrorRefresh from '../../../common/components/ErrorRefresh'
import Header from '../../../common/components/Header'
import { GetHeaderMainMenus, headerMainMenus, headerPlannerTitles } from '../../../common/enums/HeaderItems'
import Loading from '../../../common/components/Loading'
import { addBannerMessage } from '../../../common/Context/commonDispatch'
import { Context } from '../../../common/Context/context'
import { Menu } from '../../../common/enums/MenuEnum'
import { IResult } from '../../../common/types/IResult'
import { getUserStore } from '../../../utils/localStore'
import { createBatch, deleteBatch, editBatch, getBatch, getSkusPerProgram } from '../../api/productionApi'
import { getStore } from '../../api/storeApi'
import { ProductTypeId } from '../../enums/ProductType'
import { IBatch } from '../../types/IBatch'
import { IBatchItem } from '../../types/IBatchItem'
import { IOptions } from '../../types/IOptions'
import { ISkusPerProgram } from '../../types/ISkusPerProgram'
import { IStore } from '../../types/IStore'
import { PlannerName } from '../../../common/enums/PlannerNameEnum'
import BatchTable from './BatchTable'
import DeleteBatchModal from './DeleteBatchModal'


const BatchView = (props: RouteComponentProps<{ id: string }>) => {
  const history = useHistory()
  const { dispatch } = useContext(Context)
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [isDeleteBatchModalOpen, setIsDeleteBatchModalOpen] = useState(false)
  const [isNew, setIsNew] = useState(false)
  const [programs, setPrograms] = useState<ISkusPerProgram>({})
  const [batch, setBatch] = useState<IBatch>({
    batchId: 0,
    isUserCreated: false,
    items: [],
    programDescription: '',
    programId: 0,
    storeId: 0,
    temperaturesCount: 0,
  })
  const [initialProgramId, setInitialProgramId] = useState(0)
  const [isBatchInvalid, setIsBatchInvalid] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [showZeroItems, setShowZeroItems] = useState(false)
  const [headerNavigationItems, setHeaderNavigationItems] = useState(headerMainMenus.hotfoodcounterNavs)

  const apiClient = useApiClient()
  const batchId = parseInt(props.match.params.id, 10)

  useEffect(() => {
    GetHeaderMainMenus(apiClient, getUserStore().storeId, PlannerName.HotFood).then((res) => setHeaderNavigationItems(res))
  }, [])

  useEffect(() => {
    const storeId = getUserStore().storeId

    const getSkusPerProgramTask = getSkusPerProgram(apiClient, storeId)
    const getStoreTask = getStore(apiClient, storeId)

    Promise.all([getStoreTask, getSkusPerProgramTask])
      .then((responses: [IStore, ISkusPerProgram]) => {
        setShowZeroItems(responses[0].showZeroItems)
        setPrograms(responses[1])

        // if "id" parameter doesn't exist then creating new batch, otherwise editing
        if (isNaN(batchId)) {
          setIsNew(true)
        } else {
          // get existing batch
          getBatch(apiClient, batchId)
            .then((existingBatch: IBatch) => {
              const batchItems: IBatchItem[] = []

              // create batch item for each SKU in the program.  Include any quantities that may exist
              if (responses[1][existingBatch.programId]) {
                responses[1][existingBatch.programId].skus.map((sku) => {
                  const existingBatchItem: IBatchItem | undefined =
                    existingBatch.items.find((s) => s.skuId === sku.skuId)

                  batchItems.push({
                    calculatedQuantity: existingBatchItem !== undefined ? existingBatchItem.calculatedQuantity : 0,
                    portionSize: existingBatchItem !== undefined ? existingBatchItem.portionSize : 0,
                    productTypeId: existingBatchItem !== undefined ? existingBatchItem.productTypeId : undefined,
                    quantityProduced: existingBatchItem !== undefined ? existingBatchItem.quantityProduced : 0,
                    quantitySold: 0,
                    skuId: sku.skuId,
                    skuName: sku.skuName,
                    toBeDisposedAt: new Date(),
                  })
                })
              }
              setBatch({ ...existingBatch, items: sortBatchItemsByProductType(batchItems) })
              setInitialProgramId(existingBatch.programId)
            })
            .catch(() => setIsError(true))
        }
      })
      .catch(() => setIsError(true))
      .finally(() => setIsLoading(false))
  }, [apiClient, batchId])

  // get options for "Please select a program" select box
  const programOptions = (): IOptions[] => {
    const keys: Array<{ id: string; description: string }> = []
    for (const key in programs) {
      if (Object.hasOwnProperty.call(programs, key)) {
        // add program option to list if creating new batch OR editing non-whole chicken program
        let add = isNew || (initialProgramId !== 1 && initialProgramId !== 13)

        // OR batch is Whole Chicken, in which case only add program 1 and/or 13
        add = add || ((initialProgramId === 1 || initialProgramId === 13) && (key === '1' || key === '13'))

        if (add) {
          keys.push({ id: key, description: programs[parseInt(key, 10)].programDescription })
        }
      }
    }

    return keys.map((key: { id: string; description: string }) => ({
      label: `${key.description}`,
      value: key.id,
    }))
  }

  // change value in "Please select a program" select box
  const onProgramChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const batchItems: IBatchItem[] = []
    let selectedProgramDescription = ''
    const programId = parseInt(event.target.value, 10)

    let originalValues: IBatchItem[] = []

    // only keep quantities for whole chicken programs
    if (initialProgramId === 1 || initialProgramId === 13) {
      originalValues = batch.items
    }

    if (programs[programId]) {
      programs[programId].skus.map((sku) => {
        batchItems.push({
          calculatedQuantity: 0,
          portionSize: 0,
          productTypeId: sku.productTypeId,
          quantityProduced: originalValues.filter((x) => x.skuId === sku.skuId)[0]?.quantityProduced ?? 0,
          quantitySold: 0,
          skuId: sku.skuId,
          skuName: sku.skuName,
          toBeDisposedAt: new Date(),
        })
      })

      selectedProgramDescription = programs[programId].programDescription
    }
    setBatch({
      ...batch,
      items: sortBatchItemsByProductType(batchItems),
      programDescription: selectedProgramDescription,
      programId,
    })
    setIsBatchInvalid(false)
  }

  // change SKU quantity
  const onSkuQuantityChange = (skuId: number, value: number) => {
    const updatedBatchItems = batch.items.reduce((acc: IBatchItem[], cur: IBatchItem) => {
      if (cur.skuId === skuId) {
        return[...acc, {...cur, quantityProduced: value}]
      }
      return [...acc, cur]
    }, [])

    setBatch({...batch, items: updatedBatchItems})
    setIsBatchInvalid(false)
  }

  const onBatchDelete = () => {
    setIsDeleteBatchModalOpen(false)

    deleteBatch(apiClient, batch.batchId)
      .then((res: IResult) => {
        if (res.isSuccess) {
          dispatch(addBannerMessage(
            <ConfirmationBanner message={`Batch '${batch.programDescription}' successfully deleted.`}/>
          ))
          history.push('/hotfoodview')
        } else {
          setIsError(true)
        }
      })
      .catch(() => setIsError(true))
  }

  const sortBatchItemsByProductType = (batchItems: IBatchItem[]) =>
    batchItems.sort((a, b) =>
      (a.productTypeId as ProductTypeId) - (b.productTypeId as ProductTypeId)
    )

  const onBatchSave = () => {
    setIsSaving(true)

    if (batch.items.filter((i) => i.quantityProduced !== 0).length === 0) {
      setIsBatchInvalid(true)
      setIsSaving(false)
      return
    }

    const store = getUserStore()

    if (isNew) {
      createBatch(apiClient, {
        items: batch.items.map((item) => ({
          productTypeId: item.productTypeId,
          quantityProduced: item.quantityProduced,
          skuId: item.skuId,
        })),
        programId: batch.programId,
        storeId: store.storeId,
      })
        .then((res: IResult) => {
          if (res.isSuccess) {
            dispatch(
              addBannerMessage(
                <ConfirmationBanner
                  message={`Batch '${batch.programDescription}' was successfully added
                  to your production plan.`}
                />
              )
            )
            history.push('/hotfoodview')
          } else {
            setIsError(true)
          }
        })
        .catch(() => setIsError(true))
        .finally(() => setIsSaving(false))
    } else {
      editBatch(apiClient, {
        batchId: batch.batchId,
        items: batch.items.map((item) => ({ quantityProduced: item.quantityProduced, skuId: item.skuId })),
        programId: batch.programId,
        storeCode: parseInt(store.storeCode, 10),
      })
        .then((res: IResult) => {
          if (res.isSuccess) {
            dispatch(addBannerMessage(
              <ConfirmationBanner message={`Batch '${batch.programDescription}' was successfully updated.`}/>
            ))
            history.push('/hotfoodview')
          } else {
            setIsError(true)
          }
        })
        .catch(() => setIsError(true))
        .finally(() => setIsSaving(false))
    }
  }

  const readOnlyProgram = () => !isNew && batch.isUserCreated === false && batch.programId > 0 &&
    initialProgramId !== 1 && initialProgramId !== 13

  return (
    <div className='c-common-main-view-content'>
      <Header
        title={headerPlannerTitles.hotfood}
        navItems={headerNavigationItems}
        activeMenuType={Menu.ProductionView}
      />
      <Container soft className='ln-u-push-top-sm'>
        {isLoading && <Loading />}
        {isError && <ErrorRefresh message={ErrorMessage.GeneralException} />}

        {!isLoading && (
          <>
            {isBatchInvalid &&
              <div className='ln-u-margin-bottom*3'>
                <ErrorBanner
                  icon={<ErrorCircle />}
                  message={`Please select at least one portion before ${isNew ? 'creating' : 'updating'} a batch.`}
                />
              </div>
            }

            {BackButton('/hotfoodview', 'Back to Production')}
            <h4>{isNew ? 'New batch' : 'Edit batch'}</h4>
            {isNew && (
              <div className='ln-u-body-1-fixed ln-u-push-bottom-sm'>
                You are creating a new batch. Select a cook program to see available SKU's and add them to your new
                batch.
              </div>
            )}

            <Card className='ln-u-margin-bottom'>
              <GridItem size={{ xs: '1/2', md: '1/3', lg: '1/5' }}>
                {!readOnlyProgram() && <SelectField
                  label='Please select a cook program'
                  name='programId'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => onProgramChange(e)}
                  placeholder='Select a program'
                  options={programOptions()}
                  value={batch.programId.toString()}
                />}

                {readOnlyProgram() &&
                  <h5>{`${programs[batch.programId].programDescription}`}</h5>}
              </GridItem>
            </Card>

            {batch.programId > 0 && (
              <Card>
                <BatchTable
                  batchItems={isNew || batch.isUserCreated === true || showZeroItems === true ? batch.items
                    : batch.items.filter((bi) => bi.calculatedQuantity > 0
                    || initialProgramId === 1 || initialProgramId === 13)}
                  isInvalid={isBatchInvalid}
                  onChange={(skuId: number, value: number) => onSkuQuantityChange(skuId, value)}
                />

                <ButtonGroupWrapper>
                  <ButtonGroupSecondary className='u-full-width'>
                    <FilledButton
                      disabled={isSaving}
                      onClick={() => onBatchSave()}
                    >
                      {isNew ? 'Create batch' : 'Update batch'}
                    </FilledButton>
                    <OutlinedButton className='ln-u-margin-left' onClick={() => history.push('/hotfoodview')}>
                      Cancel
                    </OutlinedButton>

                    {!isNew && (
                      <IconButton
                        className='c-batch-view-delete-button'
                        label='Delete batch'
                        onClick={() => setIsDeleteBatchModalOpen(true)}
                      >
                        <Delete />
                      </IconButton>
                    )}
                  </ButtonGroupSecondary>
                </ButtonGroupWrapper>
              </Card>
            )}

            {isDeleteBatchModalOpen && (
              <DeleteBatchModal
                handleClose={() => setIsDeleteBatchModalOpen(false)}
                handleSave={onBatchDelete}
                isOpen={isDeleteBatchModalOpen}
              />
            )}
          </>
        )}
      </Container>
    </div >
  )
}

export default BatchView
