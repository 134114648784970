import React from 'react'

interface IPlannerSectionHeader {
  headerMessage: string
}

const ProductionPlanningHeader = ({ headerMessage }: IPlannerSectionHeader) =>
  <header>
    <div data-testid='ppHeader' className='ln-c-header__inner'>
      <div className='ln-c-navigation-wrapper'>
        <div className='ln-o-container--soft ln-o-clearfix ln-u-hard-right'>
          <h1 className='c-common-planner-selection-title'>{headerMessage}</h1>
        </div>
      </div>
    </div>
  </header>

export default ProductionPlanningHeader
