import React, { useEffect, useState } from 'react'
import {
  ButtonGroupSecondary,
  ButtonGroupWrapper,
  FilledButton,
  GridItem,
  GridWrapper,
  Modal,
  ModalHeading,
  TextButton,
} from '@jsluna/react'
import { useApiClient } from '../../../common/AppContext/appContext'
import { ITemperatureData } from '../../types/ITemperatureData'
import { IInputModalProps } from '../../types/IInputModalProps'
import { BatchTray, CabinetWindow, TurboShelf } from '../../enums/TemperatureCheckPosition'
import { FormErrorMessageKey } from '../../enums/FormErrorMessageKey'
import TemperatureCheckErrorMessage from '../common/TemperatureCheckErrorMessage'
import { validateTemperatures } from '../../utils/hotFoodUtils'
import { ITemperatureCheckValidationProps } from '../../types/ITemperatureCheckValidationProps'
import { Feature } from '../../../common/enums/FeatureEnum'
import { isFeatureEnabled } from '../../../common/api/featureApi'
import { getUserStore } from '../../../utils/localStore'

const TemperatureCheckModal = (props: IInputModalProps) => {
  const storeId = getUserStore().storeId
  const [isHotFoodProbeFeature, setIsHotFoodProbeFeature] = useState(false)
  const [temperatures, setTemperatures] = useState<ITemperatureData[]>([])
  const [validation, setValidation] = useState<ITemperatureCheckValidationProps>({
    errorData: [],
    isLowReadingWarned: false,
    messageKey: FormErrorMessageKey.NONE,
    result: true,
  })

  const apiClient = useApiClient()
  const [isBluetoothReading, setIsBluetoothReading] = useState(true)

  useEffect(() => {
    const trayTemperatures: ITemperatureData[] = [{ position: BatchTray.Top, temperature: '' }]

    if (props.requiredTemperatureCount > 1) {
      trayTemperatures.push({ position: BatchTray.Middle, temperature: ''})
    }

    if (props.requiredTemperatureCount > 2) {
      trayTemperatures.push({ position: BatchTray.Bottom, temperature: '' })
    }
    setTemperatures(trayTemperatures)

    let script: HTMLScriptElement
    let probeEnabled = false

    isFeatureEnabled(apiClient, Feature.HotFoodProbe, storeId)
      .then((hotFoodProbeFeatureActive) => {
        probeEnabled = hotFoodProbeFeatureActive
        setIsHotFoodProbeFeature(hotFoodProbeFeatureActive)
        setIsBluetoothReading(hotFoodProbeFeatureActive)

        if (probeEnabled) {
          script = document.createElement('script')
          script.src = './probe.js'
          document.head.append(script)

          if ((window as Window).webkit && (window as Window).webkit!.messageHandlers.bluetoothMessage) {
            (window as Window).webkit!.messageHandlers.bluetoothMessage.postMessage('start')
          }
        }
      })
      .catch(() => {
        probeEnabled = false
        setIsHotFoodProbeFeature(probeEnabled)
      })


    return () => {
      if (probeEnabled) {
        document.head.removeChild(script)
        if ((window as Window).webkit && (window as Window).webkit!.messageHandlers.bluetoothMessage) {
          (window as Window).webkit!.messageHandlers.bluetoothMessage.postMessage('stop')
        }
      }
    }
  }, [apiClient, props.requiredTemperatureCount, storeId])

  const renderTemperatureInputText = (position: BatchTray | CabinetWindow | TurboShelf) =>
    <div className={(validation.errorData.filter((t) => t.position === position).length > 0 &&
      'ln-c-form-group c-temperature-modal-input-field has-error') || ''}>
      <input
        autoComplete='off'
        className={'c-form-input-content ln-c-text-input ln-c-form-group'}
        id={`temperature-input-${position}`}
        data-testid={`temperature-input-${position}`}
        onChange={(e: React.FormEvent<HTMLInputElement>) => handleTemperatureValidation(e, position)}
        placeholder='--.- ℃'
        type='number'
        value={getUpdatedTemperatureValue(position)}
        readOnly={isBluetoothReading}
      />
    </div>


  // validate the temperature in a xx.x format and each dynamic temperature inputs are updated on the matching object.
  const handleTemperatureValidation = (
    e: React.FormEvent<HTMLInputElement>,
    position: BatchTray | CabinetWindow | TurboShelf
  ) => {
    const targets = temperatures.reduce((accTemperatures: ITemperatureData[], current) => {
      if (current.position === position) {
        const matches = e.currentTarget.value.match(/(\d{0,3}[.]\d)|(\d{0,3}[.])|([\d]{1,3})/)

        if (!matches || matches.length === 0) {
          current.temperature = ''
          return [...accTemperatures, current]
        }

        if (parseFloat(e.currentTarget.value) <= 140.0) {
          current.temperature = matches[0]
        }

        return [...accTemperatures, current]
      }

      return [...accTemperatures, current]
    }, [])
    setTemperatures(targets)
  }

  const getUpdatedTemperatureValue = (position: BatchTray | CabinetWindow | TurboShelf) => {
    const targetData = temperatures.find((t) => t.position === position)
    if (!targetData) {
      return ''
    }

    return targetData.temperature
  }

  const onSave = async () => {
    const [errorData, messageKey, result] = validateTemperatures(temperatures)
    setValidation({
      errorData: errorData as ITemperatureData[],
      isLowReadingWarned: (!validation.isLowReadingWarned &&
        !result &&
        messageKey === FormErrorMessageKey.Under20DegTemperature),
      messageKey: messageKey as FormErrorMessageKey,
      result: result as boolean,
    })

    if (!result && !validation.isLowReadingWarned) {
      return
    }
    await props.handleSave(temperatures, isBluetoothReading)
  }

  const switchInputModeToBluetooth = (bool: boolean) => {
    setIsBluetoothReading(bool)
    const updatedTemperatureDataArray = temperatures.map((item) => ({
      ...item,
      temperature: '',
    }))
    setTemperatures(updatedTemperatureDataArray)
  }

  return (
    <Modal
      small
      handleClose={() => props.handleClose()}
      open={props.isOpen}
      headingId='info-modal'
    >
      <ModalHeading data-testid='modalTitle' element='h4'>
        {props.title}
      </ModalHeading>

      <div>
        {isHotFoodProbeFeature && <div className='ln-u-text-align-center ln-u-margin-bottom*2'>
          <span id='probe-status' data-testid='probe-status'></span>
        </div>}

        <div data-testid='modalDescription' className='ln-u-margin-bottom*2' >
          {props.description}
        </div>

        {isHotFoodProbeFeature && <div>
          <div className='ln-u-text-align-center'>
            { isBluetoothReading ?
              <>
                <div data-testid='manual-input-hint' className='ln-c-label ln-u-font-weight-bold'>
                  Can't use your Bluetooth probe?
                </div>
                <br />
                <TextButton data-testid='manual-input-button' onClick={() => switchInputModeToBluetooth(false)}>
                  Change to manual checks
                </TextButton>
              </>
              :
              <TextButton data-testid='bluetooth-input-button' onClick={() => switchInputModeToBluetooth(true)}>
                Change back to Bluetooth checks
              </TextButton>
            }
          </div>
        </div>}

        <div
          className='ln-u-text-align-center ln-u-padding-top ln-u-padding-bottom ln-u-padding-right ln-u-padding-left'
        >
          {temperatures.map((item, index) =>
            <div key={index}>
              {index < props.requiredTemperatureCount &&
                <GridItem
                  className='c-form-input-center'
                  size={{ xs: '1/1', sm: '1/1', md: '1/3', lg: '1/3' }}
                >
                  {renderTemperatureInputText(item.position)}
                </GridItem>
              }
            </div>
          )}
        </div>

        {!validation.result &&
          <TemperatureCheckErrorMessage
            class='ln-u-font-weight-bold ln-u-text-align-left ln-u-padding-left'
            messageKey={validation.messageKey}
          />}

        <ButtonGroupWrapper className='ln-u-text-align-center ln-u-padding-bottom ln-u-padding-right ln-u-padding-left'>
          <ButtonGroupSecondary className='u-full-width'>
            <GridWrapper>
              <GridItem size={{ default: '1/1', xs: '1/2', sm: '1/2', md: '1/2', lg: '1/2' }}>
                <FilledButton
                  className='c-form-buttom-center c-submit-modal-button u-full-width'
                  onClick={() => onSave()}
                >
                  Save
                </FilledButton>
              </GridItem>
            </GridWrapper>
          </ButtonGroupSecondary>
        </ButtonGroupWrapper>
      </div>
    </Modal>
  )
}

export default TemperatureCheckModal
