import { useMsal } from '@azure/msal-react'
import { createContext, useContext } from 'react'
import { ApiClient } from '../api/apiClient'

interface AppContextType {
  apiClient: ApiClient
}

const AppContext = createContext<AppContextType | null>(null)

interface AppProviderProps {
  apiScope: string
  children: React.ReactNode
}

export const AppProvider: React.FC<AppProviderProps> = ({ apiScope, children }) => {
  const { instance } = useMsal()
  const apiClient = new ApiClient(
    instance,
    apiScope
  )

  return <AppContext.Provider value={{ apiClient }}> {children} </AppContext.Provider>
}

export const useApiClient = () => {
  const context = useContext(AppContext)

  if (!context) {
    throw new Error('useApiClient must be used within an AppProvider')
  }

  return context.apiClient
}
