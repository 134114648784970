/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { datadogLogs } from '@datadog/browser-logs'
import { getUserStore } from '../../utils/localStore'

const loogerObject = {
  name: 'productionplanning-app',
  service: 'productionplanning-frontend',
}

const loggers = {
  error: (message: string, object: object) => {
    const storeInfo = getUserStore()
    datadogLogs.logger.error(
      `ddlogger-error : locId(${storeInfo?.storeCode}) - ${message}`,
      {...loogerObject, object, localStorage}
    )
  },
  info: (message: string, object: object) => {
    datadogLogs.logger.info(
      `ddlogger-info ${message}`,
      {...loogerObject, object}
    )
  },
}

export default loggers
