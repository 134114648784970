import React, { useState } from 'react'
import {
  ButtonGroupSecondary,
  ButtonGroupWrapper,
  FilledButton,
  GridItem,
  GridWrapper,
  Modal,
  ModalHeading,
  OutlinedButton,
  RadioButtonField,
  TextInputField,
} from '@jsluna/react'
import { ErrorCircle } from '@jsluna/icons'

interface IProps {
  handleClose: () => void
  handleSave: (reasonId: number, reasonDescription: string) => Promise<void>
  isOpen: boolean
}

const CloseCounterModal = (props: IProps) => {
  const [reasonId, setReasonId] = useState(0)
  const [reasonDescription, setReasonDescription] = useState('')
  const [error, setError] = useState('')

  const isValid = (): boolean => {
    if (reasonId === 0) {
      setError('Please select a reason before closing the counter')
      return false
    }

    if (reasonId === 99 && reasonDescription === '') {
      setError("Please describe why you're closing the counter")
      return false
    }

    return true
  }

  const onReasonChange = (event: React.FormEvent<HTMLSelectElement>) => {
    setReasonId(parseInt(event.currentTarget.value, 10))
    setError('')
  }

  const onReasonDescriptionChange = (event: React.FormEvent<HTMLInputElement>) => {
    setReasonDescription(event.currentTarget.value)
    setError('')
  }

  const onSave = async () => {
    if (isValid()) {
      await props.handleSave(reasonId, reasonDescription)
    }
  }

  return (
    <Modal
      large
      handleClose={() => props.handleClose()}
      open={props.isOpen}
      headingId='info-modal'
    >
      <ModalHeading element='h4'>
        Set counter to closed
      </ModalHeading>

      <div>
        You DO NOT need to close the counter at the end of every shift, this function should only be used in
        extraordinary situations. Please pick a reason for closing the counter from the options below:

        <div className='ln-u-margin-top*2 c-closure-reason-radio'>
          <RadioButtonField
            fullWidth
            label='Reason for closing the counter'
            name='reason'
            onChange={(e: React.FormEvent<HTMLSelectElement>) => onReasonChange(e)}
            options={[
              { label: 'No colleague available', value: 1 },
              { label: 'Emergency in store', value: 2 },
              { label: 'Other', value: 99 },
            ]}
          />
          {reasonId === 99 &&
            <TextInputField className={error.length > 0 ? 'ln-c-form-group has-error' : ''}
              name='other-reason'
              label='Why did you close the counter?'
              onChange={(e: React.FormEvent<HTMLInputElement>) => onReasonDescriptionChange(e)}
              placeholder='Describe your issue'
            />
          }
        </div>

        {error.length !== 0 &&
          <div className='ln-u-font-weight-bold'>
            <ErrorCircle className='ln-u-color-red ln-u-margin-right' />{error}
          </div>}
      </div>

      <ButtonGroupWrapper>
        <ButtonGroupSecondary className='u-full-width'>
          <GridWrapper>
            <GridItem
              size={{ xxs: '1/1', xs: '1/2' }}>
              <FilledButton
                className='u-full-width'
                onClick={() => onSave()}>
                Close counter
              </FilledButton>
            </GridItem>

            <GridItem
              className='c-batch-card-modal-secondary-button'
              size={{ xxs: '1/1', xs: '1/2' }}>
              <OutlinedButton
                className='u-full-width'
                onClick={() => props.handleClose()}>
                Cancel
              </OutlinedButton>
            </GridItem>
          </GridWrapper>
        </ButtonGroupSecondary>
      </ButtonGroupWrapper>
    </Modal>
  )
}

export default CloseCounterModal
