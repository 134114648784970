import React from 'react'
import Banner from './Banner'

interface IErrorBanner {
  icon: JSX.Element
  message: string
  messageObject?: JSX.Element
}

const ErrorBanner = ({ message, icon, messageObject }: IErrorBanner) =>
  <Banner
    message={message}
    icon={icon}
    className='c-common-error-banner'
    messageObject={messageObject}
  />

export default ErrorBanner
