import React from 'react'
import { useHistory } from 'react-router-dom'
import { ButtonGroupWrapper, Card, OutlinedButton } from '@jsluna/react'

const AddNewBatchCard = () => {
  const history = useHistory()

  const renderButtons = () =>
    <ButtonGroupWrapper>
      <OutlinedButton className="ln-u-margin-left*3"
        onClick={() => history.push('/hotfoodbatch')}
      >
        Add new batch
      </OutlinedButton>
    </ButtonGroupWrapper>

  return (
    <Card className='ln-u-margin-bottom*2 ln-u-margin-right*2 ln-u-hard c-batch-card'>
      <div className='ln-u-padding-bottom ln-u-padding-right*3 ln-u-soft-top'>
        <div className='ln-c-label ln-u-font-weight-bold ln-u-align-items-center ln-u-padding-top*2
          ln-u-padding-left*3'>
          Create a batch from scratch
        </div>
        {renderButtons()}
      </div>
    </Card>
  )
}

export default AddNewBatchCard
