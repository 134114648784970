import { ApiClient } from '../../common/api/apiClient'
import { PlannerName } from '../../common/enums/PlannerNameEnum'
import { IResult } from '../../common/types/IResult'
import { getUserStore } from '../../utils/localStore'
import { IPicklist } from '../types/IPicklist'
import IPicklistUpdate from '../types/IPicklistUpdate'

const getPicklist = async (apiClient: ApiClient, orderDate: Date): Promise<IPicklist> =>
  await apiClient.get<IPicklist>(PlannerName.Bakery, `picklist/${getUserStore().storeId}/${orderDate.toISOString()}`)

const updatePicklist = async (apiClient: ApiClient, data: IPicklistUpdate) => {
  const body = `${JSON.stringify(data)}`
  const result = await apiClient.post(PlannerName.Bakery, 'picklist/update', body)
  return await result.json() as IResult
}

export { getPicklist, updatePicklist }
