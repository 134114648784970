import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import 'core-js/es/array/find'
import React from 'react'
import 'react-app-polyfill/ie11'
import ReactDOM from 'react-dom'
import App from './App'
import { msalConfig } from './authConfig'
import './scss/index.scss'
import * as serviceWorker from './serviceWorker'
import { AppProvider } from './common/AppContext/appContext'
import { apiConfig } from './apiConfig'
import initDatadog from './common/Datadog/setup'

// create PublicClientApplication instance
const msalInstance = new PublicClientApplication(msalConfig)

initDatadog()

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <AppProvider apiScope={apiConfig.apiClientScope as string}>
      <App />
    </AppProvider>
  </MsalProvider>, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
void serviceWorker.unregister()
