import { Minus, Plus } from '@jsluna/icons'
import { IconButton, TextInputField } from '@jsluna/react'
import React from 'react'
import IMixSku from '../../types/IMixSku'
import { selectCurrentTarget } from '../../../utils/selectCurrentTarget'

interface IProps {
  mixSku: IMixSku
  setActualProduction: (mixSkuId: number, isIncrement: boolean, productionActual?: number) => void
}

const MixSkusIncrementor = (props: IProps) => {
  const { mixSku } = props

  const isMinusDisabled = (productionActual: number) => productionActual === 0
  const isPlusDisabled = (productionActual: number) => productionActual >= 999

  const withinValue = (event: React.FormEvent<HTMLInputElement>, mixSkuCheck: IMixSku) => {
    const regexZeroToThreeDigitsOnly = /^\d{0,3}$/
    if (regexZeroToThreeDigitsOnly.test(event.currentTarget.value) === true) {
      props.setActualProduction(mixSkuCheck.mixSkuId, false, +event.currentTarget.value)
    }
  }

  return (
    <div className='c-common-incrementor'>
      <div className='c-common-incrementor__button'>
        <IconButton variant='outlined' label='Increment actual production' hideLabel
          disabled={isMinusDisabled(mixSku.productionActual)}
          onClick={() => props.setActualProduction(mixSku.mixSkuId, false)}
        >
          <Minus />
        </IconButton>
      </div>
      <div className='c-common-incrementor__input-text-box'>
        <TextInputField
          name='actualProductionInput'
          autoComplete='off'
          onClick={selectCurrentTarget}
          type='number'
          onChange={(e: React.FormEvent<HTMLInputElement>) => withinValue(e, mixSku)}
          value={mixSku.productionActual.toString()}
          className='ln-u-flush-bottom'
        />
      </div>
      <div className='c-common-incrementor__button'>
        <IconButton variant='outlined' label='Decrement actual production' hideLabel
          disabled={isPlusDisabled(mixSku.productionActual)}
          onClick={() => props.setActualProduction(mixSku.mixSkuId, true)}
        >
          <Plus fixed />
        </IconButton>
      </div>
    </div>
  )
}

export default MixSkusIncrementor
