import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { clearScrollPosition, setScrollPosition } from '../../store/scrollPositions/actions'
import ScrollToPrevious from './ScrollToPrevious'

const mapDispatchToProps = (dispatch: Dispatch) =>
  ({
    clearScrollPosition: (className: string) =>
      dispatch(clearScrollPosition(className)),
    setScrollPosition: (className: string, position: number) =>
      dispatch(setScrollPosition({ className, position })),
  })

const mapStateToProps = (state: any) =>
  ({
    scrollPositions: state.scrollPositionsReducer.scrollPositions,
  })

export default connect(mapStateToProps, mapDispatchToProps)(ScrollToPrevious)
