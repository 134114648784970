import { Tick } from '@jsluna/icons'
import React from 'react'
import Banner from './Banner'

interface IConfirmationBanner {
  message: string
}

const ConfirmationBanner = ({ message }: IConfirmationBanner) =>
  <Banner
    message={message}
    icon={<Tick />}
    className='c-common-confirmation-banner'
  />

export default ConfirmationBanner
