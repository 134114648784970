import React from 'react'
import { ErrorCircle } from '@jsluna/icons'
import {
  ButtonGroupSecondary,
  ButtonGroupWrapper,
  FilledButton,
  FlagComponent,
  FlagWrapper,
  GridItem,
  GridWrapper,
  Modal,
  ModalHeading,
} from '@jsluna/react'
import { AlertLevelEnum } from '../../enums/AlertLevelEnum'
import { IAlertModalProps } from '../../types/IAlertModalProps'
import { TemperatureAlertMessages } from '../../utils/TemperatureAlertMessages'

const TemperatureBoostAlertModal = (props: IAlertModalProps) => {
  const getMessageContent = (alertLevel: AlertLevelEnum | undefined) => {
    const message = TemperatureAlertMessages.find((m) => m.alertLevel === alertLevel)
    return (
      <>
        {message && (
          <>
            <ModalHeading data-testid='modalTitle' element='h4'>

              <FlagWrapper>
                {(alertLevel === AlertLevelEnum.Warning) &&
                  <FlagComponent className='ln-u-margin-top*2 ln-u-margin-right'>
                    <ErrorCircle className='c-form-alert-icon-red ln-u-display-6-fixed' />
                  </FlagComponent>
                }
                <FlagComponent className='ln-u-font-weight-bold ln-u-margin-top*2'>
                  {message.title}
                </FlagComponent>
              </FlagWrapper>

            </ModalHeading>

            <div>{message.instruction}</div>
          </>
        )}
      </>
    )
  }

  return (
    <>
      <Modal
        small
        handleClose={() => props.handleClose()}
        open={props.isOpen}
        headingId='info-modal'
        handleAction={() => props.handleClose()}
      >
        {
          getMessageContent(props.alertLevel)
        }

        <ButtonGroupWrapper className='ln-u-text-align-center'>
          <ButtonGroupSecondary className='u-full-width'>
            <GridWrapper>
              <GridItem
                className='c-pizza-modal-button-padding'
                size={{ default: '1/1', xs: '1/2', sm: '1/2', md: '1/2', lg: '1/2' }}>
                <FilledButton
                  className='c-pizza-modal-button c-submit-modal-button u-full-width'
                  onClick={() => props.handleClose()}
                >
                  {props.alertLevel === AlertLevelEnum.Advisory && 'I\'ll boost it'}
                  {props.alertLevel === AlertLevelEnum.Watch && 'I\'ll boost it again'}
                  {props.alertLevel === AlertLevelEnum.Warning && 'I\'ll dispose and report it'}
                </FilledButton>
              </GridItem>

            </GridWrapper>
          </ButtonGroupSecondary>
        </ButtonGroupWrapper>
      </Modal>
    </>
  )
}

export default TemperatureBoostAlertModal
