import React from 'react'
import { Edit, ErrorCircle, Tick, Time } from '@jsluna/icons'
import { Card, TableBody, TableCell, TableContainer, TableHeader, TableRow } from '@jsluna/react'
import moment from 'moment'
import { groupBy } from '../../../utils/arrays'
import { ICounterTemperatures } from '../../types/ICounterTemperatures'
import { ICounterTemperature } from '../../types/ICounterTemperature'

moment.locale('en-GB')

interface IProps {
  counterTemperature: ICounterTemperatures
  isHotFoodProbeFeature: boolean
}

const CounterTemperatureTable = (props: IProps) => {
  const getCounterName = () =>
    `${props.counterTemperature.turboServe ? 'Turbo-serve' : 'Cabinet'} ${props.counterTemperature.cabinetNumber}`

  const getTableHeader = (showFifthCell: boolean) =>
    <TableHeader>
      <TableRow>
        <TableCell className='c-hotfood-report-counter-column-mobile'></TableCell>
        <TableCell className='c-hotfood-report-counter-column-mobile'>Time</TableCell>
        <TableCell className='c-hotfood-report-counter-column-mobile'>1</TableCell>
        <TableCell className='c-hotfood-report-counter-column-mobile'>2</TableCell>
        <TableCell className='c-hotfood-report-counter-column-mobile'>3</TableCell>
        <TableCell className='c-hotfood-report-counter-column-mobile'>4</TableCell>
        {showFifthCell && <TableCell className='c-hotfood-report-counter-column-mobile'>5</TableCell>}
      </TableRow>
    </TableHeader>

  const getTableRows = (cabinetTemperatures: ICounterTemperature[], showFifthCell: boolean) => (
    cabinetTemperatures.map((ct: ICounterTemperature, i: number) => (
      <TableRow key={i} data-testid={`row-${i}`} className={`${ct.isValid ? '' : 'ln-u-color-red'}`}>
        <TableCell className={`c-hotfood-report-counter-column-mobile c-hotfood-report-counter-item-time-icon 
                ${ct.takenAtOverdueTimeMessage ? 'ln-u-color-red' : ''}`}
        >
          {!!ct.takenAtOverdueTimeMessage && <Time size='large' className='ln-u-color-red' />}
        </TableCell>
        <TableCell className='c-hotfood-report-counter-column-mobile'>
          <span className={`${ct.takenAtOverdueTimeMessage ? 'ln-u-color-red ln-u-font-weight-bold' : ''}`}>
            {moment.utc(ct.takenAt).local().format('H:mm')}
            {!!ct.takenAtOverdueTimeMessage && ` (${ct.takenAtOverdueTimeMessage})`}
          </span>
          { props.isHotFoodProbeFeature && !ct.isBluetoothReading &&
            <>
              &nbsp;
              <Edit data-testid='manual-check-icon' size='large' className='ln-u-color-blue ln-u-margin-right*2' />
            </>
          }
        </TableCell>
        <TableCell className='c-hotfood-report-counter-column-mobile'>
          {ct.temperature1 ? `${ct.temperature1}℃` : 'N/A'}
        </TableCell>
        <TableCell className='c-hotfood-report-counter-column-mobile'>
          {ct.temperature2 ? `${ct.temperature2}℃` : 'N/A'}
        </TableCell>
        <TableCell className='c-hotfood-report-counter-column-mobile'>
          {ct.temperature3 ? `${ct.temperature3}℃` : 'N/A'}
        </TableCell>
        <TableCell className='c-hotfood-report-counter-column-mobile'>
          {ct.temperature4 ? `${ct.temperature4}℃` : 'N/A'}
        </TableCell>
        {showFifthCell &&
          <TableCell className='c-hotfood-report-counter-column-mobile'>
            {ct.temperature5 ? `${ct.temperature5}℃` : 'N/A'}
          </TableCell>
        }
      </TableRow>
    )
    ))

  const hasTemperature5 = (counterTemperature: ICounterTemperatures): boolean => {
    return counterTemperature.cabinetTemperatures.some((temperature: ICounterTemperature) => temperature.temperature5 !== '')
  }

  const renderCounterTemperatures = () => {
    const cabinetTemperatures = props.counterTemperature.cabinetTemperatures
    let previousCabinetTemperature = null
    let displayIndexCount = 0

    // divide into displayIndexes where each one represents a fault, fix or table of temperatures
    for (const cabinetTemperature of cabinetTemperatures) {
      if (previousCabinetTemperature === null || previousCabinetTemperature.isFaulty !== cabinetTemperature.isFaulty) {
        displayIndexCount++
      }
      cabinetTemperature.displayIndex = displayIndexCount
      previousCabinetTemperature = cabinetTemperature
    }

    // group by displayIndexes
    const grouped = groupBy(cabinetTemperatures, (c: ICounterTemperature) => c.displayIndex)

    return Array.from(grouped).map(([, values]: [number, ICounterTemperature[]], index) => {
      if (values.length === 1 && values[0].faultyOrFixedText !== null && values[0].faultyOrFixedText.length > 0) {
        return (
          <div key={index} className={`${values[0].isFaulty ? 'ln-u-color-red' : ''}
            ln-u-font-weight-bold ln-u-margin-top ln-u-margin-bottom`}
          >
            {values[0].isFaulty && <ErrorCircle size='large' className='ln-u-margin-right' />}
            {!values[0].isFaulty && <Tick size='large' className='ln-u-color-green ln-u-margin-right' />}
            {values[0].faultyOrFixedText}
          </div>
        )
      }
      return (
        <TableContainer key={index}>
          {getTableHeader(hasTemperature5(props.counterTemperature))}
          <TableBody>
            {getTableRows(values, hasTemperature5(props.counterTemperature))}
          </TableBody>
        </TableContainer>)
    })
  }

  return (
    <Card>
      <div className='ln-c-label ln-u-font-weight-bold'>{getCounterName()}</div>

      {renderCounterTemperatures()}

      <div className='ln-u-font-weight-bold ln-u-margin-top*2'>
        {!props.counterTemperature.isValid &&
          <ErrorCircle size='large' className='ln-u-color-red ln-u-margin-sides' />}
        <span className='ln-u-color-red'>{props.counterTemperature.cabinetSummaryHighlighted}</span>
        &nbsp;{props.counterTemperature.cabinetSummary}
      </div>
    </Card>
  )
}

export default CounterTemperatureTable
