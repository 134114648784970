import React, { useEffect, useState } from 'react'
import {
  ButtonGroupSecondary,
  ButtonGroupWrapper,
  FilledButton,
  GridItem,
  GridWrapper,
  Modal,
  ModalHeading,
  OutlinedButton,
} from '@jsluna/react'
import { InfoCircle } from '@jsluna/icons'
import { FormErrorMessageKey } from '../../enums/FormErrorMessageKey'
import { TempCheckFor } from '../../enums/TempCheckFor'
import ICounterCheck from '../../types/ICounterCheck'
import { ITemperatureCheckValidationProps } from '../../types/ITemperatureCheckValidationProps'
import { ITemperatureData } from '../../types/ITemperatureData'
import { getInstructionTitle, getInstructionDescription } from '../../utils/CounterInstructionMessages'
import { allTemperaturesExist, isAM } from '../../utils/hotFoodUtils'
import TemperatureCheckErrorMessage from '../common/TemperatureCheckErrorMessage'
import CabinetDrawing from './CabinetDrawing'
import CounterTempCheckInputCard from './CounterTempCheckInputCard'

interface IProps {
  cabinet: ICounterCheck
  handleClose: () => void
  handleCloseStillNotFixed: (isTurboServe: boolean) => void
  handleSave: (data: ICounterCheck) => Promise<void>
  isOpen: boolean
  isBluetoothReading: boolean
}

const CabinetStatusModal = (props: IProps) => {
  const [temperatures, setTemperatures] = useState<ITemperatureData[]>([])
  const [validation, setValidation] = useState<ITemperatureCheckValidationProps>({
    errorData: [],
    isLowReadingWarned: false,
    messageKey: FormErrorMessageKey.NONE,
    result: true,
  })

  const maxShelfNumber = Math.max(...(props.cabinet.shelves?.map(shelf => shelf.shelfNumber) || []))

  const onSubmitReading = async () => {
    // ensure that all temperatures have a value, whether valid or not
    const [errorData, messageKey, result] = allTemperaturesExist(temperatures)
    setValidation({
      errorData: errorData as ITemperatureData[],
      isLowReadingWarned: false,
      messageKey: messageKey as FormErrorMessageKey,
      result: result as boolean,
    })

    if (!result) {
      return
    }

    let valid = true

    if (props.cabinet.turboServe) {
      // turbo-serve
      for (let count = 1; count <= temperatures.length; count++) {
        const temp = temperatures.find((t) => t.position === count)!.temperature
        const tempRange = props.cabinet.shelves!.find((s) => s.shelfNumber === count && s.isAfternoon === !isAM())!

        valid = valid && (temp === 'n/a' || Number(temp) >= tempRange.minimumTemperature)
      }
    } else {
      // serve-over
      valid = (temperatures.filter((t) => Number(t.temperature) < 75).length === 0)
    }

    if (!valid) {
      props.handleCloseStillNotFixed(props.cabinet.turboServe)
    } else {
      await props.handleSave({ ...props.cabinet, temperatures })
    }
  }

  useEffect(() => {
    if(maxShelfNumber === 5) {
      setTemperatures([
        {
          position: 1,
          temperature: '',
        },
        {
          position: 2,
          temperature: '',
        },
        {
          position: 3,
          temperature: '',
        },
        {
          position: 4,
          temperature: '',
        },
        {
          position: 5,
          temperature: '',
        },
      ])
    } else {
      setTemperatures([
        {
          position: 1,
          temperature: '',
        },
        {
          position: 2,
          temperature: '',
        },
        {
          position: 3,
          temperature: '',
        },
        {
          position: 4,
          temperature: '',
        }])
    }
  }, [])

  return (
    <Modal
      small={props.cabinet.isFaulty}
      handleClose={() => props.handleClose()}
      open={props.isOpen}
      headingId='info-modal'
    >
      <ModalHeading element='h4'>{props.cabinet.isFaulty ? 'Faulty cabinet' : 'Mark cabinet as fixed'}</ModalHeading>

      <div>
        {props.cabinet.isFaulty &&
          `Are you sure this cabinet is faulty? By marking it as faulty you are
        confirming it is not being used and there is an open work order for
        the fixture on FM Assist.`}

        {!props.cabinet.isFaulty && (
          <>
            {`Please only mark cabinet as fixed once it's back up to temperature and
            operating correctly. You'll also need to complete an air temperature check before using the counter.`}

            <GridWrapper>
              <GridItem size={props.cabinet.turboServe ? { default: '1/1' } : { xs: '1/1', sm: '2/3' }}>
                <div className='ln-c-label ln-u-font-weight-bold ln-u-padding-top*2 ln-u-padding-bottom'>
                  {getInstructionTitle(
                    props.cabinet.turboServe ? TempCheckFor.TurboServeFirstAir : TempCheckFor.CabinetFirstAir
                  )}
                </div>
                <br />
                <div>
                  {getInstructionDescription(
                    props.cabinet.turboServe ? TempCheckFor.TurboServeFirstAir : TempCheckFor.CabinetFirstAir
                  )}
                </div>
              </GridItem>

              {!props.cabinet.turboServe && (
                <GridItem size={{ default: '1/3' }} className='ln-u-padding-top'>
                  <CabinetDrawing />
                </GridItem>
              )}
            </GridWrapper>

            <div className='ln-u-margin-top*2 ln-c-label ln-u-font-weight-bold u-full-width'>
              {`${props.cabinet.turboServe ? 'Turbo-serve' : 'Cabinet'} ${props.cabinet.counterNumber}`}
            </div>

            {!props.isBluetoothReading &&
              <div>
                <InfoCircle size='large' className='ln-u-color-blue ln-u-margin-right' />
                <p data-testid='manual-input-hint' className='ln-c-label ln-u-font-weight-bold'>
                  You've changed to manual checks. Please manually input the temperature checks below.
                </p>
              </div>
            }

            <div>
              {temperatures.map((item, index) => (
                <CounterTempCheckInputCard
                  className='c-cabinet-status-modal-temperature-input'
                  counterNumber={props.cabinet.counterNumber}
                  hasError={validation.errorData.filter((t) => t.position === item.position).length > 0}
                  key={index}
                  currentTemp={item}
                  temperatures={temperatures}
                  setTemperatures={setTemperatures}
                  isTurbo={props.cabinet.turboServe}
                  isModal={true}
                  isBluetoothReading={props.isBluetoothReading}
                />
              ))}
            </div>
          </>
        )}

        {!validation.result && (
          <TemperatureCheckErrorMessage
            class='ln-u-font-weight-bold ln-u-text-align-left ln-u-padding-left'
            messageKey={validation.messageKey}
          />
        )}
      </div>

      <ButtonGroupWrapper>
        <ButtonGroupSecondary className='u-full-width'>
          <GridWrapper>
            <GridItem className='' size={{ default: '1/1', xs: '1/2', sm: '1/2', md: '1/2', lg: '1/2' }}>
              <FilledButton
                className='u-full-width'
                onClick={() => (props.cabinet.isFaulty ? props.handleSave(props.cabinet) : onSubmitReading())}
              >
                Mark as {props.cabinet.isFaulty ? 'faulty' : 'fixed and submit reading'}
              </FilledButton>
            </GridItem>

            <GridItem
              className='c-batch-card-modal-secondary-button'
              size={{ default: '1/1', xs: '1/2', sm: '1/2', md: '1/2', lg: '1/2' }}
            >
              <OutlinedButton className='u-full-width' data-testid='close-button' onClick={() => props.handleClose()}>
                Cancel
              </OutlinedButton>
            </GridItem>
          </GridWrapper>
        </ButtonGroupSecondary>
      </ButtonGroupWrapper>
    </Modal>
  )
}

export default CabinetStatusModal
