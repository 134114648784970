export enum TempCheckFor {
  Batch = 'Batch',
  CabinetFirstAir = 'CabinetFirstAir',
  CabinetFollowing = 'CabinetFollowing',
  TurboServe = 'TurboServe',
  TurboServeFirstAir = 'TurboServeFirstAir',
  TurboServeRegular = 'TurboServeRegular',
  TurboServeCompletedAM = 'TurboServeCompletedAM',
  TurboServeCompletedToday = 'TurboServeCompletedToday',
}
