import React from 'react'
import { Cancel, ErrorCircle, Tick } from '@jsluna/icons'
import { TableBody, TableCell, TableContainer, TableHeader, TableRow } from '@jsluna/react'
import AppTooltip from '../../../common/components/Tooltip/AppTooltip'
import { IBatchItem } from '../../types/IBatchItem'
import Incrementor from './Incrementor'

interface IProps {
  batchItems: IBatchItem[]
  isInvalid: boolean
  onChange: (e: any, f: any) => void
}

const BatchTable = (props: IProps) =>
  <TableContainer>
    <TableHeader className='c-batch-table-desktop'>
      <TableRow>
        <TableCell>
          {props.isInvalid && <ErrorCircle className='c-form-alert-icon-red ln-u-margin-right' />}
          In batch
        </TableCell>
        <TableCell>Product</TableCell>
        <TableCell>
          Portion(s)
          <AppTooltip value={'Quantity of portions you\'re cooking per product'} />
        </TableCell>
      </TableRow>
    </TableHeader>
    <TableBody>
      {props.batchItems.map((bi: IBatchItem) =>
        <TableRow className={`${bi.quantityProduced !== 0 ? 'c-batch-table-in-batch-edge' : ''}`} key={bi.skuId}>

          <TableCell className='c-batch-table-in-batch-column'>
            {bi.quantityProduced !== 0 ? <Tick className='ln-u-color-green' /> : <Cancel className='ln-u-color-red' />}
          </TableCell>

          <TableCell className='c-batch-table-desktop'>{bi.skuName}</TableCell>

          <TableCell className='c-batch-table-mobile-sku'>
            <div className='c-batch-table-mobile ln-u-font-weight-bold'>{bi.skuName}</div>
            <Incrementor
              id={bi.skuId}
              step={1}
              value={bi.quantityProduced}
              setValue={(skuId: any, value: any) => props.onChange(skuId, value)} />
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  </TableContainer >

export default BatchTable
