import React, { useContext, useEffect, useState } from 'react'
import { Prompt } from 'react-router-dom'
import { Card, Container, FilledButton, GridItem, GridWrapper, OutlinedButton } from '@jsluna/react'
import { useApiClient } from '../../../common/AppContext/appContext'
import InfoBanner from '../../../common/components/Banners/InfoBanner'
import { ErrorMessage } from '../../../common/enums/ErrorMessage'
import ErrorRefresh from '../../../common/components/ErrorRefresh'
import Header from '../../../common/components/Header'
import {
  GetHeaderMainMenus,
  headerMainMenus,
  headerPlannerTitles,
} from '../../../common/enums/HeaderItems'
import Loading from '../../../common/components/Loading'
import SaveModal from '../../../common/components/Modals/SaveModal'
import {
  addBannerMessage,
  setBannerMessages,
  setPlanner,
  setUnsavedChanges,
} from '../../../common/Context/commonDispatch'
import { Context } from '../../../common/Context/context'
import {
  setForecastEditing, setForecastPlan, setForecastPlanOnSave, setForecastSaveModal,
  setIsFuture, setPlanDates, setSelectedPlanDateIndex,
} from '../../../common/Context/forecastDispatch'
import { Menu } from '../../../common/enums/MenuEnum'
import { getDates } from '../../../utils/dates'
import { getUserStore } from '../../../utils/localStore'
import { getDateOptions } from '../../../utils/pizzaUtils'
import { getForecastPlan, updatePizzaPlan } from '../../api/forecastApi'
import { ViewPlan } from '../../enums/ViewPlanEnum'
import { IForecastPlan } from '../../types/IForecastPlan'
import { IUpdatedPlanItems } from '../../types/IUpdatedPlanItems'
import { PlannerName } from '../../../common/enums/PlannerNameEnum'
import TutorialWrapper from '../../../common/components/TutorialWrapper'
import BannerHolder from '../../../common/components/Banners/BannerHolder'
import { IStackBanner } from '../../../hotfood/types/IStackBanner'
import ConfirmationBanner from '../../../common/components/Banners/ConfirmationBanner'
import ForecastTable from './ForecastTable'

const ForecastView = () => {
  const { state, dispatch } = useContext(Context)
  const [tutorialToken, setTutorialToken] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const [viewPlan, setViewPlan] = useState(2)
  const [showEditButton, setShowEditButton] = useState(false)
  const [headerNavigationItems, setHeaderNavigationItems] = useState(headerMainMenus.pizzaNavs)

  const apiClient = useApiClient()

  useEffect(() => {
    GetHeaderMainMenus(apiClient, getUserStore().storeId, PlannerName.Pizza).then((res) => setHeaderNavigationItems(res))
  }, [])

  useEffect(() => {
    dispatch(setPlanner(PlannerName.Pizza))

    apiClient.getTutorialAccessToken()
      .then((res) => setTutorialToken(res))
      // eslint-disable-next-line no-console
      .catch((e) => console.log(e as Error))

    configureViewplan(state.forecast.selectedPlanDateIndex)
    dispatch(setPlanDates(getDates()))
    setIsLoading(false)
  }, [apiClient, dispatch, state.forecast.selectedPlanDateIndex])

  useEffect(() => {
    setIsLoading(true)
    configureViewplan(state.forecast.selectedPlanDateIndex)
    getPlanForDate(state.forecast.selectedPlanDateIndex)
  }, [state.forecast.selectedPlanDateIndex])

  useEffect(() => {
    checkUnsavedChanges()
  }, [state.forecast.forecastPlan])

  const checkUnsavedChanges = () => {
    for (const plannerGroup of state.forecast.forecastPlan.forecastPlannerGroups) {
      if (plannerGroup.items.some((p) => p.totalSalesForecastN1 !== p.totalSalesForecast)) {
        dispatch(setUnsavedChanges(true))
        return
      }
    }
    dispatch(setUnsavedChanges(false))
  }

  const getDateFromIndex = (index: number) => getDates()[index].format('YYYY-MM-DD')

  const getPlanForDate = (selectedDatePlanIndex: number) => {
    const planDate = getDateFromIndex(selectedDatePlanIndex)
    getForecastPlan(apiClient, getUserStore().storeId, planDate)
      .then((res: IForecastPlan) => {
        dispatch(setForecastPlan(res))
      })
      .catch(() => {
        setErrorMessage(ErrorMessage.ForecastView.FailedToLoad)
      })
      .finally(() => setIsLoading(false))
  }
  const editOnClick = () => {
    dispatch(setForecastEditing(true))
    setShowEditButton(false)
  }

  const configureViewplan = (planDateIndex: number) => {
    setErrorMessage('')
    switch (true) {
      // Before today
      case (planDateIndex < 6):
        setViewPlan(1)
        dispatch(setIsFuture(false))
        setShowEditButton(false)
        break
      // Today
      case (planDateIndex === 6):
        setViewPlan(2)
        dispatch(setIsFuture(false))
        setShowEditButton(false)
        break
      // Tomorrow + 2
      case (planDateIndex > 6 && planDateIndex < 9):
        setViewPlan(3)
        dispatch(setIsFuture(true))
        setShowEditButton(false)
        break
      // Day(s) after tomorrow + 2
      case (planDateIndex > 8 && planDateIndex < 14):
        setViewPlan(3)
        dispatch(setIsFuture(true))
        setShowEditButton(true)
        break
      default:
        setErrorMessage(ErrorMessage.ForecastView.FailedToLoad)
        break
    }

    setIsLoading(false)
  }

  const onPlanDateChange = (event: React.FormEvent<HTMLSelectElement>) => {
    const planDateIndex = Number(event.currentTarget.value)
    if (planDateIndex < 8) {
      dispatch(setForecastEditing(false))
    }
    dispatch(setSelectedPlanDateIndex(planDateIndex))
    configureViewplan(planDateIndex)
  }

  const onEdit = () => {
    if (state.forecast.editing === true) {
      setIsLoading(true)
      const planItemUpdateList: IUpdatedPlanItems[] = []
      state.forecast.forecastPlan.forecastPlannerGroups.forEach((plannerGroup) => {
        plannerGroup.items
          .filter((planItem) => planItem.totalSalesForecastN1 !== planItem.totalSalesForecast)
          .forEach((planItem) => {
            planItemUpdateList.push({
              pizzaId: planItem.pizzaId,
              planItemId: planItem.planItemId,
              quantityCompleted: planItem.quantityCompleted,
              totalSalesForecast: planItem.totalSalesForecastN1,
            })
          })
      })

      updatePizzaPlan(apiClient, state.forecast.forecastPlan.forecastPlanId, planItemUpdateList)
        .then((result) => {
          dispatch(setForecastEditing(false))
          dispatch(setUnsavedChanges(false))
          dispatch(addBannerMessage(<ConfirmationBanner message={'Edits saved.'}/>))
          getForecastPlan(apiClient, getUserStore().storeId, getDateFromIndex(state.forecast.selectedPlanDateIndex))
            .then((res: IForecastPlan) =>
              dispatch(setForecastPlanOnSave(state.forecast.forecastPlan.forecastPlannerGroups, res)))
            .catch(() => setErrorMessage(ErrorMessage.SaveException))
          if (result.isSuccess === false) {
            setErrorMessage(result.message)
            return
          }
        })
        .then(() => {
          setErrorMessage('')
        })
        .catch(() => setErrorMessage(ErrorMessage.SaveException))
        .finally(() => setIsLoading(false))
    }
  }

  const modalOnNoSaveAndContinue = () => {
    dispatch(setUnsavedChanges(false))
    dispatch(setForecastEditing(false))
    dispatch(setForecastSaveModal(false))
    getForecastPlan(apiClient, getUserStore().storeId, getDateFromIndex(state.forecast.selectedPlanDateIndex))
      .then((res: IForecastPlan) => {
        dispatch(setForecastPlanOnSave(state.forecast.forecastPlan.forecastPlannerGroups, res))
        setErrorMessage('')
      })
      .catch(() => setErrorMessage(ErrorMessage.ForecastView.FailedToLoad))
      .finally(() => setIsLoading(false))
    configureViewplan(state.forecast.selectedPlanDateIndex)
  }

  const cancelOnClick = () => {
    if (state.unsavedChanges) {
      dispatch(setForecastSaveModal(true))
    } else {
      dispatch(setForecastEditing(false))
      configureViewplan(state.forecast.selectedPlanDateIndex)
    }
  }

  const OnSave = () => {
    onEdit()
    dispatch(setForecastSaveModal(false))
    configureViewplan(state.forecast.selectedPlanDateIndex)
  }

  const saveAndCloseButton = () =>
    <>
      <GridItem size={'1/2'} className='c-pizza-margin-top c-pizza-button-view ln-u-margin-top'>
        <OutlinedButton className='ln-u-space-half ln-u-margin-right'
          onClick={cancelOnClick} fullWidth>Cancel</OutlinedButton>
      </GridItem>
      <GridItem size={'1/2'} className='c-pizza-margin-top c-pizza-button-view ln-u-margin-top'>
        <FilledButton className='ln-u-space-half'
          onClick={OnSave}
          fullWidth
          disabled={!state.unsavedChanges}>
          Save</FilledButton>
      </GridItem>
    </>

  const saveBanners = (banners: IStackBanner[]) => {
    dispatch(setBannerMessages(banners))
  }

  return (
    <>
      {!tutorialToken ? (
        <Loading message='Tutorial Loading' />
      ) : (
        <TutorialWrapper state={state} token={tutorialToken}>
          <Prompt
            when={state.unsavedChanges && !state.forecast.isSaveModalOpen}
            message='You have unsaved changes, are you sure you want to leave?'
          />
          <div className='c-common-main-view-content'>
            <Header
              title={headerPlannerTitles.pizza}
              navItems={headerNavigationItems}
              activeMenuType={Menu.ForecastView}
            />
            <Container soft className='ln-u-push-top-sm'>
              {viewPlan === ViewPlan.BeforeToday as number &&
                <div className='ln-u-margin-bottom*2'>
                  <InfoBanner message='This forecast is in the past' />
                </div>}
              <BannerHolder banners={state.bannerMessages} setBanners={saveBanners} />
              <Card className='ln-u-margin-bottom*2'>
                <GridWrapper>
                  <GridItem size={{ xs: '2/5', lg: '2/6', default: '1/1' }}>
                    <select id='plan-date-select' className='ln-c-select'
                      onChange={(event: React.FormEvent<HTMLSelectElement>) => onPlanDateChange(event)}
                      value={String(state.forecast.selectedPlanDateIndex)}
                      disabled={state.unsavedChanges}>
                      {getDateOptions(state.forecast.planDates)}
                    </select>
                  </GridItem>
                  <GridItem size={{ xs: '1/5', lg: '3/6' }}><></></GridItem>
                  {!state.forecast.editing && showEditButton &&
                    <GridItem size={{ xs: '2/5', lg: '1/6', default: '1/1' }}
                      className='ln-u-text-align-right'>
                      <OutlinedButton onClick={() => editOnClick()} fullWidth
                        className='c-pizza-margin-top'>
                        Edit
                      </OutlinedButton>
                    </GridItem>
                  }
                  {state.forecast.editing &&
                    <GridItem size={{ xs: '2/5', lg: '1/6', default: '1/1' }} className='ln-u-hard-left'>
                      {saveAndCloseButton()}
                    </GridItem>}
                </GridWrapper>
              </Card>

              {!isLoading && !errorMessage && (
                <>
                  <ForecastTable />
                  {state.forecast.editing &&
                    <div className='ln-u-soft-right ln-u-hard-left'>
                      <GridItem size={{ xs: '3/5', lg: '5/6' }}><></></GridItem>
                      <GridItem size={{ xs: '2/5', lg: '1/6', default: '1/1' }}
                        className='c-remove-button-padding c-bottom-pizza-button ln-u-hard-left'>
                        {saveAndCloseButton()}
                      </GridItem>
                    </div>
                  }
                </>
              )}

              {isLoading && <Loading />}
              {errorMessage && <ErrorRefresh message={errorMessage} />}
            </Container>
            {(state.forecast.isSaveModalOpen) &&
              <SaveModal
                isOpen={state.forecast.isSaveModalOpen}
                modalBody={'You\'ve made changes to this forecast plan. Would you like to save these changes?'}
                modalTitle={'Save changes?'}
                onClose={() => dispatch(setForecastSaveModal(false))}
                onNo={modalOnNoSaveAndContinue}
                onYes={OnSave} />
            }
          </div>
        </TutorialWrapper>
      )}
    </>
  )
}
export default ForecastView
