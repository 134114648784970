import React from 'react'
import {
  Modal,
  ModalHeading,
  ButtonGroupWrapper,
  ButtonGroupSecondary,
  GridWrapper,
  GridItem,
  FilledButton,
  OutlinedButton,
  FlagWrapper,
  FlagComponent,
} from '@jsluna/react'
import { ErrorCircle } from '@jsluna/icons'

interface IProps {
  handleClose: () => void
  handleSave: (value: boolean) => void
  isOpen: boolean
}

const CounterManualInputModal = (props: IProps) => (
  <Modal
    large='true'
    handleClose={() => props.handleClose()}
    open={props.isOpen}
    headingId='manual-input-modal'
  >
    <ModalHeading element='h4'>
      <FlagWrapper>
        <FlagComponent className='ln-u-margin-top*2 ln-u-margin-right'>
          <ErrorCircle className='c-form-alert-icon-red ln-u-display-6-fixed' />
        </FlagComponent>
        <FlagComponent data-testid='manual-input-modal-title' className='c-counter-alert-title-font ln-u-margin-top*2'>
            Changing to manual checks
        </FlagComponent>
      </FlagWrapper>
    </ModalHeading>

    <div>
        You're changing to manual checks. Please only do so if you're unable to use your Bluetooth probe.
        To report an issue, please refer to Sainsbury's Tech Assist.
    </div>
    <ButtonGroupWrapper>
      <ButtonGroupSecondary className='u-full-width'>
        <GridWrapper>
          <GridItem
            size={{ xxs: '1/1', xs: '1/2' }}>
            <FilledButton
              data-testid='manual-input-button'
              className='u-full-width'
              onClick={() => props.handleSave(false)}>
                Change to manual checks
            </FilledButton>
          </GridItem>

          <GridItem
            className='c-batch-card-modal-secondary-button'
            size={{ xxs: '1/1', xs: '1/2' }}>
            <OutlinedButton
              className='u-full-width'
              onClick={() => props.handleClose()}>
                Cancel
            </OutlinedButton>
          </GridItem>
        </GridWrapper>
      </ButtonGroupSecondary>
    </ButtonGroupWrapper>
  </Modal>


)

export default CounterManualInputModal
