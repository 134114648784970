interface IQpigTypes {
  readonly QpigBaking: string
  readonly QpigFinishing: string
  readonly QpigGenesisPlantAdjustment: string
  readonly QpigIngredient: string
  readonly QpigIngredientSummary: string
  readonly QpigPackaging: string
  readonly QpigPackagingCodes: string
  readonly QpigPostBakeStandard: string
  readonly QpigPostThawStandard: string
  readonly QpigPreparation: string
  readonly QpigProcess: string
  readonly QpigProductShouldBeRejectedIf: string
  readonly QpigProving: string
  readonly QpigTargetProduct: string
}

const QpigTypes: IQpigTypes = {
  QpigBaking: 'QPIG - Baking',
  QpigFinishing: 'QPIG - Finishing',
  QpigGenesisPlantAdjustment: 'QPIG - Genesis Plant Adjustment',
  QpigIngredient: 'QPIG - Ingredient',
  QpigIngredientSummary: 'QPIG - Ingredient Summary',
  QpigPackaging: 'QPIG - Packaging',
  QpigPackagingCodes: 'QPIG - Packaging Codes',
  QpigPostBakeStandard: 'QPIG - Post Bake Standard',
  QpigPostThawStandard: 'QPIG - Post Thaw Standard',
  QpigPreparation: 'QPIG - Preparation',
  QpigProcess: 'QPIG - Process',
  QpigProductShouldBeRejectedIf: 'QPIG - Product Rejection',
  QpigProving: 'QPIG - Proving',
  QpigTargetProduct: 'QPIG - Target Product',
}

interface IQpigImageStepId {
  readonly Baking: string
  readonly Display: string
  readonly Finishing: string
  readonly Frying: string
  readonly Packaging: string
  readonly PostBakeStandard: string
  readonly PostFryStandard: string
  readonly PostThawStandard: string
  readonly Preparation: string
  readonly Process: string
  readonly ProductShouldBeRejectedIf: string
  readonly Proving: string
  readonly TargetProduct: string
}

const QpigImageStepId: IQpigImageStepId = {
  Baking: 'Baking',
  Display: 'Display',
  Finishing: 'Finishing',
  Frying: 'Frying',
  Packaging: 'Packaging',
  PostBakeStandard: 'Post-Bake-Standard',
  PostFryStandard: 'Post-Fry-Standard',
  PostThawStandard: 'Post-Thaw-Standard',
  Preparation: 'Preparation',
  Process: 'Process',
  ProductShouldBeRejectedIf: 'Product-should-be-rejected-if',
  Proving: 'Proving',
  TargetProduct: 'Target-Product',
}

interface IHashTable<T> {
  [key: string]: T
}

const StepTypeToImageStepId: IHashTable<string> = {}
StepTypeToImageStepId[QpigTypes.QpigBaking] = QpigImageStepId.Baking
StepTypeToImageStepId[QpigTypes.QpigPreparation] = QpigImageStepId.Preparation
StepTypeToImageStepId[QpigTypes.QpigProcess] = QpigImageStepId.Process
StepTypeToImageStepId[QpigTypes.QpigProving] = QpigImageStepId.Proving
StepTypeToImageStepId[QpigTypes.QpigPostBakeStandard] = QpigImageStepId.PostBakeStandard
StepTypeToImageStepId[QpigTypes.QpigPostThawStandard] = QpigImageStepId.PostThawStandard
StepTypeToImageStepId[QpigTypes.QpigFinishing] = QpigImageStepId.Finishing
StepTypeToImageStepId[QpigTypes.QpigPackaging] = QpigImageStepId.Packaging
StepTypeToImageStepId[QpigTypes.QpigTargetProduct] = QpigImageStepId.TargetProduct
StepTypeToImageStepId[QpigTypes.QpigProductShouldBeRejectedIf] = QpigImageStepId.ProductShouldBeRejectedIf

const QpigStepTypes: string[] = [
  QpigTypes.QpigPreparation,
  QpigTypes.QpigProcess,
  QpigTypes.QpigProving,
  QpigTypes.QpigBaking,
  QpigTypes.QpigPostBakeStandard,
  QpigTypes.QpigPostThawStandard,
  QpigTypes.QpigFinishing,
  QpigTypes.QpigPackaging,
  QpigTypes.QpigTargetProduct,
  QpigTypes.QpigProductShouldBeRejectedIf]

export {
  QpigTypes,
  QpigStepTypes,
  StepTypeToImageStepId,
}
