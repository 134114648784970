import { ApiClient } from '../../common/api/apiClient'
import { PlannerName } from '../../common/enums/PlannerNameEnum'
import { IStore } from '../types/IStore'

const getStore = async (apiClient: ApiClient, storeCode: number): Promise<IStore> =>
  await apiClient.get(PlannerName.HotFood, `store/${storeCode}`)

export {
  getStore,
}
