import React from 'react'
import {
  FilledButton,
  GridItem,
  GridWrapper,
  Modal,
  ModalHeading,
} from '@jsluna/react'

const AllergenMixModal = ({
  isOpened,
  setIsOpened,
  handleAction,
}: {
  isOpened: boolean
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>
  handleAction: () => void
}) => (
  <React.Fragment>
    <Modal open={isOpened} handleClose={() => setIsOpened(false)}>
      <ModalHeading data-testid='modalTitle' element='h4' className='ln-u-text-align-center'>
        {'A full end of day clean is needed'}
      </ModalHeading>
      <div className='ln-o-matrix-list ln-o-matrix-list--spaced
        ln-u-padding-bottom ln-u-padding-right ln-u-padding-left'>
        {
          <div data-testid='modalBody' className='ln-u-margin-sides*2 ln-u-text-align-center'>
            { 'After this mix is complete, ' +
              'you\'ll need to carry out a full end of day clean of the equipment, ' +
              'due to allergen risks.'}
          </div>
        }
        <GridWrapper className='ln-u-text-align-center'>
          <GridItem size={{ xs: '1/3', lg: '2/4', default: '1/1' }}>
            <FilledButton onClick={handleAction} data-testid='modalButton' fullWidth className='ln-u-margin*2'>
              {'Understood'}
            </FilledButton>
          </GridItem>
        </GridWrapper>
      </div>
    </Modal>
  </React.Fragment>
)

export default AllergenMixModal
