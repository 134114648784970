import {
  ButtonGroupSecondary,
  ButtonGroupWrapper,
  Modal,
  TextButton,
} from '@jsluna/react'
import React from 'react'

const AlertMixStatusModal = ({
  isOpened,
  setIsOpened,
  message,
}: {
  isOpened: boolean
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>
  message: string
}) => (
  <div className='c-common-modal'>
    <Modal open={isOpened} handleClose={() => setIsOpened(false)}>
      <h4>Information</h4>
      <p>
        {message}
      </p>
      <ButtonGroupWrapper>
        <ButtonGroupSecondary>
          <TextButton onClick={() => setIsOpened(false)}>
            OK
          </TextButton>
        </ButtonGroupSecondary>
      </ButtonGroupWrapper>
    </Modal>
  </div>
)

export default AlertMixStatusModal
