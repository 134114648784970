import { isSafari } from 'react-device-detect'

export const selectCurrentTarget = (event: React.FormEvent<HTMLInputElement>) => {
  if (isSafari) {
    /* currentTarget.select() doesn't work on Safari */
    try {
      event.currentTarget.focus()
      event.currentTarget.setSelectionRange(0, event.currentTarget.value.length)
    } catch (ex) {
      /* Set selection error - usually for chrome in iPad debug mode */
      event.currentTarget.select()
    }
  } else {
    event.currentTarget.select()
  }
}
