import React, { useContext, useEffect, useState } from 'react'
import { Container, FilledButton, GridItem } from '@jsluna/react'
import moment from 'moment'
import { Prompt } from 'react-router'
import { useApiClient } from '../../../common/AppContext/appContext'
import { ErrorMessage } from '../../../common/enums/ErrorMessage'
import ErrorRefresh from '../../../common/components/ErrorRefresh'
import Header from '../../../common/components/Header'
import {
  GetHeaderMainMenus,
  headerMainMenus,
  headerPlannerTitles,
} from '../../../common/enums/HeaderItems'
import Loading from '../../../common/components/Loading'
import {
  addBannerMessage,
  setBannerMessages,
  setPlanner,
  setUnsavedChanges,
} from '../../../common/Context/commonDispatch'
import { Context } from '../../../common/Context/context'
import {
  setAddToTotalStatus,
  setProductionPlan,
  setProductionPlanOnSave,
} from '../../../common/Context/productionDispatch'
import { Menu } from '../../../common/enums/MenuEnum'
import { PlannerName } from '../../../common/enums/PlannerNameEnum'
import { getUserStore } from '../../../utils/localStore'
import { updatePizzaPlan } from '../../api/forecastApi'
import { getProductionPlan } from '../../api/productionApi'
import { IItem } from '../../types/IItem'
import { IPlannerGroupItem } from '../../types/IPlannerGroupItem'
import { IProductionPlan } from '../../types/IProductionPlan'
import { IUpdatedPlanItems } from '../../types/IUpdatedPlanItems'
import { IStackBanner } from '../../../hotfood/types/IStackBanner'
import BannerHolder from '../../../common/components/Banners/BannerHolder'
import ConfirmationBanner from '../../../common/components/Banners/ConfirmationBanner'
import TutorialWrapper from '../../../common/components/TutorialWrapper'
import ProductionTable from './ProductionTable'


const ProductionView = () => {
  const { state, dispatch } = useContext(Context)
  const [tutorialToken, setTutorialToken] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const [totalFrozenPizzas, setTotalFrozenPizzas] = useState(0)
  const [totalPizzas, setTotalPizzas] = useState(0)
  const [headerNavigationItems, setHeaderNavigationItems] = useState(headerMainMenus.pizzaNavs)

  const apiClient = useApiClient()

  useEffect(() => {
    GetHeaderMainMenus(apiClient, getUserStore().storeId, PlannerName.Pizza).then((res) => setHeaderNavigationItems(res))
  }, [])

  useEffect(() => {
    dispatch(setPlanner(PlannerName.Pizza))

    apiClient.getTutorialAccessToken()
      .then((res) => setTutorialToken(res))
      // eslint-disable-next-line no-console
      .catch((e) => console.log(e as Error))

    getProductionPlan(apiClient, getUserStore().storeId)
      .then((res: IProductionPlan) => {
        dispatch(setProductionPlan(res))
        setTotalPizzas(countTotalPizzas(res.productionPlannerGroups))
        setTotalFrozenPizzas(countTotalPizzas(res.frozenProductionPlannerGroups))
      })
      .catch(() => setErrorMessage(ErrorMessage.ProductionView.FailedToLoad))
      .finally(() => setIsLoading(false))
  }, [apiClient, dispatch])

  useEffect(() => {
    let changesMade = false

    for (const plannerGroups of state.production.productionPlannerGroups) {
      if (plannerGroups.items.some((p: IItem) => p.youMade !== 0)) {
        changesMade = true
      }
    }

    for (const plannerGroups of state.production.frozenProductionPlannerGroups) {
      if (plannerGroups.items.some((p: IItem) => p.youMade !== 0)) {
        changesMade = true
      }
    }

    if (changesMade) {
      dispatch(setUnsavedChanges(true))
      dispatch(setAddToTotalStatus(true))
      return
    }

    dispatch(setUnsavedChanges(false))
    dispatch(setAddToTotalStatus(false))
  }, [dispatch, state.production])

  const countTotalPizzas = (plannerGroups: IPlannerGroupItem[]): number => {
    let total = 0
    plannerGroups.forEach((plannerGroup: IPlannerGroupItem) => {
      plannerGroup.items.forEach((item: IItem) => {
        total += item.produceQuantity
      })
    })

    return total
  }

  const onEdit = () => {
    setIsLoading(true)
    if (state.unsavedChanges) {
      const planItemUpdateList: IUpdatedPlanItems[] = []
      const planFrozenItemUpdateList: IUpdatedPlanItems[] = []

      // items to be made
      state.production.productionPlannerGroups.forEach((plannerGroup: IPlannerGroupItem) => {
        plannerGroup.items
          .filter((planItem: IItem) => planItem.youMade !== 0)
          .forEach((planItem: IItem) => {
            planItemUpdateList.push({
              pizzaId: planItem.pizzaId,
              planItemId: planItem.planItemId,
              quantityCompleted: planItem.youMade,
              totalSalesForecast: planItem.totalSalesForecast,
            })
          })
      })

      // items to be defrosted
      state.production.frozenProductionPlannerGroups.forEach((plannerGroup: IPlannerGroupItem) => {
        plannerGroup.items
          .filter((planItem: IItem) => planItem.youMade !== 0)
          .forEach((planItem: IItem) => {
            planFrozenItemUpdateList.push({
              pizzaId: planItem.pizzaId,
              planItemId: planItem.planItemId,
              quantityCompleted: planItem.youMade,
              totalSalesForecast: planItem.totalSalesForecast,
            })
          })
      })

      updatePizzaPlan(apiClient, state.production.planId, planItemUpdateList.concat(planFrozenItemUpdateList))
        .then((result) => {
          dispatch(setUnsavedChanges(false))
          dispatch(setAddToTotalStatus(false))
          getProductionPlan(apiClient, getUserStore().storeId)
            .then((res: IProductionPlan) => {
              dispatch(setProductionPlanOnSave(state.production.productionPlannerGroups, res))
              dispatch(addBannerMessage(
                <ConfirmationBanner message={'You\'ve successfully added your pizzas to today\'s total.'}/>))
            })
            .catch(() => setErrorMessage(ErrorMessage.SaveException))
          if (result.isSuccess === false) {
            setErrorMessage(result.message)
            return
          }
        })
        .then(() => {
          setErrorMessage('')
          setIsLoading(false)
        })
        .catch(() => setErrorMessage(ErrorMessage.SaveException))
    }
  }

  const addToTotalButton = () =>
    <GridItem
      size={{ default: '1/1', xs: '1/3', sm: '1/3', md: '1/4', lg: '1/5' }}
      className='ln-u-hard'>
      <FilledButton
        fullWidth
        onClick={() => onEdit()}
        className='ln-u-margin-top ln-u-margin-bottom*2 ln-u-soft-sides'
        disabled={!state.production.addToTotal}>
        Add to total
      </FilledButton>
    </GridItem>

  const saveBanners = (banners: IStackBanner[]) => {
    dispatch(setBannerMessages(banners))
  }

  return (
    <>
      {!tutorialToken ? (
        <Loading message='Tutorial Loading' />
      ) : (
        <TutorialWrapper state={state} token={tutorialToken}>
          <Prompt
            when={state.unsavedChanges && !state.forecast.isSaveModalOpen}
            message='You have unsaved changes, are you sure you want to leave?'
          />
          <div className='c-common-main-view-content'>
            <Header
              title={headerPlannerTitles.pizza}
              navItems={headerNavigationItems}
              activeMenuType={Menu.ProductionView}
            />
            <Container soft className='ln-u-push-top-sm'>
              <BannerHolder banners={state.bannerMessages} setBanners={saveBanners} />
              <GridItem
                size={{ default: '1/1' }}
                className='ln-u-hard'>
                <h4 className='ln-u-flush-bottom pizza-production-title'>
                  {`Today's production plan ${moment().format('(DD.MM.YYYY)')}`}
                </h4>
              </GridItem>

              {totalPizzas > 0 &&
              <>
                <GridItem
                  size={{ default: '1/1' }}
                  className='ln-u-hard'
                >
                  <div className='ln-u-margin-bottom*2 ln-u-margin-top*2'>
                    There's {totalPizzas} pizzas to make today.
                  </div>
                </GridItem>
                <ProductionTable isFrozen={false} />
              </>}

              {totalFrozenPizzas > 0 &&
              <>
                <GridItem
                  size={{ default: '1/1' }}
                  className='ln-u-hard'
                >
                  <div className='ln-u-margin-bottom*2 ln-u-margin-top*2'>
                    There's {totalFrozenPizzas} pizzas to defrost today.
                  </div>
                </GridItem>
                <ProductionTable isFrozen={true} />
              </>}

              <div className='ln-u-text-align-right'>{addToTotalButton()}</div>

              {isLoading && <Loading />}
              {errorMessage && <ErrorRefresh message={errorMessage} />}

            </Container >
          </div >
        </TutorialWrapper>
      )}
    </>
  )
}

export default ProductionView
