import React, { useEffect } from 'react'
import IScrollPosition from '../../types/IScrollPosition'

interface IScrollToPreviousProps {
  children: any
  clearScrollPosition: (className: string) => void
  setScrollPosition: (className: string, position: number) => void
  scrollAreaId: string
  scrollPositions: IScrollPosition[]
}

const ScrollToPrevious = ({
  children,
  clearScrollPosition,
  setScrollPosition,
  scrollAreaId,
  scrollPositions }: IScrollToPreviousProps) => {

  useEffect(() => {
    const scrollPostion = scrollPositions.find((sp) => sp.className === scrollAreaId)
    if (scrollPostion !== undefined) {
      setTimeout(() => {
        window.scrollTo(0, scrollPostion.position || 0)
        clearScrollPosition(scrollAreaId)
      }, 10)
    }

    return () => {
      setScrollPosition(scrollAreaId, window.scrollY)
    }
  }, [])

  return (
    <div>
      {children}
    </div>
  )
}

export default ScrollToPrevious
