import { Tick } from '@jsluna/icons'
import { Card, GridItem } from '@jsluna/react'
import React from 'react'
import ICounterCheck from '../../types/ICounterCheck'
import {
  afternoonHour,
  getCurrentCheckedTimeInOrder,
  getNextTimeToCheck,
  midday,
  midnight,
  getTimeNow,
} from '../../utils/hotFoodUtils'

interface IPostSubmitMessageCard {
  isTurboServe: boolean
  counters: ICounterCheck[]
  isInstantMessage: boolean
}

const PostSubmitMessageCard = (props: IPostSubmitMessageCard): JSX.Element => {
  const renderMessage = () => {
    const latestTakenTime = getCurrentCheckedTimeInOrder(props.counters, props.isTurboServe, false, false)

    // General message after AM check
    const timeToCheck = getNextTimeToCheck(props.counters, props.isTurboServe)
    const message = (!props.isInstantMessage && timeToCheck.isSame(midday))

      // Current is AM, and AM check was done
      ? ''.concat('You\'ve already completed your AM temperature check.',
        '\n',
        'Please wait until 12:00 to complete your PM check.')

      : (!props.isInstantMessage && timeToCheck.isSame(midnight))

        // Current is PM, and AM/PM checks were done
        ? ''.concat('AM and PM checks have been completed.',
          '\n',
          'You have no more turbo-serve checks due today.')

        // general message after temp check on both of ServeOver and TurboServe
        : ''.concat('Temperature readings submitted at ',
          latestTakenTime.format('HH:mm'),
          '.',
          (props.isTurboServe && getTimeNow().getHours() >= afternoonHour)
            ? ''
            : `\nPlease complete your next check at ${timeToCheck.format('HH:mm')}.`)

    return message.split('\n').map((line, i) => <div key={i}>{line}<br /></div>)
  }

  return (
    <Card className='ln-u-display-flex ln-u-align-items-center c-counter-post-submit-card'>
      <GridItem size={'1/3'} className='ln-u-hard ln-c-label c-counter-post-submit-icon'>
        <Tick />
      </GridItem>
      <GridItem
        size={'2/3'}
        className='ln-u-hard ln-c-label c-counter-post-submit-text'
        data-testid='post-submission-message'
      >
        {renderMessage()}
      </GridItem>
    </Card>
  )
}

export default PostSubmitMessageCard
