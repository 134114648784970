const getEnv = () => {
  switch (window.location.host) {
    case 'localhost:3000':
      return 'local'
    case 'productionplanning-dev.retail-azure.js-devops.co.uk':
      return 'dev'
    case 'productionplanning-test.retail-azure.js-devops.co.uk':
      return 'test'
    case 'productionplanning-preprod.retail-azure.js-devops.co.uk':
      return 'preprod'
    case 'productionplanning.sainsburys.co.uk':
      return 'prod'
    default:
      return 'local'
  }
}

export default getEnv
