import React from 'react'
import { GridItem, GridWrapper } from '@jsluna/react'
import moment from 'moment'
import { IBatchTemperature } from '../../types/IBatchTemperature'
import { getRequiredTemperatureCount, isBatchDisposed } from '../../utils/hotFoodUtils'
import TemperatureCheckRow from './TemperatureCheckRow'

moment.locale('en-GB')

interface IProps {
  batch: IBatchTemperature
  temperatureCount: number
  isHotFoodProbeFeature: boolean
}

const TemperatureCheck = (props: IProps) => {
  const count = getRequiredTemperatureCount(props.batch)

  const temperatures = props.batch.temperatures!.slice(props.temperatureCount * count,
    props.temperatureCount * count + count)

  return (
    <GridWrapper className='ln-u-padding-top*2 ln-u-padding-bottom*2'>

      <GridItem data-testid='temperature-check-info' size='1/1' className='ln-u-font-weight-bold'>
        { props.isHotFoodProbeFeature ?
          `${
            temperatures.filter((x) => x.isBluetoothReading === true).length > 0 ? 'Bluetooth' : 'Manual'
          } ${
            props.temperatureCount === 0 ? 'temperature ' : 'boost temperature '
          }`
          :
          `${
            props.temperatureCount === 0 ? 'Temperature ' : 'Boost temperature '
          }`
        }
        checks by {temperatures[0].takenBy!} at {moment
          .utc(temperatures[0].takenAt)
          .local()
          .format('H:mm')}
      </GridItem>
      <GridItem size='1/1'>
        <TemperatureCheckRow temperatures={temperatures} />
      </GridItem>
      {props.temperatureCount === 2 && isBatchDisposed(props.batch) &&
        <GridItem size='1/1' className='ln-u-font-weight-bold'>
          {
            `${temperatures[0].takenBy!} told to dispose of batch and log faulty oven
              at ${moment.utc(temperatures[0].takenAt).local().format('H:mm')}`
          }
        </GridItem>
      }
    </GridWrapper>
  )
}

export default TemperatureCheck
