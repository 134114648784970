import { IIngredient } from '../types/IIngredient'
import { ActionType } from './reducer'

export interface ISetIngredients {
  type: ActionType.ingredient_setIngredients
  payload: IIngredient[]
}

export const setIngredients = (context: IIngredient[]): ISetIngredients => ({
  payload: context,
  type: ActionType.ingredient_setIngredients,
})
