import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  ButtonGroupSecondary,
  ButtonGroupWrapper,
  Card,
  Container,
  FilledButton,
  GridItem,
  OutlinedButton,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableRow,
} from '@jsluna/react'
import { ErrorCircle } from '@jsluna/icons'
import BackButton from '../../../common/components/BackButton/BackButton'
import ErrorRefresh from '../../../common/components/ErrorRefresh'
import Header from '../../../common/components/Header'
import { GetHeaderMainMenus, headerMainMenus, headerPlannerTitles } from '../../../common/enums/HeaderItems'
import Loading from '../../../common/components/Loading'
import { Menu } from '../../../common/enums/MenuEnum'
import { IResult } from '../../../common/types/IResult'
import { getUserStore } from '../../../utils/localStore'
import { IBatch } from '../../types/IBatch'
import { IBatchItem } from '../../types/IBatchItem'
import { Context } from '../../../common/Context/context'
import { ErrorMessage } from '../../../common/enums/ErrorMessage'
import { useApiClient } from '../../../common/AppContext/appContext'
import { ISkuHeader } from '../../types/ISkuHeader'
import { getPizzaAndIsbProducts } from '../../api/displayApi'
import Incrementor from '../Production/Incrementor'
import { getStore } from '../../api/storeApi'
import { IStore } from '../../types/IStore'
import { createBatch } from '../../api/productionApi'
import { addBannerMessage } from '../../../common/Context/commonDispatch'
import ConfirmationBanner from '../../../common/components/Banners/ConfirmationBanner'
import { PlannerName } from '../../../common/enums/PlannerNameEnum'

const PizzaPlacement = () => {
  const apiClient = useApiClient()
  const history = useHistory()
  const { dispatch } = useContext(Context)
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  const [batch, setBatch] = useState<IBatch>({
    batchId: 0,
    items: [],
    programDescription: '',
    programId: 0,
    storeId: 0,
    temperaturesCount: 0,
  })
  const [hasServeOver, setHasServeOver] = useState(false)
  const [hasTurboServe, setHasTurboServe] = useState(false)
  const [isItemsValidated, setIsItemValidated] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const [rangedIsbProducts, setRangedIsbProducts] = useState<ISkuHeader[]>([])
  const [rangedPizzaSlices, setRangedPizzaSlices] = useState<ISkuHeader[]>([])
  const [rangedWholePizzas, setRangedWholePizzas] = useState<ISkuHeader[]>([])
  const [headerNavigationItems, setHeaderNavigationItems] = useState(headerMainMenus.hotfoodcounterNavs)

  const storeId = getUserStore().storeId

  // based on the skus, it will have a set of batchItems and add them to a batch for ServeOver and TurboServe
  // so that it will display the incrementor UI
  const buildBatchItems = (rangedSkus: ISkuHeader[]) => {
    const batchItems = rangedSkus.reduce((accItems: IBatchItem[], sku) => [...accItems, {
      calculatedQuantity: 0,
      portionSize: 1,
      quantityProduced: 0,
      quantitySold: 0,
      serveOver: true,
      skuId: sku.skuId,
      skuName: sku.skuName,
      toBeDisposedAt: new Date(),
      turboServe: false,
    }, {
      calculatedQuantity: 0,
      portionSize: 1,
      quantityProduced: 0,
      quantitySold: 0,
      serveOver: false,
      skuId: sku.skuId,
      skuName: sku.skuName,
      toBeDisposedAt: new Date(),
      turboServe: true,
    }], [])

    setBatch({ ...batch, items: sortBatchItems(batchItems) })
  }

  useEffect(() => {
    const getPizzaAndIsbProductsTask = getPizzaAndIsbProducts(apiClient, storeId)
    const getStoreTask = getStore(apiClient, storeId)

    GetHeaderMainMenus(apiClient, getUserStore().storeId, PlannerName.HotFood).then((res) => setHeaderNavigationItems(res))

    Promise.all([getPizzaAndIsbProductsTask, getStoreTask])
      .then((response: [ISkuHeader[], IStore]) => {
        setRangedIsbProducts(response[0].filter((p) => p.isIsbProduct))
        setRangedPizzaSlices(response[0].filter((p) => p.isPizzaSlice))
        setRangedWholePizzas(response[0].filter((p) => !p.isIsbProduct && !p.isPizzaSlice))
        buildBatchItems(response[0])
        setHasServeOver(response[1].serveOver)
        setHasTurboServe(response[1].turboServe)
        setIsLoading(false)
      })
      .catch(() => setIsError(true))
  }, [])

  // change SKU quantity
  const onSkuQuantityChange = (skuId: number, value: number, turboServe: boolean | undefined) => {
    if (turboServe === undefined) {
      return
    }

    // a set of non-changed items will be the base items
    const batchItems = batch.items.filter((i) => i.skuId !== skuId ||
      (i.skuId === skuId && i.turboServe !== turboServe))

    // add updated item to the base items
    batchItems.push({
      calculatedQuantity: 0,
      portionSize: 1,
      quantityProduced: value,
      quantitySold: 0,
      serveOver: !turboServe,
      skuId,
      skuName: batch.items.filter((i) => i.skuId === skuId)[0].skuName,
      toBeDisposedAt: new Date(),
      turboServe,
    })

    // sorted batch items will be updated on the batch
    setBatch({ ...batch, items: sortBatchItems(batchItems) })
  }

  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  // sort batch items by skuName
  const sortBatchItems = (batchItems: IBatchItem[]) =>
    batchItems.sort((a, b) =>
      a.skuName.toLowerCase() > b.skuName.toLowerCase() ? 1 : b.skuName.toLowerCase() > a.skuName.toLowerCase() ? -1 : 0
    )

  const validateItems = async () => {
    const countedItems = batch.items.filter((item) => item.quantityProduced > 0)

    setIsItemValidated(countedItems.length > 0)
    if (countedItems.length > 0) {
      await handleSave({ ...batch, items: countedItems })
    } else {
      scrollToTop()
    }
  }

  const handleSave = async (batchToSave: IBatch) => {

    if (batchToSave.items.length === 0) {
      return
    }

    await createBatch(
      apiClient,
      {
        items: batchToSave.items.map((item) => ({
          quantityProduced: item.quantityProduced,
          serveOver: item.serveOver,
          skuId: item.skuId,
          turboServe: item.turboServe,
        })),
        storeId,
      })
      .then((batchRes: IResult) => {
        if (batchRes.isSuccess) {
          dispatch(addBannerMessage(<ConfirmationBanner message={'Pizza and ISB product(s) added successfully.'}/>))
          history.push('/hotfoodDisplay')
        } else {
          setIsError(true)
          setErrorMessage(ErrorMessage.SaveException)
          scrollToTop()
        }
      })
      .catch(() => {
        setIsError(true)
        setErrorMessage(ErrorMessage.SaveException)
        scrollToTop()
      })
  }

  const renderTableHeader = (title: string, serveOver: boolean, turboServe: boolean) =>
    <TableHeader>
      <TableRow>
        <TableCell>{title}</TableCell>
        {serveOver &&
          <TableCell className={'c-pizza-placement-incrementor-heading'}>
            Counter
          </TableCell>}
        {turboServe &&
          <TableCell className={'c-pizza-placement-incrementor-heading'}>
            Turbo-serve
          </TableCell>}
      </TableRow>
    </TableHeader>

  const renderTableBody = (serveOver: boolean, turboServe: boolean, skus: ISkuHeader[]) =>
    <TableBody>
      {skus.map((sku: ISkuHeader) =>
        <TableRow key={sku.skuId}>
          <TableCell className='c-batch-table-responsive-title'>
            {sku.skuName}
          </TableCell>

          {/* A Sku will be displayed for both ServeOver and TurboServe Items */}
          {batch.items
            .filter((bi) => bi.skuId === sku.skuId)
            .sort((a: IBatchItem, b: IBatchItem) => Number(a.serveOver) < Number(b.serveOver) ? 1 : -1)
            .map((itemFromCabinet) => (
              <>
                {serveOver && itemFromCabinet.serveOver === true &&
                  <TableCell className='c-pizza-placement-incrementor'>
                    <Incrementor
                      id={itemFromCabinet.skuId}
                      step={1}
                      value={itemFromCabinet.quantityProduced}
                      setValue={(skuId: number, value: number) =>
                        onSkuQuantityChange(skuId, value, false)}
                      turboServe={false}
                    />
                  </TableCell>}

                {turboServe && itemFromCabinet.turboServe === true &&
                  <TableCell className='c-pizza-placement-incrementor'>
                    <Incrementor
                      id={itemFromCabinet.skuId}
                      step={1}
                      value={itemFromCabinet.quantityProduced}
                      setValue={(skuId: number, value: number) =>
                        onSkuQuantityChange(skuId, value, true)}
                      turboServe={true}
                    />
                  </TableCell>}
              </>
            ))}
        </TableRow>
      )}
    </TableBody>

  const renderCardHeader = (title: string) => <h6>{title}</h6>

  const renderCardRows = (serveOver: boolean, turboServe: boolean, skus: ISkuHeader[]) =>
    <>
      {skus.map((sku: ISkuHeader) =>
        <div className='display-pizza-slice-card-row ln-u-padding-top ln-u-padding-bottom'>
          <div className='ln-u-font-weight-bold ln-u-padding-left' key={sku.skuId}>{sku.skuName}</div>

          {batch.items
            .filter((bi) => bi.skuId === sku.skuId)
            .sort((a: IBatchItem, b: IBatchItem) => Number(a.serveOver) < Number(b.serveOver) ? 1 : -1)
            .map((itemFromCabinet) => (
              <>
                {serveOver && itemFromCabinet.serveOver === true &&
                  <div>
                    {turboServe &&
                      <GridItem className='display-pizza-slice-cabinet-label' size={'1/3'}>
                        Counter
                      </GridItem>}
                    <GridItem className='ln-u-padding-left-x2' size={'2/3'}>
                      <Incrementor
                        id={itemFromCabinet.skuId}
                        step={1}
                        value={itemFromCabinet.quantityProduced}
                        setValue={(skuId: number, value: number) =>
                          onSkuQuantityChange(skuId, value, false)}
                        turboServe={false}
                      />
                    </GridItem>
                  </div>}

                {turboServe && itemFromCabinet.turboServe === true &&
                  <div>
                    {hasServeOver &&
                      <GridItem className='display-pizza-slice-cabinet-label' size={'1/3'}>
                        Turbo-serve
                      </GridItem>}
                    <GridItem className='ln-u-padding-left-x2' size={'2/3'}>
                      <Incrementor
                        id={itemFromCabinet.skuId}
                        step={1}
                        value={itemFromCabinet.quantityProduced}
                        setValue={(skuId: number, value: number) =>
                          onSkuQuantityChange(skuId, value, true)}
                        turboServe={true}
                      />
                    </GridItem>
                  </div>}
              </>
            ))}
        </div>
      )}
    </>

  return (
    <div className='c-common-main-view-content'>
      <Header
        title={headerPlannerTitles.hotfood}
        navItems={headerNavigationItems}
        activeMenuType={Menu.Display}
      />
      <Container soft className='ln-u-push-top-sm'>
        {isLoading && <Loading />}
        {isError && <ErrorRefresh message={errorMessage ?? ErrorMessage.GeneralException} />}

        {!isLoading && (
          <>
            {BackButton('/hotfoodDisplay', 'Back to On Display')}
            <h4>Pizza and ISB products</h4>

            <div className='ln-u-body-1-fixed ln-u-push-bottom-sm'>
              Add pizza or ISB products to your display to keep track of removal times.

              {!isItemsValidated &&
                <div className='ln-u-font-weight-bold ln-u-padding-bottom ln-u-padding-top'>
                  <ErrorCircle className='c-form-alert-icon-red ln-u-margin-right' />
                  Please select at least one portion before adding to display
                </div>}
            </div>

            <Card>

              {/* ISB product(s) table */}
              {hasTurboServe && rangedIsbProducts.length > 0 &&
                <TableContainer className='c-display-pizza-slice-table-container'>
                  {renderTableHeader('ISB', false, hasTurboServe)}
                  {renderTableBody(false, hasTurboServe, rangedIsbProducts)}
                </TableContainer>}

              {/* pizza slice(s) table */}
              {hasTurboServe && rangedPizzaSlices.length > 0 &&
                <TableContainer className='c-display-pizza-slice-table-container ln-u-margin-top*4'>
                  {renderTableHeader('Pizza slices', false, hasTurboServe)}
                  {renderTableBody(false, hasTurboServe, rangedPizzaSlices)}
                </TableContainer>}

              {/* whole pizza(s) table */}
              {rangedWholePizzas.length > 0 &&
                <TableContainer className='c-display-pizza-slice-table-container ln-u-margin-top*4'>
                  {renderTableHeader('Whole pizzas', hasServeOver, hasTurboServe)}
                  {renderTableBody(hasServeOver, hasTurboServe, rangedWholePizzas)}
                </TableContainer>}

              <div className='c-display-pizza-slice-card-container'>
                {/* ISB product(s) cards */}
                {hasTurboServe && rangedIsbProducts.length > 0 &&
                  <>
                    {renderCardHeader('ISB')}
                    {renderCardRows(false, hasTurboServe, rangedIsbProducts)}
                  </>}

                {/* pizza slice(s) cards */}
                {hasTurboServe && rangedPizzaSlices.length > 0 &&
                  <div className='ln-u-padding-top*4'>
                    {renderCardHeader('Pizza slices')}
                    {renderCardRows(false, hasTurboServe, rangedPizzaSlices)}
                  </div>}

                {/* whole pizza(s) cards */}
                {rangedWholePizzas.length > 0 &&
                  <div className='ln-u-padding-top*4'>
                    {renderCardHeader('Whole pizzas')}
                    {renderCardRows(hasServeOver, hasTurboServe, rangedWholePizzas)}
                  </div>}

              </div>

              <ButtonGroupWrapper>
                <ButtonGroupSecondary className='u-full-width'>
                  <FilledButton onClick={validateItems}>
                    Add to display
                  </FilledButton>

                  <OutlinedButton className='ln-u-margin-left' onClick={() => history.push('/hotfoodDisplay')}>
                    Cancel
                  </OutlinedButton>
                </ButtonGroupSecondary>
              </ButtonGroupWrapper>

            </Card>
          </>
        )}
      </Container>
    </div >
  )
}

export default PizzaPlacement
