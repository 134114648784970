/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React from 'react'
import { Tutorial } from '@jsainsburyplc/retail-stateful-tutorial'
import { IContext } from '../types/IContext'
import { TutorialProjectName } from '../enums/TutorialProjectNameEnum'
import { TutorialVersion } from '../enums/TutorialVersion'
import { PlannerName } from '../enums/PlannerNameEnum'

interface ITutorialWrapperProps {
  children: React.ReactNode
  state: IContext
  token: string
}

type PartialEnabledCaseKeys = 'HotFood_forecasting' | 'Bakery_golpicklist' | 'Bakery_ingredients'

const partialEnabledVersions: { [key in PartialEnabledCaseKeys]: number } = {
  HotFood_forecasting: 6,
  Bakery_ingredients: 4,
  Bakery_golpicklist: 3,
}

const getTutorialEnvironment = (): string => {
  const namespace = `${window.env?.NAMESPACE ?? process.env.REACT_APP_NAMESPACE}`
  return namespace === 'productionplanning-prod' ? 'prod' : 'preprod'
}

const getTutorialProjectName = (
  planner: PlannerName
): TutorialProjectName => TutorialProjectName[planner]

const getTutorialVersion = (
  planner: PlannerName,
  enabledFeatures: Partial<{ [key: string]: boolean }>
): number => {
  // Ensure `partialEnabledCaseKey` is of type `PartialEnabledCaseKeys` or handle it appropriately
  const featureKey = Object.keys(enabledFeatures).find((key) => enabledFeatures[key]) || ''
  const partialEnabledCaseKey = `${planner}_${featureKey}`

  const version = (partialEnabledVersions[partialEnabledCaseKey as PartialEnabledCaseKeys])
    ? partialEnabledVersions[partialEnabledCaseKey as PartialEnabledCaseKeys]
    : TutorialVersion[planner] || 1

  return version
}

const TutorialWrapper = ({
  children,
  state,
  token,
  enabledFeatures = {},
}: ITutorialWrapperProps & {
  enabledFeatures?: Partial<{ [key: string]: boolean }>
}) => (token !== 'disabled' && token !== 'fake') ? (
  <Tutorial
    project={getTutorialProjectName(state.planner)}
    version={getTutorialVersion(state.planner, enabledFeatures)}
    fetch={(i: RequestInfo, r: RequestInit) =>
      fetch(i, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'content-type': 'application/json',
          ...r.headers,
        },
        ...r,
      })
    }
    environment={getTutorialEnvironment()}
    notSkippable
  >
    {children}
  </Tutorial>
) : (
  <> {children} </>
)

export default TutorialWrapper
