import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Search } from '@jsluna/icons'
import {
  Card, Container, FilledButton, GridItem,
  GridWrapper, Link, SearchField, TableBody, TableCell, TableContainer, TableRow,
} from '@jsluna/react'
import { getSkus } from '../../../common/api/skuApi'
import { useApiClient } from '../../../common/AppContext/appContext'
import Header from '../../../common/components/Header'
import ScrollToPrevious from '../../../common/components/ScrollToPrevious'
import {
  GetHeaderMainMenus,
  headerMainMenus,
  HeaderNavItem,
  headerPlannerTitles,
} from '../../../common/enums/HeaderItems'
import { Menu } from '../../../common/enums/MenuEnum'
import { PlannerName } from '../../../common/enums/PlannerNameEnum'
import { ISku } from '../../../common/types/ISku'
import { getUserStore, setCurrentPlanner } from '../../../utils/localStore'
import { Context } from '../../../common/Context/context'
import { setPlanner } from '../../../common/Context/commonDispatch'
import TutorialWrapper from '../../../common/components/TutorialWrapper'
import Loading from '../../../common/components/Loading'
import { ErrorMessage } from '../../../common/enums/ErrorMessage'
import ErrorRefresh from '../../../common/components/ErrorRefresh'

const QpigListView = () => {
  const { state, dispatch } = useContext(Context)
  const [tutorialToken, setTutorialToken] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const [searchInput, setSearchInput] = useState('')
  const [skus, setSkus] = useState<ISku[]>([])
  const [headerNavigationItems, setHeaderNavigationItems] = useState(headerMainMenus.bakeryNavs)

  const apiClient = useApiClient()
  const history = useHistory()
  const store = getUserStore()

  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    setIsLoading(true)

    dispatch(setPlanner(PlannerName.Bakery))

    const getHeaderMainMenusTask = GetHeaderMainMenus(apiClient, store.storeId, PlannerName.Bakery)
    const getSkusTask = getSkus(apiClient, 'bakery', store.storeId)
    const getTutorialTokenTask = apiClient.getTutorialAccessToken()

    // Do not change the order of the promises in the Promise.all
    // Once the default tutorial not related with a feature is rendered,
    // it will not re-render again so all set states should be done before tutorial token is set
    Promise.all([
      getHeaderMainMenusTask,
      getSkusTask,
      getTutorialTokenTask,
    ])
      .then((responses: [HeaderNavItem[], ISku[], string | null,]) => {
        setHeaderNavigationItems(responses[0])

        setSkus(responses[1])
        setTutorialToken(responses[2])
      })
      .catch((e) => {
        if (process.env.NODE_ENV !== 'production') {
          // eslint-disable-next-line no-console
          console.log(e)
        }
        setErrorMessage(`${ErrorMessage.QpigView.FailedToLoad} - ${(e as Error).message}`)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [apiClient, dispatch, store.storeId])

  const openQpig = (skuId: number, e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    setCurrentPlanner('bakery')
    history.push(`/qpig/${skuId}`)
  }

  const search = searchInput.trim().toLowerCase()
  const filteredSkus = skus.filter(
    (sku) => sku.skuName.toLowerCase().includes(search) || sku.skuId.toString().includes(search),
  )
  const getSku = () => filteredSkus
    .sort((a, b) => (a.skuName.trim() > b.skuName.trim()) ? 1 : -1)
    .map((skuValue, i) => {
      const getSkuId = skuValue.skuId
      const getSkuName = skuValue.skuName
      return (
        <TableRow data-testid='qpig-listview-row' key={i + 1}>
          <TableCell>
            <Link
              className='ln-u-text-decoration-underline c-common-hover-over-cursor'
              href=''
              onClick={(e: React.MouseEvent<HTMLElement>) => openQpig(getSkuId, e)}>
              {`${getSkuName} (${getSkuId})`}
            </Link>
          </TableCell>
        </TableRow>
      )
    })

  return (
    <>
      { !tutorialToken ? (
        <Loading message='Tutorial Loading' />
      ) : (
        <TutorialWrapper
          state={state}
          token={tutorialToken || ''}
        >
          <Header
            title={headerPlannerTitles.bakery}
            navItems={headerNavigationItems}
            activeMenuType={Menu.Qpig}
          />
          <Container soft className='ln-u-push-top-sm'>

            {isLoading && <Loading />}

            {!isLoading && errorMessage && <ErrorRefresh message={errorMessage} />}

            {!isLoading && !errorMessage &&
              <div className='c-common-main-view-content'>
                <h4>Qpigs</h4>
                <Card>
                  <div>
                    <GridWrapper>
                      <GridItem size={'1/1'}>
                        <SearchField
                          name='searchField'
                          label='Search Qpigs'
                          placeholder='Enter product name or SKU number'
                          hasIcon
                          icon={<Search />}
                          value={searchInput}
                          onChange={(e: { currentTarget: { value: string } }) => {
                            setSearchInput(e.currentTarget.value)
                          }}
                        />
                      </GridItem>
                      <GridItem size={'1/1'}>
                        <ScrollToPrevious scrollAreaId='qpig-list-view-scroll-area'>
                          <TableContainer>
                            <TableBody className='tableBody'>
                              {getSku()}
                            </TableBody>
                          </TableContainer>
                        </ScrollToPrevious>
                      </GridItem>
                    </GridWrapper>
                  </div>
                  <div className='ln-u-text-align-center ln-u-margin*2'>
                    <FilledButton
                      data-testid='return-to-top'
                      onClick={() => scrollToTop()}>
                      Return to top
                    </FilledButton>
                  </div>
                </Card>
              </div>
            }
          </Container>
        </TutorialWrapper>
      )}
    </>
  )
}

export default QpigListView
