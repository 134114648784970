import React, { useEffect, useState } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import { RouteComponentProps } from 'react-router-dom'
import { Cancel } from '@jsluna/icons'
import {
  Card,
  Container,
  FilledButton,
  GridItem,
  GridWrapper,
  Link,
  OutlinedButton,
} from '@jsluna/react'
import { StarFilled } from '@sainsburys-tech/icons'
import { useApiClient } from '../../../common/AppContext/appContext'
import { IMix } from '../../types/IMix'
import { getMixSkus, updateMixSkus, updateMixStatus } from '../../api/mixApi'
import { ErrorMessage } from '../../../common/enums/ErrorMessage'
import IMixSkuUpdate from '../../types/IMixSkuUpdate'
import Loading from '../../../common/components/Loading'
import ErrorRefresh from '../../../common/components/ErrorRefresh'
import { MixStageEnum } from '../../enums/MixStageEnum'
import IMixSku from '../../types/IMixSku'
import { toUkTimeDateStringFrom } from '../../../common/utils/times'
import MixSkusTitle from './MixSkusTitle'
import MixSkusTableHeader from './MixSkusTableHeader'
import MixSkusRowCompleted from './MixSkusRowCompleted'
import MixSkusRowPending from './MixSkusRowPending'
import MixSkusIncrementor from './MixSkusIncrementor'

interface IMixSkusCardRouterProps {
  id: string
  date: string
  preview: string
}

const MixSkusView = (props: RouteComponentProps<IMixSkusCardRouterProps>) => {
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const [currentMixScrollPosition, setCurrentMixScrollPosition] = useState(0)
  const [mix, setMix] = useState<IMix>()
  const [allMixSkusMarked, setAllMixSkusMarked] = useState(false)

  const currentMixId = Number(props.match.params.id)
  const planDate = new Date(props.match.params.date)

  const apiClient = useApiClient()

  useEffect(() => {
    const el = document.querySelector('#mix-scroll-area')
    if (el !== null) {
      const child = el.firstElementChild
      if (child !== null) {
        setTimeout(() => (child.scrollTop = currentMixScrollPosition), 10)
      }
    }
  }, [])

  useEffect(() => {
    const getMixSkusTask = getMixSkus(apiClient, planDate, currentMixId)

    Promise.all([
      getMixSkusTask,
    ])
      .then((responses: [IMix[]]) => {
        const initMix = responses[0][0]
        console.log(initMix)
        setMix(initMix)
        setAllMixSkusMarked(initMix.mixSkus.some((mixSku) => mixSku.marked === false) ? false : true)
      })
      .catch((e) => {
        if (process.env.NODE_ENV !== 'production') {
          // eslint-disable-next-line no-console
          console.log(e)
        }
        setErrorMessage(`${ErrorMessage.ProductionView.FailedToLoad} - ${(e as Error).message}`)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  const isMixCompleted = () => mix !== undefined && mix.mixStatusStageId === (MixStageEnum.Completed as number)
  const canProgressMix = () => isMixCompleted() === false && mix !== undefined && mix.isReadonly === false

  const openQpig = (mixSku: IMixSku) => {
    const scrollAreaElement: any = document.querySelector('#mix-scroll-area')
    if (scrollAreaElement !== null) {
      const scrollAreaElementChild = scrollAreaElement.firstElementChild
      if (scrollAreaElementChild !== null) {
        setCurrentMixScrollPosition(scrollAreaElementChild.scrollTop)
      }
    }

    props.history.push(`/qpig/${mixSku.skuId}`)
  }

  const skuSort = (skuA: IMixSku, skuB: IMixSku): number => skuA.positionOnOutput < skuB.positionOnOutput ? -1 : 1

  const getUpdatedMixSkus = () => mix?.mixSkus
    .filter((ms: IMixSku) => ms.changed === true)
    .map(
      (ms: IMixSku): IMixSkuUpdate => ({
        marked: ms.marked,
        mixSkuId: ms.mixSkuId,
        productionActual: ms.productionActual,
      }) as IMixSkuUpdate
    ) || []

  const progressMix = async () => {
    if (mix !== null && mix !== undefined) {
      const nextStage = mix.mixStatusStageId + 1
      const mixSkusToUpdate = getUpdatedMixSkus()

      try {
        await updateMixStatus(apiClient,
          mix.planId,
          mix.mixId,
          nextStage,
          mixSkusToUpdate,
          (nextStage === MixStageEnum.InProgress as number),
          mix.rowVersion)

        props.history.push('/bakeryview')
      } catch (e) {
        setErrorMessage(`${ErrorMessage.ProductionView.FailedToUpdate} - ${(e as Error).message}`)
      }
    }
  }

  const handleUpdateCloseView = () => {
    if (!mix) {
      setErrorMessage(`${ErrorMessage.ProductionView.FailedToLoad} - No Mix found`)
      return
    }

    const mixSkusToUpdate = getUpdatedMixSkus()
    if (mixSkusToUpdate.length > 0) {
      updateMixSkus(apiClient, mix.mixId, mixSkusToUpdate)
        .then((response) => {
          if (!response.ok) {
            return
          }
        })
        .catch((e) => {
          setErrorMessage(`${ErrorMessage.ProductionView.FailedToUpdate} - ${(e as Error).message}`)
          return
        })
    }

    props.history.push('/bakeryview')
  }

  const handleSkuChecked = (mixSkuId: number) => {
    if (!mix) {
      return
    }

    const updatedMixSkus = mix.mixSkus.reduce((acc: IMixSku[], cur: IMixSku) => {
      if (cur.mixSkuId === mixSkuId) {
        return [...acc, {
          ...cur,
          marked: !cur.marked,
          changed: true,
        }]
      }
      return [...acc, cur]
    }, [])

    setMix({...mix, mixSkus: updatedMixSkus})
  }

  const handleAllSkusChecked = () => {
    if (!mix) {
      return
    }

    const updatedMixSkus = mix.mixSkus.reduce((acc: IMixSku[], cur: IMixSku) => [...acc, {
      ...cur,
      marked: (allMixSkusMarked) ? false : true,
      changed: true,
    }], [])

    setMix({...mix, mixSkus: updatedMixSkus})

    setAllMixSkusMarked(!allMixSkusMarked)
  }

  const handleUpdateMixProductionActual = (
    mixSkuId: number,
    isIncrement: boolean,
    productionActual?: number
  ) => {
    if (!mix) {
      return
    }

    const updatedSkus = mix.mixSkus.map((mixSku) => {
      if (mixSku.mixSkuId === mixSkuId) {
        if (productionActual === null || productionActual === undefined) {
          mixSku.productionActual = isIncrement ? mixSku.productionActual + 1 : mixSku.productionActual - 1
        } else {
          mixSku.productionActual = productionActual || 0
        }
        mixSku.changed = true
      }
      return mixSku
    })
    setMix({ ...mix, mixSkus: updatedSkus })
  }

  const renderSkuCheckbox = (mixId: number, mixSku: IMixSku) => (
    <div className='ln-c-form-option--checkbox'>
      <input
        className='ln-c-form-option__input'
        type='checkbox'
        id={mixSku.mixSkuId.toString()}
        name={mixSku.mixSkuId.toString()}
        checked={mixSku.marked}
        onChange={() => handleSkuChecked(mixSku.mixSkuId)}
      />
      <label
        className='ln-c-form-option__label u-no-right-padding-sm'
        htmlFor={mixId.toString()}
        onClick={() => handleSkuChecked(mixSku.mixSkuId)}
      />
    </div>
  )

  const renderSkuQpigLink = (mixSku: IMixSku) => (
    <Link className='ln-u-text-decoration-underline c-common-hover-over-cursor' onClick={() => openQpig(mixSku)}>
      {mixSku.skuName}
      {mixSku.isCredibleProduct === true &&
        <StarFilled height='25px' fill='black' className='ln-u-margin-left c-table__icon' />
      }
    </Link>
  )

  const renderSkuActualProductionIncrementor = (mixSku: IMixSku) => (
    <MixSkusIncrementor mixSku={mixSku} setActualProduction={handleUpdateMixProductionActual} />
  )

  const renderEditableSkuList = (mx: IMix) => (
    mx.mixSkus.sort(skuSort).map((mixSku: IMixSku) => (
      <tr className='ln-c-table__row' key={mixSku.mixSkuId} id={`MixSkuId_${mixSku.mixSkuId}`}>
        <td className='ln-c-table__cell c-table__cell--checkbox ln-u-hard u-checkbox-sm'>
          {renderSkuCheckbox(mx.mixId, mixSku)}
        </td>

        <td className='ln-c-table__cell c-table__cell--type-sku u-display-lg'>{renderSkuQpigLink(mixSku)}</td>
        <td className='ln-c-table__cell c-table__cell--production-target u-display-lg'>{mixSku.productionTarget}</td>
        <td className='ln-c-table__cell c-table__cell--production-actual u-display-lg'>
          {renderSkuActualProductionIncrementor(mixSku)}
        </td>

        <td className='ln-c-table__cell u-no-left-padding-sm u-display-sm'>
          <div className='ln-u-font-weight-bold ln-u-margin-top*1/2'>{renderSkuQpigLink(mixSku)}</div>
          <GridWrapper className='ln-u-margin-top'>
            <GridItem size='3/4'>Production target</GridItem>
            <GridItem size='1/4'>{mixSku.productionTarget}</GridItem>
          </GridWrapper>
          <div className='ln-u-margin-top'>Actual production</div>
          <div className='ln-u-margin-top'>{renderSkuActualProductionIncrementor(mixSku)}</div>
        </td>
      </tr>
    ))
  )

  const renderSkuList = (mx: IMix) => {
    if (mx.mixStatusStageId === MixStageEnum.InProgress as number) {
      return renderEditableSkuList(mx)
    }

    if (mx.mixStatusStageId === MixStageEnum.Completed as number) {
      return mx.mixSkus
        .sort(skuSort)
        .map((mixSku) => (
          <MixSkusRowCompleted
            key={mixSku.mixSkuId}
            mixSku={mixSku}
            openQpig={openQpig}
          />
        ))
    }

    return mx.mixSkus
      .sort(skuSort)
      .map((mixSku) => (
        <MixSkusRowPending
          key={mixSku.mixSkuId}
          mixSku={mixSku}
          openQpig={openQpig}
        />
      ))
  }

  const renderEditButton = (mx: IMix) => {
    if (mx.isCompletedEditable === true) {
      return (
        <OutlinedButton disabled={mx.updating === true} fullWidth onClick={() => progressMix()}>
          Edit
        </OutlinedButton>
      )
    } else {
      return (
        <FilledButton disabled={mx.updating === true} fullWidth onClick={handleUpdateCloseView}>
          Close
        </FilledButton>
      )
    }
  }

  return (
    <Container soft>
      <Card className='c-bakery-full-page-card c-mix-wrapper'>
        <button className='ln-c-button ln-c-dismiss'
          role='button'
          title='Close'
          type='button'
          onClick={handleUpdateCloseView}
        >
          <Cancel />
        </button>

        {isLoading && <Loading />}

        {!isLoading && errorMessage && <ErrorRefresh message={errorMessage} />}

        {!isLoading && !errorMessage && mix !== undefined && mix.plannerGroup !== undefined && (
          <>
            <MixSkusTitle
              plannerGroupName={mix.plannerGroup !== null ? mix.plannerGroup.plannerGroupName : ''}
              ovenSettingDetail={mix.ovenSettingDetail}
            />
            {mix.plannerGroup && mix.plannerGroup.displayFlourWeight === true && (
              <div>
                <span className='ln-u-display-1-fixed ln-u-push-left'>Yield&nbsp;</span>
                <span>
                  {mix.yieldKg}kg&nbsp;{mix.yieldBags}&nbsp;bags
                </span>
              </div>
            )}

            <table className='ln-c-table u-display-lg'>
              <thead className='ln-c-table__header'>
                <MixSkusTableHeader
                  mix={mix}
                  allMixSkusMarked={allMixSkusMarked}
                  handleCheckAll={() => handleAllSkusChecked()}
                />
              </thead>
            </table>

            {canProgressMix() === true && (mix.mixStatusStageId === MixStageEnum.InProgress as number) && (
              <div className='ln-c-form-option--checkbox u-display-sm'>
                <input
                  className='ln-c-form-option__input'
                  type='checkbox'
                  id='select-all'
                  name='select-all'
                  checked={allMixSkusMarked}
                  onChange={() => handleAllSkusChecked()}
                />
                <label
                  className='ln-c-form-option__label ln-u-font-weight-bold ln-u-margin-top ln-u-margin-bottom'
                  htmlFor='select-all'
                >
                  Select all
                </label>
              </div>
            )}

            <div className='ln-c-table-container c-mix-body'>
              <Scrollbars id='mix-scroll-area'>
                <table className='ln-c-table'>
                  <tbody className='ln-c-table__body'>{renderSkuList(mix)}</tbody>
                </table>

                {canProgressMix() === false && (
                  <div className='c-mix-audit' data-testid='mix-audit'>
                    Started by: {mix.startedBy} at {toUkTimeDateStringFrom(mix.startedAt)}
                    <br />
                    Completed by: {mix.completedBy} at {toUkTimeDateStringFrom(mix.completedAt)}
                  </div>
                )}
              </Scrollbars>
            </div>

            <div className='c-mix-footer'>
              {mix !== undefined && (
                <Card className='c-mix-body__button-group-border'>

                  { props.match.params.preview !== 'true' &&
                    canProgressMix() === true &&
                    (mix.mixStatusStageId === MixStageEnum.Pending as number) &&
                    <FilledButton disabled={mix.updating === true} fullWidth onClick={() => progressMix()}>
                      Start
                    </FilledButton>
                  }

                  { props.match.params.preview === 'true' &&
                    <FilledButton disabled={mix.updating === true} fullWidth onClick={handleUpdateCloseView}>
                      Close preview
                    </FilledButton>
                  }

                  { canProgressMix() === true &&
                    (mix.mixStatusStageId === MixStageEnum.InProgress as number) && (
                    <GridWrapper>
                      <GridItem className='u-padding-bottom-sm' size={{ xs: '1/2', lg: '1/2', default: '1/1' }}>
                        <OutlinedButton disabled={mix.updating === true} fullWidth onClick={handleUpdateCloseView}>
                          Save and close
                        </OutlinedButton>
                      </GridItem>

                      <GridItem size={{ xs: '1/2', lg: '1/2', default: '1/1' }}>
                        <FilledButton disabled={mix.updating === true} fullWidth onClick={() => progressMix()}>
                          All items completed
                        </FilledButton>
                      </GridItem>
                    </GridWrapper>
                  )}

                  {canProgressMix() === false && renderEditButton(mix)}
                </Card>
              )}
            </div>
          </>
        )}
      </Card>
    </Container>
  )
}

export default MixSkusView
