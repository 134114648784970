import React, {
  useContext,
  useEffect,
  useState,
} from 'react'
import {
  Container,
  GridItem,
  GridWrapper,
} from '@jsluna/react'
import dayjs from 'dayjs'
import '../../../common/utils/times'
import { useHistory } from 'react-router-dom'
import { useApiClient } from '../../../common/AppContext/appContext'
import InfoBanner from '../../../common/components/Banners/InfoBanner'
import ErrorRefresh from '../../../common/components/ErrorRefresh'
import Header from '../../../common/components/Header'
import {
  GetHeaderMainMenus,
  headerMainMenus,
  HeaderNavItem,
  headerPlannerTitles,
} from '../../../common/enums/HeaderItems'
import { Menu } from '../../../common/enums/MenuEnum'
import Loading from '../../../common/components/Loading'
import TutorialWrapper from '../../../common/components/TutorialWrapper'
import { setPlanner } from '../../../common/Context/commonDispatch'
import { Context } from '../../../common/Context/context'
import { ErrorMessage } from '../../../common/enums/ErrorMessage'
import { PlannerName } from '../../../common/enums/PlannerNameEnum'
import { toIsoDateStringFrom, toNthDayOfWeekFullDatetimeString } from '../../../common/utils/times'
import { getUserStore } from '../../../utils/localStore'
import { getMixes, updateMixStatus } from '../../api/mixApi'
import { MixStageEnum } from '../../enums/MixStageEnum'
import { IMix } from '../../types/IMix'
import AlertMixStatusModal from './AlertMixStatusModal'
import AllergenMixModal from './AllergenMixModal'
import CleanMixModal from './CleanMixModal'
import WaveMixCard from './WaveMixCard'

interface IWaveState {
  plannerGroupId: number
  ovenSettingId: number
  ovenTypeDescription: string
  wave: number
}

const ProductionView = () => {
  const { dispatch, state } = useContext(Context)
  const history = useHistory()

  const [tutorialToken, setTutorialToken] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const [mixes, setMixes] = useState<IMix[]>([])
  const [currentMixId, setCurrentMixId] = useState<number>(0)
  const [canAutoScroll, setCanAutoScroll] = useState(true)
  const [isCleanMixModalOpened, setIsCleanMixModalOpened] = useState(false)
  const [isAllergenMixModalOpened, setIsAllergenMixModalOpened] = useState(false)
  const [isAlertMixStatusModalOpened, setIsAlertMixStatusModalOpened] = useState(false)
  const [mixStatusAlertMessage, setMixStatusAlertMessage] = useState('')
  const [reload, setReload] = useState(false)
  const [headerNavigationItems, setHeaderNavigationItems] = useState(headerMainMenus.bakeryNavs)

  const apiClient = useApiClient()
  const store = getUserStore()

  let hasIncompleteNonAllergenMixes = false

  useEffect(() => {
    setIsLoading(true)

    dispatch(setPlanner(PlannerName.Bakery))

    const getHeaderMainMenusTask = GetHeaderMainMenus(apiClient, store.storeId, PlannerName.Bakery)
    const getMixesTask = getMixes(apiClient, new Date())
    const getTutorialTokenTask = apiClient.getTutorialAccessToken()

    // Do not change the order of the promises in the Promise.all
    // Once the default tutorial not related with a feature is rendered,
    // it will not re-render again so all set states should be done before tutorial token is set
    Promise.all([
      getHeaderMainMenusTask,
      getMixesTask,
      getTutorialTokenTask,
    ])
      .then((responses: [HeaderNavItem[], IMix[], string|null]) => {
        setHeaderNavigationItems(responses[0])
        setMixes(responses[1])
        setTutorialToken(responses[2])
        scrollToLastMix()
      })
      .catch((e) => {
        if (process.env.NODE_ENV !== 'production') {
          // eslint-disable-next-line no-console
          console.log(e)
        }
        setErrorMessage(`${ErrorMessage.ProductionView.FailedToLoad} - ${(e as Error).message}`)
      })
      .finally(() => {
        setIsLoading(false)
        setReload(false)
      })

    const scrollToLastMix = () => {
      if (canAutoScroll === true) {
        // const currentMixId = props.currentMixId
        if (currentMixId !== undefined && currentMixId !== null && currentMixId !== 0) {
          const el = document.querySelector(`#MixId_${currentMixId}`)
          if (el !== null) {
            el.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'nearest' })
          }
        }
      }
    }
  }, [reload, apiClient, dispatch, canAutoScroll, currentMixId, store.storeId])

  const isMixNextDay = (mix: IMix): boolean => {
    const tomorrow = dayjs().add(1, 'day').format('YYYY/MM/DD')
    const mixDate = dayjs(mix.plan.planDate).format('YYYY/MM/DD')
    return tomorrow === mixDate
  }

  const getWavesForToday = () => {
    const wavesCurrentDay = mixes.filter(
      (mix) => isMixNextDay(mix) === false && mix.containsAllergens === false
    )
    return getWaves(wavesCurrentDay)
  }

  const getWavesForTomorrow = () => {
    const wavesForTomorrow = mixes.filter(
      (mix) => isMixNextDay(mix) === true && mix.containsAllergens === false
    )
    return getWaves(wavesForTomorrow)
  }

  const getWavesForAllergens = () => {
    const wavesForAllergens = mixes.filter((mix) => mix.containsAllergens === true)
    return getWaves(wavesForAllergens)
  }

  const getWaveHeader = (mixesForWave: IMix[], waveNumber: number): string => {
    if (mixesForWave.some((mix) => mix.containsAllergens)) {
      return 'Last Wave'
    }
    if (mixesForWave.some((mix) => isMixNextDay(mix))) {
      return 'Overnights'
    }
    return `Wave ${waveNumber}`
  }

  const isOvernightButCantStart = (mixesForWave: IMix[]): boolean =>
    mixesForWave.some((mix) => isMixNextDay(mix) && mix.plan.requiresMixRefresh)

  const displayForecastPendingWarning = (mixesForWave: IMix[]): any => {
    if (isOvernightButCantStart(mixesForWave)) {
      return <InfoBanner message='Overnights is pending updates. Try again in 10 minutes.' />
    }
  }

  const getWaves = (wMixes: IMix[]) => {
    const distinctWaveNumbers = [...new Set(wMixes.map((mix) => mix.wave))]
    const waveComponents: JSX.Element[] = []
    const enabledOrCompletedMixList: IWaveState[] = []
    distinctWaveNumbers
      .sort((waveA, waveB) => waveA - waveB)
      .forEach((waveNumber) => {
        const mixesForWave = wMixes.filter((mix: IMix) => {
          const isIncomplete =
            mix.wave === waveNumber &&
            ( (mix.mixStatusStageId === MixStageEnum.Pending as number) ||
              (mix.mixStatusStageId === MixStageEnum.InProgress as number) )

          if (isIncomplete) {
            if (!mix.containsAllergens) {
              hasIncompleteNonAllergenMixes = true
            }
            const ovenSettingIdInProcess = (mix.ovenSettingDetail && mix.ovenSettingDetail.ovenSettingId) || 0
            const ovenSettingDescriptionInProcess =
              mix.ovenSettingDetail !== null && mix.ovenSettingDetail !== undefined
                ? mix.ovenSettingDetail.ovenTypeDescription
                : ''

            if (
              enabledOrCompletedMixList.findIndex(
                (ws) =>
                  ws.plannerGroupId === mix.plannerGroupId &&
                  ws.wave !== waveNumber &&
                  ws.ovenSettingId === ovenSettingIdInProcess
              ) === -1 &&
              ((mix.containsAllergens && !hasIncompleteNonAllergenMixes) || !mix.containsAllergens)
            ) {
              mix.enabled = true
              const newWaveState: IWaveState = {
                ovenSettingId: ovenSettingIdInProcess,
                ovenTypeDescription: ovenSettingDescriptionInProcess,
                plannerGroupId: mix.plannerGroupId,
                wave: waveNumber,
              }
              enabledOrCompletedMixList.push(newWaveState)
            }
          }

          return isIncomplete
        })

        if (mixesForWave.length > 0) {
          waveComponents.push(
            <div data-name='wave-header' data-testid='wave-header' key={waveNumber} className='ln-u-text-align-left'>
              <div className='ln-u-display-3-fixed ln-u-push-bottom-sm' data-testid='wave-header-text'>
                {getWaveHeader(mixesForWave, waveNumber)}
              </div>

              {displayForecastPendingWarning(mixesForWave)}

              <div className='ln-u-text-align-left'>
                <WaveMixCard
                  mixesForWave={mixesForWave}
                  handleMixActions={handleMixNextActions}
                />
              </div>
            </div>
          )
        }
      })

    return waveComponents
  }

  const getCompletedWavesForToday = () => {
    const wavesCurrentDay = mixes.filter(
      (mix) => isMixNextDay(mix) === false && mix.containsAllergens === false
    )
    return getCompletedWaves(wavesCurrentDay)
  }

  const getCompletedWavesForTomorrow = () => {
    const wavesForTomorrow = mixes.filter(
      (mix) => isMixNextDay(mix) === true && mix.containsAllergens === false
    )
    return getCompletedWaves(wavesForTomorrow)
  }

  const getCompletedWavesForAllergens = () => {
    const wavesForAllergens = mixes.filter((mix) => mix.containsAllergens === true)
    return getCompletedWaves(wavesForAllergens)
  }

  const getCompletedWaves = (completedMixes: IMix[]) => {
    const distinctWaveNumbers = [...new Set(completedMixes.map((mix) => mix.wave))]
    const waveComponents: JSX.Element[] = []
    distinctWaveNumbers
      .sort((a, b) => a - b)
      .forEach((waveNumber) => {
        const mixesForWave = completedMixes.filter((mix: IMix) =>
          mix.wave === waveNumber && (mix.mixStatusStageId === MixStageEnum.Completed as number)
        )

        if (mixesForWave.length > 0) {
          waveComponents.push(
            <div key={waveNumber} className='ln-u-text-align-left'>
              <div className='ln-u-display-3-fixed ln-u-push-bottom-sm'>
                {getWaveHeader(mixesForWave, waveNumber)}
              </div>
              <div className='ln-u-text-align-left'>
                <WaveMixCard
                  mixesForWave={mixesForWave}
                  handleMixActions={handleMixNextActions}
                />
              </div>
            </div>
          )
        }
      })

    return waveComponents
  }

  const handleMixNextActions = async (mix: IMix, nextStatus?: MixStageEnum) => {
    setCurrentMixId(mix.mixId)
    setCanAutoScroll(false)

    if (nextStatus === MixStageEnum.Completed) {
      if (mix.mixStatusStageId === MixStageEnum.Completed as number) {
        setMixStatusAlertMessage('This wave has already been completed')
        setIsAlertMixStatusModalOpened(true)
        return
      }

      try {
        await updateMixStatus(apiClient,
          mix.planId,
          mix.mixId,
          nextStatus,
          mix.mixSkus,
          (mix.isMixerClean === true),
          mix.rowVersion)

        history.push('/bakeryview')
        setReload(true)
        return
      } catch (e) {
        setErrorMessage(`${ErrorMessage.ProductionView.FailedToUpdate} - ${(e as Error).message}`)
        return
      }
    }

    // ask a clean-belt process when it's on the pending stage OR the display-flour-weight of planner group is true.
    if (( mix.mixStatusStageId === MixStageEnum.Pending as number) &&
          mix.plannerGroup &&
          mix.plannerGroup.displayFlourWeight ) {
      if (!mix.enabled) {
        // it won't take a stage progress on a 'Preview Wave', and won't ask a clean belt requirement.
        history.push(`/mixskusview/${mix.mixId}/${toIsoDateStringFrom(mix.plan.planDate)}/true`)
      }

      setIsCleanMixModalOpened(true)
      return
    }

    history.push(`/mixskusview/${mix.mixId}/${toIsoDateStringFrom(mix.plan.planDate)}/false`)
  }

  const handleCleanStartMix = () => {
    setIsCleanMixModalOpened(false)

    const currentMix = mixes.find((mx) => mx.mixId === currentMixId) as IMix
    if (currentMix.containsAllergens) {
      setIsAllergenMixModalOpened(true)
      return
    }

    history.push(`/mixskusview/${currentMixId}/${toIsoDateStringFrom(currentMix.plan.planDate)}/false`)
  }

  const handleAllergenStartMix = () => {
    setIsAllergenMixModalOpened(false)
    const currentMix = mixes.find((mx) => mx.mixId === currentMixId) as IMix
    history.push(`/mixskusview/${currentMixId}/${toIsoDateStringFrom(currentMix.plan.planDate)}/false`)
  }

  hasIncompleteNonAllergenMixes = false

  return (
    <>
      { !tutorialToken ? (
        <Loading message='Tutorial Loading' />
      ) : (
        <TutorialWrapper
          state={state}
          token={tutorialToken || ''}
        >
          <div className='c-common-main-view-content'>
            <Header
              title={headerPlannerTitles.bakery}
              navItems={headerNavigationItems}
              activeMenuType={Menu.ProductionView}
            />
            <Container soft className='ln-u-push-top-sm'>

              {isLoading && <Loading />}

              {!isLoading && errorMessage && <ErrorRefresh message={errorMessage} />}

              {!isLoading && !errorMessage &&
                <>
                  <GridWrapper>
                    <GridItem size={{ xs: '1/1', sm: '1/2', md: '1/2', lg: '1/3' }}>
                      <h4>Production</h4>
                      <div className='ln-u-body-1-fixed ln-u-push-bottom-sm'>
                        {`${toNthDayOfWeekFullDatetimeString()}.`}
                      </div>
                      {getWavesForToday()}
                      {getWavesForTomorrow()}
                      {getWavesForAllergens()}
                    </GridItem>
                    <GridItem size={{ xs: '1/1', sm: '1/2', md: '1/2', lg: '1/3' }}>
                      <h4>Production Completed</h4>
                      <div className='ln-u-soft-top ln-u-push-bottom-sm' />
                      {getCompletedWavesForToday()}
                      {getCompletedWavesForTomorrow()}
                      {getCompletedWavesForAllergens()}
                    </GridItem>
                  </GridWrapper>
                </>
              }
            </Container>
          </div>

          {isCleanMixModalOpened && (
            <CleanMixModal
              isOpened={isCleanMixModalOpened}
              setIsOpened={setIsCleanMixModalOpened}
              handleAction={handleCleanStartMix}
            />
          )}

          {isAllergenMixModalOpened && (
            <AllergenMixModal
              isOpened={isAllergenMixModalOpened}
              setIsOpened={setIsAllergenMixModalOpened}
              handleAction={handleAllergenStartMix}
            />
          )}

          {isAlertMixStatusModalOpened && (
            <AlertMixStatusModal
              isOpened={isAlertMixStatusModalOpened}
              setIsOpened={setIsAlertMixStatusModalOpened}
              message={mixStatusAlertMessage}
            />
          )}

        </TutorialWrapper>
      )}
    </>
  )
}

export default ProductionView
