import { ArrowLeft } from '@jsluna/icons'
import React from 'react'
import { Link } from 'react-router-dom'

const BackButton = (linkPath: string, message: string) =>
  <Link
    to={{ pathname: linkPath }}
    className='ln-u-display-inline-flex ln-u-align-items-center ln-u-font-weight-bold'>
    <ArrowLeft className='ln-u-margin-right' /> {message}
  </Link>

export default BackButton
