import { IStackBanner } from '../../hotfood/types/IStackBanner'
import { PlannerName } from '../enums/PlannerNameEnum'
import { IUser } from '../types/IUser'
import { ActionType } from './reducer'

export interface ISetUser {
  type: ActionType.setUser
  payload: IUser
}

export interface ISetBannerMessages {
  type: ActionType.setBannerMessages
  payload: IStackBanner[]
}

export interface IAddBannerMessage {
  type: ActionType.addBannerMessage
  payload: JSX.Element
}

export interface ISetUnsavedChanges {
  type: ActionType.setUnsavedChanges
  payload: boolean
}

export interface ISetPlanner {
  type: ActionType.setPlanner
  payload: PlannerName
}

export const setBannerMessages = (value: IStackBanner[]): ISetBannerMessages => ({
  payload: value,
  type: ActionType.setBannerMessages,
})

export const addBannerMessage = (value: JSX.Element): IAddBannerMessage => ({
  payload: value,
  type: ActionType.addBannerMessage,
})

export const setUnsavedChanges = (value: boolean): ISetUnsavedChanges => ({
  payload: value,
  type: ActionType.setUnsavedChanges,
})

export const setPlanner = (value: PlannerName): ISetPlanner => ({
  payload: value,
  type: ActionType.setPlanner,
})

export const setUser = (value: IUser): ISetUser => ({
  payload: value,
  type: ActionType.setUser,
})
