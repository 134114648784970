import React from 'react'
import {
  ButtonGroupSecondary,
  ButtonGroupWrapper,
  FilledButton,
  Modal,
  ModalHeading,
  OutlinedButton,
} from '@jsluna/react'

interface IProps {
  skuName: string
  handleClose: () => void
  handleSoldOut: () => void
  isOpen: boolean
}

const getMessageContent = (skuName: string) =>
  <>
    <ModalHeading data-testid='modalTitle' element='h4'>
      Have you sold out of {skuName}?
    </ModalHeading>

    <div>Please confirm that you no longer have any {skuName} on display available for sale.</div>
  </>

const SoldOutModal = (props: IProps) =>
  <Modal
    small
    handleClose={() => props.handleClose()}
    open={props.isOpen}
    headingId='info-modal'
  >
    {
      getMessageContent(props.skuName)
    }

    <ButtonGroupWrapper>
      <ButtonGroupSecondary className='u-full-width'>
        <FilledButton
          className='ln-u-margin-right*2'
          onClick={() => props.handleSoldOut()}>
          Sold out
        </FilledButton>

        <OutlinedButton
          onClick={() => props.handleClose()}>
          Cancel
        </OutlinedButton>
      </ButtonGroupSecondary>
    </ButtonGroupWrapper>
  </Modal>

export default SoldOutModal
