import React from 'react'
import { Edit, ErrorCircle, InfoCircle, Time } from '@jsluna/icons'
import { Accordion, AccordionItem, Card, GridItem, GridWrapper, OutlinedButton } from '@jsluna/react'
import { Tag } from '@jsluna/tag'
import moment, { MomentInput } from 'moment'
import { getUserStore } from '../../../utils/localStore'
import { IBatchTemperature } from '../../types/IBatchTemperature'
import { IBatchTemperaturesResponse } from '../../types/IBatchTemperaturesResponse'
import { ICounterAvailability } from '../../types/ICounterAvailability'
import BatchItemTable from './BatchItemTable'
import TemperatureCheck from './TemperatureCheck'
import { SummaryCard } from './SummaryCard'

moment.locale('en-GB')

interface IProps {
  batchDate: MomentInput
  batchTemperatures: IBatchTemperaturesResponse
  counterAvailability: ICounterAvailability | undefined
  hasSingleCabinetType?: boolean | undefined
  isHotFoodProbeFeature: boolean
}

const TemperatureCheckView = (props: IProps) => {
  const getPrintFooter = () => {
    const store = getUserStore()
    const printDateTime = new Date()
    return `Report generated at ${printDateTime.getDate()}/${
      printDateTime.getMonth() + 1
    }/${printDateTime.getFullYear()} 
    ${printDateTime.getHours()}:${printDateTime.getMinutes()} for store ${store.storeId}-${store.storeName}.`
  }

  const renderAccordionItemCard = (batch: IBatchTemperature, hasSingleCabinetType: boolean | undefined) => (
    <Card className='c-hotfood-report-temp-check-card'>
      {batch.isActive !== false && renderTemperatureChecks(batch)}
      <BatchItemTable
        batchItems={batch.items}
        hasSingleCabinetType={hasSingleCabinetType}
        isPizza={batch.programId === null} />
    </Card>
  )

  const renderBatchTitle = (batch: IBatchTemperature, i: number) => {
    const isb = batch.isIsbProduct === true
    const pizza = batch.isPizza === true
    const batchText = `${pizza ? 'Pizza' : ''}${pizza && isb ? ' and ' : ''}${isb ? 'ISB' : ''}`

    return (
      <span data-testid='batch-title'>
        {batch.programId == null && `${batchText} - ${moment.utc(batch.productionTime).local().format('H:mm')}`}
        {batch.programId !== null &&
          `Batch ${i + 1} - ${moment.utc(batch.productionTime).local().format('H:mm')}
        ${batch.isActive !== true ? ' - user deleted batch ' : ''}`}

        {renderTags(batch)}
      </span>
    )
  }

  const renderSummary = (text: string, value: string, style: string, testId = '') =>
    <GridItem size={{ default: '1/1', md: '1/2', lg: '1/3' }} data-testid={testId}>
      <SummaryCard
        text={text}
        value={value}
        style={style}
      />
    </GridItem>

  const renderTags = (batch: IBatchTemperature) =>
    <>
      {batch.items.filter((bi) => bi.outOfCabinetOverdueTime || bi.outOfTurboServeOverdueTime).length > 0 &&
      <Tag outlined selected className='c-hotfood-report-tag ln-u-bg-color-white ln-u-margin-left'>
        <span className='ln-u-color-red'>
          <Time size='large' className='ln-u-margin-right' />Overdue
        </span>
      </Tag>}

      {props.isHotFoodProbeFeature && batch.programId !== null &&
      batch.temperatures!.filter((x) => !x.isBluetoothReading).length > 0 &&
      <Tag outlined selected className='c-hotfood-report-tag ln-u-bg-color-blue'>
        <span className='ln-u-color-white'>
          <Edit data-testid='manual-check-icon' size='large' className='ln-u-margin-right' />Manual check
        </span>
      </Tag>}

      {batch.items.filter((bi) => bi.calculatedQuantity !== 0).length > 0 &&
      batch.items.filter((bi) => bi.calculatedQuantity !== bi.quantityProduced).length > 0 &&
      <Tag outlined selected className='c-hotfood-report-tag ln-u-bg-color-yellow'>
        <span className='ln-u-color-white'>
          <Edit size='large' className='ln-u-margin-right' />Edited batch
        </span>
      </Tag>}

      {batch.temperatures!.filter((x) => parseInt(x.temperature, 10) < 75).length > 0 &&
      <Tag outlined selected className='c-hotfood-report-tag ln-u-bg-color-red'>
        <span className='ln-u-color-white'>
          <ErrorCircle size='large' className='ln-u-margin-right' />Failed check
        </span>
      </Tag>}
    </>

  const renderTemperatureChecks = (batch: IBatchTemperature) => {
    if (batch.temperaturesCount === 0) {
      return
    }

    const temperatureCount: number[] = []

    for (let count = 0; count < batch.temperaturesCount; count++) {
      temperatureCount.push(count)
    }

    return (
      <GridWrapper className='ln-u-margin-bottom'>
        <GridItem size='1/8'>&nbsp;</GridItem>
        <GridItem size='6/8' className='ln-u-border'>
          {temperatureCount.map((count) => (
            <TemperatureCheck
              key={count}
              batch={batch}
              temperatureCount={count}
              isHotFoodProbeFeature={props.isHotFoodProbeFeature}
            />
          ))}
        </GridItem>
        <GridItem size='1/8'>&nbsp;</GridItem>
      </GridWrapper>
    )
  }

  return (
    <>
      <Card className='ln-u-margin-bottom c-common-only-visible-in-print'>
        <div className='ln-u-font-weight-bold'>
          {`Temperature check report from the ${moment(props.batchDate).format('DD/MM/YYYY')}`}
        </div>
      </Card>

      {props.counterAvailability !== undefined && props.counterAvailability.updatedBy !== '' && (
        <Card className='ln-u-margin-bottom c-common-warning-banner c-common-hide-in-print'>
          <div className='ln-u-font-weight-bold'>
            <InfoCircle size='large' className='ln-u-margin-right' />
            {`Counter ${props.counterAvailability.isActive ? 're-opened' : 'closed'}
               at ${moment.utc(props.counterAvailability.updatedAt).local().format('hh.mma')}
              by ${props.counterAvailability.updatedBy!}`}
            {!props.counterAvailability.isActive && ` due to ${props.counterAvailability.reasonDescription}.`}
          </div>
        </Card>
      )}

      {props.batchTemperatures.batchTemperatures.length > 0 &&
      <GridWrapper>
        {renderSummary(
          'SKUs removed from display on time',
          `${props.batchTemperatures.skusRemovedFromDisplayOnTime}%`,
          props.batchTemperatures.skusRemovedFromDisplayOnTime > 0 ? 'ln-u-color-green' : 'ln-u-color-red'
        )}
        {renderSummary(
          'SKUs on display past removal time',
          `${props.batchTemperatures.skusOnDisplayPastRemovalTime}%`,
          props.batchTemperatures.skusOnDisplayPastRemovalTime > 0 ? 'ln-u-color-red' : 'ln-u-color-green'
        )}
        {renderSummary(
          'Failed temperature checks',
          props.batchTemperatures.failedTempChecks.toString(),
          props.batchTemperatures.failedTempChecks > 0 ? 'ln-u-color-red' : 'ln-u-color-green'
        )}
        {renderSummary('Edited batches', props.batchTemperatures.editedBatches.toString(), '')}
        {renderSummary('Created batches', props.batchTemperatures.createdBatches.toString(), '')}
        {props.isHotFoodProbeFeature &&
         renderSummary(
           'Manual temperature checks',
           props.batchTemperatures.manualTemperatureChecks.toString(),
           '',
           'manual-temperature-checks')}
      </GridWrapper>}

      {props.batchTemperatures.batchTemperatures.map((batch, i) => (
        <div className='ln-u-margin-bottom' key={i}>
          <Accordion className='ln-u-margin-bottom c-common-hide-in-print' standalone titleElement='h3'>
            <AccordionItem
              className='ln-u-flush c-common-hide-in-print'
              key={batch.batchId}
              title={renderBatchTitle(batch, i)}
            >
              {renderAccordionItemCard(batch, props.hasSingleCabinetType)}
            </AccordionItem>
          </Accordion>

          <div className='c-common-only-visible-in-print'>
            <>
              <h5 className='ln-u-margin-left*2'>{renderBatchTitle(batch, i)}</h5>
              {renderAccordionItemCard(batch, props.hasSingleCabinetType)}
            </>
          </div>
        </div>
      ))}

      <Card>
        <div className='ln-u-margin-left*2 c-common-only-visible-in-print'>{getPrintFooter()}</div>
        <OutlinedButton className='ln-u-soft-sides c-common-hide-in-print' onClick={() => window.print()}>
          Print Report
        </OutlinedButton>
      </Card>
    </>
  )
}

export default TemperatureCheckView
