import React from 'react'
import { GridItem, GridWrapper, TableBody, TableCell, TableContainer, TableHeader, TableRow } from '@jsluna/react'
import moment from 'moment'
import { IBatchItem } from '../../types/IBatchItem'

interface IProps {
  batchItems: IBatchItem[]
  hasSingleCabinetType: boolean | undefined
  isPizza: boolean
}

const BatchItemTable = (props: IProps) =>
  <>
    <TableContainer className='c-hotfood-report-batch-item-desktop'>
      <TableHeader>
        <TableRow>
          <TableCell className='c-hotfood-report-batch-item-forecasted-column'>
            {props.isPizza ? '' : 'Forecasted'}
          </TableCell>
          <TableCell className='c-hotfood-report-batch-item-cooked-column'>Cooked</TableCell>
          <TableCell>Product</TableCell>
          <TableCell className='c-hotfood-report-batch-item-out-of-cabinet-column'>Out of cabinet</TableCell>
          {!props.hasSingleCabinetType &&
            <TableCell className='c-hotfood-report-batch-item-out-of-cabinet-column'>Out of turbo-serve</TableCell>
          }
        </TableRow>
      </TableHeader>
      <TableBody>
        {props.batchItems.map((bi: IBatchItem, i: number) =>
          <TableRow key={i}>
            <TableCell>{props.isPizza ? '' : bi.calculatedQuantity}</TableCell>
            <TableCell>{bi.quantityProduced}</TableCell>
            <TableCell>{bi.skuName}</TableCell>
            <TableCell
              className={bi.outOfCabinetOverdueTime ? 'ln-u-color-red' : ''}
              data-testid='outofcabinet-overdue'>
              {bi.outOfCabinet && moment.utc(bi.outOfCabinet).local().format('HH:mm')}
              {bi.outOfCabinetOverdueTime && ` (${bi.outOfCabinetOverdueTime})`}
            </TableCell>
            {!props.hasSingleCabinetType &&
              <TableCell className={bi.outOfTurboServeOverdueTime ? 'ln-u-color-red' : ''}>
                {bi.outOfTurboServe && moment.utc(bi.outOfTurboServe).local().format('HH:mm')}
                {bi.outOfTurboServeOverdueTime && ` (${bi.outOfTurboServeOverdueTime})`}
              </TableCell>
            }
          </TableRow>
        )}
      </TableBody>
    </TableContainer>

    <div className='c-hotfood-report-batch-item-mobile'>
      {props.batchItems.map((bi: IBatchItem, i: number) =>
        <div key={i} className={`${i % 2 !== 0 ? 'c-hotfood-report-batch-item-alt-row' : ''}`}>
          <GridWrapper className='ln-u-padding-top ln-u-padding-bottom'>
            <GridItem
              className='ln-u-font-weight-bold'
              size={'1/1'}>
              {bi.skuName}
            </GridItem>
          </GridWrapper>

          <GridWrapper className='c-hotfood-report-batch-item-subsection'>
            {!props.isPizza &&
              <>
                <GridItem className='ln-u-font-weight-bold ln-u-margin-bottom' size='7/12'>Forecasted</GridItem>
                <GridItem size='5/12'>{bi.calculatedQuantity}</GridItem>
              </>
            }
            <GridItem className='ln-u-font-weight-bold ln-u-margin-bottom' size='7/12'>Cooked</GridItem>
            <GridItem size='5/12'>{bi.quantityProduced}</GridItem>
            <GridItem className='ln-u-font-weight-bold ln-u-margin-bottom' size='7/12'>Out of cabinet</GridItem>
            <GridItem className={bi.outOfCabinetOverdueTime ? 'ln-u-color-red' : ''} size='5/12'>
              {bi.outOfCabinet && moment.utc(bi.outOfCabinet).local().format('HH:mm')}
              {bi.outOfCabinetOverdueTime && ` (${bi.outOfCabinetOverdueTime})`}
            </GridItem>
            {!props.hasSingleCabinetType &&
              <>
                <GridItem className='ln-u-font-weight-bold ln-u-margin-bottom' size='7/12'>Out of turbo-serve</GridItem>
                <GridItem className={bi.outOfTurboServeOverdueTime ? 'ln-u-color-red' : ''} size='5/12'>
                  {bi.outOfTurboServe && moment.utc(bi.outOfTurboServe).local().format('HH:mm')}
                  {bi.outOfTurboServeOverdueTime && ` (${bi.outOfTurboServeOverdueTime})`}
                </GridItem>
              </>
            }
          </GridWrapper>
        </div>
      )}
    </div>
  </>

export default BatchItemTable
