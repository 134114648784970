import {
  AuthenticationResult,
  IPublicClientApplication,
} from '@azure/msal-browser'
import { PlannerName } from '../enums/PlannerNameEnum'
import { apiConfig } from '../../apiConfig'
import loggers from '../Datadog/loggers'
import parseErrorStack from '../../utils/errors'
import { isTutorialEnabled } from '../enums/TutorialVersion'

export class ApiClient {
  public cachedResponses: { [key: string]: string } = {}

  constructor(
    private instance: IPublicClientApplication,
    private apiScope: string,
  ) {
    /* */
  }

  public getBaseUrl(plannerName: PlannerName) {
    switch (plannerName) {
      case PlannerName.Bakery:
        return process.env.NODE_ENV === 'production'
          ? '/bakerybe'
          : 'http://localhost:5000'
      case PlannerName.Pizza:
        return process.env.NODE_ENV === 'production'
          ? '/pizzabe'
          : 'http://localhost:5002'
      case PlannerName.HotFood:
        return process.env.NODE_ENV === 'production'
          ? '/hotfoodbe'
          : 'http://localhost:5003'
      default:
        return process.env.NODE_ENV === 'production'
          ? '/systembe'
          : 'http://localhost:5001'
    }
  }

  public async getAccessToken(): Promise<string | null> {
    const accounts = this.instance.getAllAccounts()

    if (accounts.length === 0) {
      return null
    }

    const account = accounts[0]

    try {
      const authResult: AuthenticationResult =
        await this.instance.acquireTokenSilent({
          account,
          scopes: [this.apiScope],
        })

      return authResult.accessToken
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
      return null
    }
  }

  // on tests, mock this method to return 'fake' token
  public async getTutorialAccessToken(): Promise<string | null> {

    // have to return a non-faulthy because of avoiding 'tutorial loading' spinner
    if (!isTutorialEnabled) {
      return 'disabled'
    }

    const accounts = this.instance.getAllAccounts()

    if (accounts.length === 0) {
      loggers.error('getTutorialAccessToken - no accounts.', {})
      return null
    }

    const account = accounts[0]

    try {
      const authResult: AuthenticationResult =
        await this.instance.acquireTokenSilent({
          account,
          scopes: [apiConfig.apiTutorialClientScope as string],
        })

      return authResult.accessToken
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
      const errorStack = parseErrorStack(error as Error)
      loggers.error('getTutorialAccessToken - failed.', errorStack ? errorStack[0] as object : {})
      return null
    }
  }

  public async get<T>(
    plannerName: PlannerName,
    endpoint: string,
    text = false
  ): Promise<T> {
    const accessToken = await this.getAccessToken()

    if (!accessToken) {
      throw new Error('Access token not available')
    }

    const headers = new Headers({
      Authorization: `Bearer ${accessToken}`,
    })

    const requestOptions = {
      headers,
      method: 'GET',
    }

    if (plannerName === PlannerName.HotFood) {
      // Chrome requests timeout default is 300 seconds
      const timeout = 10000

      // there's no way to stop a fetch() request without using an abort controller
      const controller = new AbortController()
      const id = setTimeout(() => controller.abort(), timeout)

      try {
        const response = await fetch(
          `${this.getBaseUrl(plannerName) as string}/api/${endpoint}`,
          {
            ...requestOptions,
            signal: controller.signal,
          }
        )

        clearTimeout(id)

        if (text) {
          return (await response.text()) as T
        } else {
          return (await response.json()) as T
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error)

        if ((error as Error).name === 'AbortError') {
          throw Error('Request timed out')
        }
        throw Error((error as Error).message)
      }
    } else {
      // bakery and pizza remain unchanged
      try {
        const response = await fetch(
          `${this.getBaseUrl(plannerName) as string}/api/${endpoint}`,
          requestOptions
        )

        if (text) {
          return (await response.text()) as T
        } else {
          return (await response.json()) as T
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error)
        throw Error((error as Error).message)
      }
    }
  }

  public async post(plannerName: PlannerName, endpoint: string, data?: string) {
    const accessToken = await this.getAccessToken()

    if (!accessToken) {
      throw new Error('Access token not available')
    }

    const headers = new Headers({
      Authorization: `Bearer ${accessToken}`,
    })

    headers.append('Content-Type', 'application/json')

    const requestOptions = {
      body: data,
      headers,
      method: 'POST',
    }

    if (plannerName === PlannerName.HotFood) {
      const timeout = 10000

      // there's no way to stop a fetch() request without using an abort controller
      const controller = new AbortController()
      const id = setTimeout(() => controller.abort(), timeout)

      try {
        const response = await fetch(
          `${this.getBaseUrl(plannerName) as string}/api/${endpoint}`,
          {
            ...requestOptions,
            signal: controller.signal,
          }
        )

        clearTimeout(id)

        if (response.ok) {
          return response
        }
        throw Error(`Request rejected with status ${response.status}`)
      } catch (error) {
        if ((error as Error).name === 'AbortError') {
          throw Error('Request timed out')
        }
        throw Error((error as Error).message)
      }
    } else {
      // bakery and pizza remain unchanged
      try {
        const response = await fetch(
          `${this.getBaseUrl(plannerName) as string}/api/${endpoint}`,
          requestOptions
        )
        if (response.ok) {
          return response
        }
        throw Error(`Request rejected with status ${response.status}`)
      } catch (error) {
        throw Error((error as Error).message)
      }
    }
  }

  public async cachedGet<T>(
    plannerName: PlannerName,
    endPoint: string
  ): Promise<T> {
    const expiry = 60 * 60 // 1 hour default
    const cacheKey = `${plannerName}|${endPoint}}`
    const cachedItem = this.cachedResponses[cacheKey]
    const cachedTimestamp = this.cachedResponses[`${cacheKey}:ts`]
    if (cachedItem !== null && cachedTimestamp !== null) {
      const age = (Date.now() - parseInt(cachedTimestamp, 10)) / 1000
      if (age < expiry) {
        const response = new Response(new Blob([cachedItem]))
        return Promise.resolve(response as T)
      } else {
        delete this.cachedResponses[cacheKey]
        delete this.cachedResponses[`${cacheKey}:ts`]
      }
    }

    const result = await this.get(plannerName, endPoint)
    this.cachedResponses[cacheKey] = JSON.stringify(result)
    this.cachedResponses[`${cacheKey}:ts`] = Date.now().toString()

    return result as T
  }
}
