import { ApiClient } from '../../common/api/apiClient'
import { PlannerName } from '../../common/enums/PlannerNameEnum'
import { IIngredient } from '../../common/types/IIngredient'
import { ISubCategory } from '../types/ISubCategory'

export const getIngredients = async (apiClient: ApiClient, storeId: number): Promise<IIngredient[]> =>
  await apiClient.get<IIngredient[]>(PlannerName.Bakery, `ingredients/${storeId}`)

export const getIngredientSubCategories = async (apiClient: ApiClient, storeId: number): Promise<ISubCategory[]> =>
  await apiClient.get<ISubCategory[]>(PlannerName.Bakery, `ingredients/subcats/${storeId}`)
