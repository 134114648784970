import { datadogLogs } from '@datadog/browser-logs'
import getEnv from '../../envConfig'

const initDatadog = () => datadogLogs.init({
  clientToken: 'pubea5b03fd4fddb6e36b604d9609450c33',
  env: getEnv(),
  forwardErrorsToLogs: false,
  service: 'productionplanning-frontend',
})

export default initDatadog
