export enum AlertMessageKey {
  NONE = '',
  CabinetUnder75DegTemperature = 'CabinetUnder75DegTemperature',
  CabinetUnder75DegTemperatureNotFixed = 'CabinetUnder75DegTemperatureNotFixed',
  CabinetBetween63And55DegTemperature = 'CabinetBetween63And55DegTemperature',
  CabinetBelow55DegTemperature = 'CabinetBelow55DegTemperature',
  ShelfUnder75DegTemperature = 'ShelfUnder75DegTemperature',
  ShelfUnder75DegTemperatureNotFixed = 'ShelfUnder75DegTemperatureNotFixed',
  ShelfBetween63And55DegTemperature = 'ShelfBetween63And55DegTemperature',
  ShelfBelow55DegTemperature = 'ShelfBelow55DegTemperature',
  TopShelfOverMaxTemperature = 'TopShelfOverMaxTemperature',
  MiddleFirstShelfOverMaxTemperature = 'MiddleFirstShelfOverMaxTemperature',
  MiddleSecondShelfOverMaxTemperature = 'MiddleSecondShelfOverMaxTemperature',
  BottomShelfOverMaxTemperature = 'BottomShelfOverMaxTemperature',
  DeleteCounter = 'DeleteCounter',
  InValidInputData = 'InValidInputData',
  SomethingWrong = 'SomethingWrong',
  NoMessageFound = 'NoMessageFound',
}
