export enum Menu {
  ForecastView = 1,
  ProductionView = 2,
  Qpig = 3,
  Ingredient = 4,
  Display = 6,
  Report = 7,
  CounterTemperature = 8,
  GOL = 9,
}
