import React, { useState } from 'react'
import { ErrorCircle } from '@jsluna/icons'
import {
  ButtonGroupSecondary,
  ButtonGroupWrapper,
  FilledButton,
  GridItem,
  GridWrapper,
  Modal,
  ModalHeading,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableRow,
} from '@jsluna/react'
import { IBatch } from '../../types/IBatch'
import { IBatchItem } from '../../types/IBatchItem'
import { IProductPlacement } from '../../types/IProductPlacement'

interface IProductPlacementModalProps {
  batch: IBatch
  handleClose: () => void
  handleSave: (data: IProductPlacement[]) => Promise<void>
  isOpen: boolean
}

const ProductPlacementModal = (props: IProductPlacementModalProps) => {
  const [missingProductPlacements, setMissingProductPlacements] = useState<IProductPlacement[]>([])
  const [productPlacements, setProductPlacements] = useState<IProductPlacement[]>(
    props.batch.items.map((bi: IBatchItem) => (
      {
        isServeOver: false,
        isTurboServe: false,
        skuId: bi.skuId,
      })
    ))

  const handleSkuChecked = (skuId: number, isTurboServe: boolean, value: boolean) => {
    const updatedProductPlacement: IProductPlacement = productPlacements.find((p) => p.skuId === skuId)!
    if (isTurboServe) {
      updatedProductPlacement.isTurboServe = value
    } else {
      updatedProductPlacement.isServeOver = value
    }
    setProductPlacements([...productPlacements.filter((pp) => pp.skuId !== skuId), updatedProductPlacement])
  }

  const validateSkus = async () => {
    const missing: IProductPlacement[] = productPlacements.filter((pp) => !pp.isServeOver && !pp.isTurboServe)
    if (missing.length !== 0) {
      setMissingProductPlacements(missing)
    } else {
      await props.handleSave(productPlacements)
    }
  }

  return (
    <Modal
      handleClose={() => props.handleClose()}
      headingId='info-modal'
      large
      open={props.isOpen}
      restrictClose
    >
      <ModalHeading data-testid='modalTitle' element='h4'>
        Where will these product be available for sale?
      </ModalHeading>

      <div>
        <div className='ln-u-padding-bottom' data-testid='modalDescription' >
          Let us know where you'll be displaying each product so that we can adjust holding times accordingly.
        </div>

        {missingProductPlacements.length !== 0 &&
          <div className='ln-u-font-weight-bold ln-u-padding-bottom ln-u-padding-top'>
            <ErrorCircle className='c-form-alert-icon-red ln-u-margin-right' />
            Please assign all products to a display before saving selection.
          </div>}

        <TableContainer>
          <TableHeader>
            <TableRow>
              <TableCell>Product</TableCell>
              <TableCell>Counter</TableCell>
              <TableCell>Turbo-serve</TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {props.batch.items.map((bi: IBatchItem) =>
              <TableRow key={bi.skuId}>
                <TableCell>
                  {missingProductPlacements.filter((mpp: IProductPlacement) => mpp.skuId === bi.skuId).length !== 0 &&
                    <ErrorCircle className='c-form-alert-icon-red ln-u-margin-right' />}
                  {bi.skuName}
                </TableCell>
                <TableCell>
                  <div className='ln-c-form-option--checkbox'>
                    <input
                      className='ln-c-form-option__input'
                      type='checkbox'
                      id={`so-${bi.skuId.toString()}`}
                      checked={productPlacements.find((p) => p.skuId === bi.skuId)!.isServeOver === true}
                      onChange={() => handleSkuChecked(
                        bi.skuId, false, !productPlacements.find((p) => p.skuId === bi.skuId)!.isServeOver)}
                    />
                    <label
                      className='ln-c-form-option__label'
                      htmlFor={`so-${bi.skuId.toString()}`}
                      onClick={() => handleSkuChecked(
                        bi.skuId, false, productPlacements.find((p) => p.skuId === bi.skuId)!.isServeOver)}
                    ></label>
                  </div>
                </TableCell>
                <TableCell>
                  <div className='ln-c-form-option--checkbox'>
                    <input
                      className='ln-c-form-option__input'
                      type='checkbox'
                      id={`ts-${bi.skuId.toString()}`}
                      checked={productPlacements.find((p) => p.skuId === bi.skuId)!.isTurboServe === true}
                      onChange={() => handleSkuChecked(
                        bi.skuId, true, !productPlacements.find((p) => p.skuId === bi.skuId)!.isTurboServe)}
                    />
                    <label
                      className='ln-c-form-option__label'
                      htmlFor={`ts-${bi.skuId.toString()}`}
                      onClick={() => handleSkuChecked(
                        bi.skuId, true, productPlacements.find((p) => p.skuId === bi.skuId)!.isTurboServe)}
                    ></label>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </TableContainer>
      </div>

      <ButtonGroupWrapper className='ln-u-text-align-center ln-u-padding-bottom ln-u-padding-right ln-u-padding-left'>
        <ButtonGroupSecondary className='u-full-width'>
          <GridWrapper>
            <GridItem size={{ default: '1/1', xs: '1/2', sm: '1/2', md: '1/2', lg: '1/2' }}>
              <FilledButton
                className='c-form-buttom-center c-submit-modal-button u-full-width'
                onClick={() => validateSkus()}
              >
                Save
              </FilledButton>
            </GridItem>
          </GridWrapper>
        </ButtonGroupSecondary>
      </ButtonGroupWrapper>
    </Modal>
  )
}

export default ProductPlacementModal
