import { IAdditionalInformation } from '../../types/IAdditionalInformation'

const getColumnText = (description: string) =>
  description.substring(description.indexOf(':') + 1, description.length).trim()

const getRowIdentifier = (qpig: IAdditionalInformation): string =>
  qpig.description.substring(0, qpig.description.indexOf(':'))

const getValueFromField = (fieldText: string): string =>
  fieldText.substring(fieldText.indexOf(':') + 1, fieldText.length)

const hasTableFormatData = (fqpigs: IAdditionalInformation[]) => {
  const keywords = ['Oven']
  return fqpigs.some((qpig) => {
    const rowData = getColumnText(qpig.description)
    if (rowData.indexOf(':') !== -1 && rowData.indexOf(',') !== -1) {
      return keywords.some((keyword) => rowData.indexOf(keyword) !== -1)
    }
    return false
  })
}

const getRowFields = (rowData: string): string[] => {
  const reformedFields: string[] = []

  rowData.split(',').forEach((field) => {
    if (field.includes(':')) {
      reformedFields.push(field)
    } else {
      reformedFields[reformedFields.length - 1] = `${reformedFields[reformedFields.length - 1]},${field}`
    }
  })

  return reformedFields
}

export {
  getColumnText,
  getRowFields,
  getRowIdentifier,
  getValueFromField,
  hasTableFormatData,
}
