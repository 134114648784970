import React, { useContext } from 'react'
import {
  Accordion, AccordionItem, GridItem, Link,
  TableBody, TableCell, TableContainer, TableHeader, TableRow,
} from '@jsluna/react'
import { useHistory } from 'react-router'
import { setCurrentPlanner } from '../../../utils/localStore'
import {
  formatPizzaName, overallDefrostToolTip, overallToolTip, toDefrostTodayTitle, toDefrostTodayToolTip,
  toMakeTodayTitle, toMakeTodayToolTip,
  totalDefrostedTitle, totalDefrostedToolTip,
  totalMadeTitle, totalMadeToolTip,
  youDefrostedTitle, youDefrostedToolTip,
  youMadeTitle, youMadeToolTip,
} from '../../../utils/pizzaUtils'
import { toTitleCase } from '../../../utils/toTitleCase'
import { Context } from '../../../common/Context/context'
import { setProductionisFrozenOpen, setProductionisOpen } from '../../../common/Context/productionDispatch'
import { IItem } from '../../types/IItem'
import { IPlannerGroupItem } from '../../types/IPlannerGroupItem'
import Incrementor from './Incrementor'

interface IProps {
  isFrozen: boolean
}

const ProductionTable = (props: IProps) => {
  const history = useHistory()

  const openQpig = (skuId: number, e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    setCurrentPlanner('pizza')
    history.push(`/qpig/${skuId}`)
  }

  const { state, dispatch } = useContext(Context)

  const pizzaCards = (items: IItem[], plannerGroupIndex: number) => {
    let totalToProduce = 0
    let total = 0

    return (
      <>
        {items.map((pizza: IItem, planItemIndex: number) => {
          totalToProduce += pizza.produceQuantity
          total += pizza.quantityCompleted
          return (
            <div className='c-pizza-card-container c-pizza-card-row' key={planItemIndex}>
              <div className='ln-u-soft ln-u-font-weight-bold'>
                <Link
                  href=''
                  className='ln-u-text-decoration-underline c-common-hover-over-cursor'
                  onClick={(e: React.MouseEvent<HTMLElement>) => openQpig(pizza.pizzaId, e)}>
                  {formatPizzaName(toTitleCase(pizza.pizzaName))}
                </Link>
                {props.isFrozen ? overallDefrostToolTip : overallToolTip}
              </div>
              <GridItem size={'1/2'}>{props.isFrozen ? toDefrostTodayTitle : toMakeTodayTitle}</GridItem>
              <GridItem size={'1/2'} className='ln-u-text-align-center'>{pizza.produceQuantity}</GridItem>
              <GridItem size={'1/2'}>{props.isFrozen ? totalDefrostedTitle : totalMadeTitle}</GridItem>
              <GridItem size={'1/2'} className='ln-u-text-align-center'>{pizza.quantityCompleted}</GridItem>
              <p className='ln-u-soft-left'>{props.isFrozen ? youDefrostedTitle : youMadeTitle}</p>
              <div className='ln-u-soft-bottom ln-u-margin-left'>
                <Incrementor
                  isFrozen={props.isFrozen}
                  planItem={pizza}
                  planItemIndex={planItemIndex}
                  plannerGroupIndex={plannerGroupIndex}
                />
              </div>
            </div>
          )
        })}

        <div className='c-pizza-card-container c-force-white-background ln-u-border-top'
          key={`total${plannerGroupIndex}`}>
          <div className='ln-u-soft ln-u-font-weight-bold'>Total</div>
          <GridItem size={'1/2'}>{props.isFrozen ? toDefrostTodayTitle : toMakeTodayTitle}</GridItem>
          <GridItem size={'1/2'} className='ln-u-text-align-center'>{totalToProduce}</GridItem>
          <GridItem size={'1/2'}>{props.isFrozen ? totalDefrostedTitle : totalMadeTitle}</GridItem>
          <GridItem size={'1/2'} className='ln-u-text-align-center'>{total}</GridItem>
        </div>
      </>
    )
  }

  const pizzaTable = (items: IItem[], plannerGroupIndex: number) => {
    let totalToProduce = 0
    let total = 0

    return (
      <div className='c-pizza-table-container' key={plannerGroupIndex}>
        <TableContainer>
          <TableHeader>
            <TableRow>
              <TableCell>Type/SKU</TableCell>
              {props.isFrozen && <>
                <TableCell>{toDefrostTodayTitle} {toDefrostTodayToolTip}</TableCell>
                <TableCell>{totalDefrostedTitle} {totalDefrostedToolTip}</TableCell>
                <TableCell>{youDefrostedTitle} {youDefrostedToolTip}</TableCell>
              </>}
              {!props.isFrozen && <>
                <TableCell>{toMakeTodayTitle} {toMakeTodayToolTip}</TableCell>
                <TableCell>{totalMadeTitle} {totalMadeToolTip}</TableCell>
                <TableCell>{youMadeTitle} {youMadeToolTip}</TableCell>
              </>}
            </TableRow>
          </TableHeader>
          <TableBody>
            {items.map((pizza: IItem, planItemIndex: number) => {
              totalToProduce += pizza.produceQuantity
              total += pizza.quantityCompleted

              return (
                <TableRow className='ln-space-half' key={planItemIndex}>
                  <TableCell>
                    <Link
                      href=''
                      className='ln-u-text-decoration-underline c-common-hover-over-cursor'
                      onClick={(e: React.MouseEvent<HTMLElement>) => openQpig(pizza.pizzaId, e)}>
                      {formatPizzaName(toTitleCase(pizza.pizzaName))}
                    </Link>
                  </TableCell>
                  <TableCell>{pizza.produceQuantity}</TableCell>
                  <TableCell>{pizza.quantityCompleted}</TableCell>
                  <td className='c-production-incrementor'>
                    <Incrementor
                      isFrozen={props.isFrozen}
                      planItem={pizza}
                      planItemIndex={planItemIndex}
                      plannerGroupIndex={plannerGroupIndex}
                    />
                  </td>
                </TableRow>
              )
            })}
            <TableRow
              className='ln-space-half c-pizza-table-container c-force-white-background'
              key={`total${plannerGroupIndex}`}>
              <TableCell className='ln-u-font-weight-bold'>Total</TableCell>
              <TableCell className='ln-u-font-weight-bold'>{totalToProduce}</TableCell>
              <TableCell className='ln-u-font-weight-bold'>{total}</TableCell>
              <td></td>
            </TableRow>
          </TableBody>
        </TableContainer>
      </div>
    )
  }

  const plannerGroups = props.isFrozen ?
    state.production.frozenProductionPlannerGroups
    : state.production.productionPlannerGroups

  return (
    <>
      <div className='c-accordion-container ln-u-soft-bottom'>
        <Accordion multipleOpen titleElement='h3'>
          {plannerGroups.slice()
            .sort((planGroupA: IPlannerGroupItem, planGroupB: IPlannerGroupItem): number =>
              planGroupA.positionOnOutput < planGroupB.positionOnOutput ? -1 : 1)
            .map((plannerGroup: IPlannerGroupItem, plannerGroupIndex: number) => {
              const total = plannerGroup.items.map((item) => item.produceQuantity).reduce((prev, next) => prev + next)
              return (
                <AccordionItem
                  onClick={() => props.isFrozen
                    ? dispatch(setProductionisFrozenOpen(plannerGroupIndex, !plannerGroup.isFrozenOpen))
                    : dispatch(setProductionisOpen(plannerGroupIndex, !plannerGroup.isOpen))}
                  defaultOpen={props.isFrozen ? plannerGroup.isFrozenOpen : plannerGroup.isOpen}
                  className='ln-u-flush'
                  key={plannerGroupIndex}
                  title={`${plannerGroup.plannerGroupName} (${total})`}>
                  {pizzaTable(plannerGroup.items, plannerGroupIndex)}
                  {pizzaCards(plannerGroup.items, plannerGroupIndex)}
                </AccordionItem>
              )
            })}
        </Accordion>
      </div>
    </>
  )
}

export default ProductionTable
