import IScrollPosition from '../../types/IScrollPosition'

const actions = {
  clear: 'CLEAR_SCROLL_POSITION',
  set: 'SET_SCROLL_POSITION',
}

const setScrollPosition = (scrollPosition: IScrollPosition) => ({
  scrollPosition,
  type: actions.set,
})

const clearScrollPosition = (scrollPositionClassName: string) => ({
  scrollPositionClassName,
  type: actions.clear,
})

export {
  actions,
  clearScrollPosition,
  setScrollPosition,
}
