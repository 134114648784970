import { InfoCircle } from '@jsluna/icons'
import React from 'react'
import Banner from './Banner'

interface IInfoBannerProps {
  message: string
}

const InfoBanner = ({ message }: IInfoBannerProps) =>
  <Banner
    message={message}
    icon={<InfoCircle />}
    className='c-common-info-banner'
  />

export default InfoBanner
