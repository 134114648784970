import React from 'react'
import { Link } from '@jsluna/react'
import { StarFilled } from '@sainsburys-tech/icons'
import IMixSku from '../../types/IMixSku'
import { setCurrentPlanner } from '../../../utils/localStore'

interface IProps {
  openQpig: (mixSku: IMixSku) => void
  mixSku: IMixSku
}

const MixSkusRowCompleted = (props: IProps) => {
  const { mixSku, openQpig } = props
  return (
    <tr className='ln-c-table__row' key={mixSku.mixSkuId} id={`MixSkuId_${mixSku.mixSkuId}`}>
      <td className='ln-c-table__cell c-table__cell--fixed-main c-table__cell--60'>
        <Link className='ln-u-text-decoration-underline c-common-hover-over-cursor' onClick={() => {
          setCurrentPlanner('bakery')
          openQpig(mixSku)
        }
        }>
          {mixSku.skuName}
          {mixSku.isCredibleProduct === true &&
            <StarFilled height='25px' fill='black' className='ln-u-margin-left c-table__icon' />
          }
        </Link>
      </td>
      <td className='ln-c-table__cell c-table__cell--fixed c-table__cell--20 c-table__row'>
        {mixSku.productionTarget}
      </td>
      <td className='ln-c-table__cell c-table__cell--fixed c-table__cell--20'>
        {mixSku.productionActual}
      </td>
    </tr>
  )
}

export default MixSkusRowCompleted
