import React, { useEffect, useState, useContext } from 'react'
import {
  Accordion,
  AccordionItem,
  ButtonGroupWrapper,
  Card,
  FilledButton,
  OutlinedButton,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@jsluna/react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { useApiClient } from '../../../common/AppContext/appContext'
import { Context } from '../../../common/Context/context'
import { IResult } from '../../../common/types/IResult'
import { IBatch } from '../../types/IBatch'
import { IBatchItem } from '../../types/IBatchItem'
import { IProductPlacement } from '../../types/IProductPlacement'
import { ITemperatureData } from '../../types/ITemperatureData'
import { disposeBatch, createBatchTemperatures, createProductPlacements } from '../../api/productionApi'
import { addBannerMessage } from '../../../common/Context/commonDispatch'
import Loading from '../../../common/components/Loading'
import { getRequiredTemperatureCount, getRequiredTemperatureDgree, isOutOfOven } from '../../utils/hotFoodUtils'
import { BatchTray, CabinetWindow, TurboShelf } from '../../enums/TemperatureCheckPosition'
import { TempCheckFor } from '../../enums/TempCheckFor'
import ConfirmationBanner from '../../../common/components/Banners/ConfirmationBanner'
import TemperatureCheckModal from './TemperatureCheckModal'
import TemperatureBoostAlertModal from './TemperatureBoostAlertModal'
import ProductPlacementModal from './ProductPlacementModal'
import NoCounterTempsTakenModal from './NoCounterTempsTakenModal'


interface IProps {
  batch: IBatch
  batchIndex: number | undefined
  hasTurboServe: boolean
  showBatchTimes: boolean
  counterTemperaturesNotTaken: boolean
  removeBatch: (batchId: number) => void
  setErrorMessage: (message: string) => void
  updateTemperatures: (batchId: number, temperatures: ITemperatureData[]) => void
  updateTemperaturesCount: (batchId: number, temperaturesCount: number) => void
}

const BatchCard = (props: IProps) => {
  const history = useHistory()
  const { dispatch } = useContext(Context)
  const [isLoading, setIsLoading] = useState(false)
  const [isInputModalOpen, setIsInputModalOpen] = useState(false)
  const [isNoCounterTempsTakenModalOpen, setIsNoCounterTempsTakenModalOpen] = useState(false)
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false)
  const [temperaturesCount, setTemperaturesCount] = useState(0)
  const [requiredTemperatureCount, setRequiredTemperatureCount] = useState(0)
  const [outOfOven, setOutOfOven] = useState(false)
  const [isProductPlacementModalOpen, setIsProductPlacementModalOpen] = useState(false)

  const apiClient = useApiClient()

  useEffect(() => {
    setTemperaturesCount(props.batch.temperaturesCount)
    setRequiredTemperatureCount(getRequiredTemperatureCount(props.batch))
  }, [props.batch])

  useEffect(() => {
    setOutOfOven(isOutOfOven(props.batch))
  }, [props.batch.temperatures])

  const batchItems = (items: IBatchItem[]) => (
    <TableContainer>
      <TableBody>
        {items.map((item, index) => (
          <TableRow key={index}>
            <TableCell>{item.skuName}</TableCell>
            <TableCell className='c-batch-card-produce-quantity'>
              {item.quantityProduced}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </TableContainer>
  )

  const renderButtons = () =>
    <ButtonGroupWrapper className='ln-u-margin-left*2'>
      {temperaturesCount === 0 &&
        <OutlinedButton
          className='ln-u-margin-left'
          onClick={() => history.push(`/hotfoodbatch/${props.batch.batchId}`)}
        >
          Edit
        </OutlinedButton>
      }
      {outOfOven && props.hasTurboServe &&
        <FilledButton
          className='ln-u-margin-left'
          onClick={() => setIsProductPlacementModalOpen(true)}
        >
          Add display selection
        </FilledButton>
      }
      {!outOfOven &&
        <FilledButton
          className='ln-u-margin-left'
          onClick={() => props.counterTemperaturesNotTaken
            ? setIsNoCounterTempsTakenModalOpen(true)
            : setIsInputModalOpen(true)}
        >
          Add temp checks
        </FilledButton>
      }
    </ButtonGroupWrapper>

  const handleSave = async (data: ITemperatureData[], isBluetoothReading: boolean) => {
    setIsInputModalOpen(false)

    const isValid = isValidTemperatures(data)
    setTemperaturesCount(temperaturesCount + 1)

    props.updateTemperaturesCount(props.batch.batchId, temperaturesCount + 1)

    if (!isValid) {
      setIsAlertModalOpen(true)
    }

    setIsLoading(true)
    const response = await createBatchTemperatures(apiClient, {
      batchId: props.batch.batchId,
      isBluetoothReading,
      temperatures: data
        .reduce((accTs: Array<{ position: BatchTray | CabinetWindow | TurboShelf; temperature: number }>, t) => {
          if (t.temperature) {
            return [
              ...accTs,
              {
                position: t.position,
                temperature: Number(t.temperature),
              },
            ]
          }
          return accTs
        }, []),
    })

    // ensure batch.temperatures are set to latest
    if (response.isSuccess) {
      props.updateTemperatures(props.batch.batchId, data)
    }

    setIsLoading(false)

    if (response.isSuccess && isValid) {
      if (props.hasTurboServe) {
        setIsProductPlacementModalOpen(true)
      } else {
        // remove the batch from those displayed
        props.removeBatch(props.batch.batchId)
        dispatch(addBannerMessage(
          <ConfirmationBanner message={`The '${props.batch.programDescription}' batch has reached temperature.
           It has been marked as 'out of oven' at ${moment(new Date(Date.now())).format('hh.mm a')}.`}/>))
      }
    }

    props.setErrorMessage(response.isSuccess ? '' : `${response.message}`)
    return
  }

  const handleProductPlacementSave = async (data: IProductPlacement[]) => {
    setIsProductPlacementModalOpen(false)

    const batch = props.batch

    for (const d of data) {
      const batchItem = batch.items.find((bi) => bi.skuId === d.skuId)!
      batchItem.serveOver = d.isServeOver
      batchItem.turboServe = d.isTurboServe
    }

    await createProductPlacements(apiClient, batch)
      .then((res) => {
        if (res.isSuccess) {
          props.removeBatch(props.batch.batchId)

          dispatch(addBannerMessage(
            <ConfirmationBanner message={`The '${props.batch.programDescription}' batch has reached temperature.
             It has been marked as 'out of oven' at ${moment(new Date(Date.now())).format('hh.mm a')}.`}/>))
        } else {
          props.setErrorMessage('An error has occurred.')
        }
      })
      .catch(() => props.setErrorMessage('An error has occurred.'))
  }

  const isValidTemperatures = (data: ITemperatureData[]) => {
    const individualItemCount = props.batch.items.reduce((acc, curr) =>
      (curr.quantityProduced * curr.portionSize) + acc, 0)

    const validTemps = data.filter((t) => (t.temperature !== '') &&
      (Number(t.temperature) >= getRequiredTemperatureDgree(TempCheckFor.Batch)))
    return (validTemps.length === Math.min(individualItemCount, 3))
  }

  const handleCloseAlertModal = () => {
    setIsAlertModalOpen(false)

    if (temperaturesCount >= 3) {
      // remove the batch from those displayed
      props.removeBatch(props.batch.batchId)
      disposeBatch(apiClient, { batchId: props.batch.batchId, storeId: props.batch.storeId }).then((res: IResult) => {
        if (res.isSuccess) {

          dispatch(addBannerMessage(
            <ConfirmationBanner message={`The '${props.batch.programDescription}' batch has been marked as 'disposed' at
                ${moment(new Date(Date.now())).format('hh.mm a')}.`}/>))
        }
      }).catch(() => props.setErrorMessage('An error has occurred.'))
    }
  }

  const getBatchCardTitleHead = () => {
    if (props.batch.isUserCreated) {
      return 'Additional batch -'
    }

    if (!props.showBatchTimes) {
      return `${props.batchIndex || 'undefined'}.`
    }

    return `${moment.utc(props.batch.productionTime).local().format('HH:mm')}`
  }
  const renderTitle = () =>
    <div>
      <div className='c-batch-card-title-inline' data-testid='batchcard-title'>
        {`${getBatchCardTitleHead()} ${props.batch.programDescription}`}
      </div>
      {outOfOven && <div className='c-batch-card-title-lable'>Out of oven</div>}
      {
        !outOfOven && props.batch.temperaturesCount > 0 &&
        <div className='c-batch-card-title-lable'>Boosted</div>
      }
    </div>

  return (
    <>
      <Card className='ln-u-margin-bottom*2 ln-u-margin-right*2 ln-u-hard c-batch-card'>
        <div className='ln-u-padding-bottom*2 ln-u-padding-right*3 ln-u-soft-top'>
          <Accordion standalone titleElement='h3'>
            <AccordionItem
              defaultOpen={false}
              className='ln-u-flush'
              key={props.batch.batchId}
              title={renderTitle()}
            >
              {batchItems(props.batch.items)}
            </AccordionItem>
          </Accordion>

          {renderButtons()}

          {isAlertModalOpen && (
            <TemperatureBoostAlertModal
              handleClose={handleCloseAlertModal}
              alertLevel={temperaturesCount}
              isOpen={isAlertModalOpen}
              handleAction={handleCloseAlertModal}
            />
          )}

          {isNoCounterTempsTakenModalOpen && (
            <NoCounterTempsTakenModal
              handleAction={() => history.push('/hotfoodcountertemperature')}
              handleClose={() => {
                setIsNoCounterTempsTakenModalOpen(false)
                setIsInputModalOpen(true)
              }}
              isOpen={isNoCounterTempsTakenModalOpen}
            />
          )}

          {isInputModalOpen && (
            <TemperatureCheckModal
              title={`Temperature checks for ${props.batch.programDescription}`}
              description={(requiredTemperatureCount >= 3)
                ? 'Take 3 readings, ideally from pieces at the top, middle and bottom of the oven.'
                : `Take ${requiredTemperatureCount} temperature reading(s).`
              }
              handleClose={() => setIsInputModalOpen(false)}
              handleSave={handleSave}
              requiredTemperatureCount={requiredTemperatureCount}
              isOpen={isInputModalOpen}
            />
          )}

          {isProductPlacementModalOpen && (
            <ProductPlacementModal
              batch={props.batch}
              handleClose={() => setIsProductPlacementModalOpen(false)}
              handleSave={handleProductPlacementSave}
              isOpen={isProductPlacementModalOpen}
            />
          )}

        </div>
      </Card>

      {isLoading && <Loading />}
    </>
  )
}

export default BatchCard
