import { ApiClient } from '../../common/api/apiClient'
import { PlannerName } from '../../common/enums/PlannerNameEnum'
import { IResult } from '../../common/types/IResult'
import { IBatch } from '../types/IBatch'
import { IBatchTemperaturesRequest } from '../types/IBatchTemperaturesRequest'
import { ICounterAvailability } from '../types/ICounterAvailability'
import { ICreateBatchRequest } from '../types/ICreateBatchRequest'
import { IDisposeBatchRequest } from '../types/IDisposeBatchRequest'
import { IEditBatchRequest } from '../types/IEditBatchRequest'
import { ISkusPerProgram } from '../types/ISkusPerProgram'

const getBatches = async (apiClient: ApiClient, storeCode: number, batchDate: Date): Promise<IBatch[]> =>
  await apiClient.get<IBatch[]>(PlannerName.HotFood, `production/${storeCode}/${batchDate.toDateString()}`)

const getBatch = async (apiClient: ApiClient, batchId: number): Promise<IBatch> =>
  await apiClient.get<IBatch>(PlannerName.HotFood, `production/${batchId}`)

const getSkusPerProgram = async (apiClient: ApiClient, storeId: number): Promise<ISkusPerProgram> =>
  await apiClient.get<ISkusPerProgram>(PlannerName.HotFood, `sku/perProgram/${storeId}`)

const createBatch = async (apiClient: ApiClient, request: ICreateBatchRequest): Promise<IResult> => {
  const body = JSON.stringify(request)
  const result = await apiClient.post(PlannerName.HotFood, 'production/createBatch', body)
  return await result.json() as IResult
}

const editBatch = async (apiClient: ApiClient, request: IEditBatchRequest): Promise<IResult> => {
  const body = JSON.stringify(request)
  const result = await apiClient.post(PlannerName.HotFood, 'production/editBatch', body)
  return await result.json() as IResult
}

const deleteBatch = async (apiClient: ApiClient, batchId: number): Promise<IResult> => {
  const result = await apiClient.post(PlannerName.HotFood, `production/deleteBatch/${batchId}`)
  return await result.json() as IResult
}

const disposeBatch = async (apiClient: ApiClient, request: IDisposeBatchRequest): Promise<IResult> => {
  const body = JSON.stringify(request)
  const result = await apiClient.post(PlannerName.HotFood, 'production/disposeBatch', body)
  return await result.json() as IResult
}

const createBatchTemperatures = async (apiClient: ApiClient, request: IBatchTemperaturesRequest): Promise<IResult> => {
  const body = JSON.stringify(request)
  const result = await apiClient.post(PlannerName.HotFood, 'production/createTemperatures', body)
  return await result.json() as IResult
}

const createProductPlacements = async (apiClient: ApiClient, request: IBatch): Promise<IResult> => {
  const body = JSON.stringify(request)
  const result = await apiClient.post(PlannerName.HotFood, 'production/createProductPlacements', body)
  return await result.json() as IResult
}

const createCounterAvailability = async (apiClient: ApiClient, request: ICounterAvailability): Promise<IResult> => {
  const body = JSON.stringify(request)
  const result = await apiClient.post(PlannerName.HotFood, 'production/availability/create', body)
  return await result.json() as IResult
}

const getCounterAvailability = async (
  apiClient: ApiClient,
  storeCode: number,
  date: Date
): Promise<ICounterAvailability> =>
  await apiClient.get(
    PlannerName.HotFood,
    `production/availability/${storeCode}/${date.toDateString()}`)

export {
  createBatch,
  disposeBatch,
  editBatch,
  deleteBatch,
  getBatches,
  getBatch,
  getSkusPerProgram,
  createBatchTemperatures,
  createProductPlacements,
  createCounterAvailability,
  getCounterAvailability,
}
