import React, {
  useContext,
  useEffect,
  useState,
} from 'react'
import { useHistory } from 'react-router-dom'
import {
  Container,
  GridItem,
  GridWrapper,
  OutlinedButton,
} from '@jsluna/react'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import { useApiClient } from '../../../common/AppContext/appContext'
import InfoBanner from '../../../common/components/Banners/InfoBanner'
import ErrorRefresh from '../../../common/components/ErrorRefresh'
import Header from '../../../common/components/Header'
import Loading from '../../../common/components/Loading'
import SaveModal from '../../../common/components/Modals/SaveModal'
import TutorialWrapper from '../../../common/components/TutorialWrapper'
import { setPlanner } from '../../../common/Context/commonDispatch'
import { Context } from '../../../common/Context/context'
import { ErrorMessage } from '../../../common/enums/ErrorMessage'
import {
  GetHeaderMainMenus,
  headerMainMenus,
  HeaderNavItem,
  headerPlannerTitles } from '../../../common/enums/HeaderItems'
import { Menu } from '../../../common/enums/MenuEnum'
import { PlannerName } from '../../../common/enums/PlannerNameEnum'
import { getUserStore } from '../../../utils/localStore'
import { getControlGroups } from '../../api/controlGroupApi'
import { getPlan, updatePlanItems } from '../../api/planApi'
import { IControlGroup } from '../../types/IControlGroup'
import { IPlan } from '../../types/IPlan'
import { IPlanControlGroup } from '../../types/IPlanControlGroup'
import { IPlanItem } from '../../types/IPlanItem'
import { IPlanItemUpdate } from '../../types/IPlanItemUpdate'
import { IPlannerGroup } from '../../types/IPlannerGroup'
import SalesForecastTable from './SalesForecastTable'

const ForecastView = () => {
  const { dispatch, state } = useContext(Context)
  const history = useHistory()

  const [tutorialToken, setTutorialToken] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')

  const [allowScroll, setAllowScroll] = useState(false)
  const [controlGroups, setControlGroups] = useState<IControlGroup[]>([])
  const [selectedControlGroupId, setSelectedControlGroupId] = useState(-1)
  const [planDateOptions] = useState<Date[]>(Array.from(
    {length: 13},
    (_, i) => {
      const rangeDate = new Date()
      rangeDate.setDate(rangeDate.getDate() + (i - 6))
      return rangeDate
    }
  ))
  const [planDate, setPlanDate] = useState<Date>(new Date())
  const [plan, setPlan] = useState<IPlan>({
    planId: 0,
    planDate: new Date(),
    planControlGroups: [],
    eventName: '',
    isSplitSalesForecast: false,
  })

  const [editing, setEditing] = useState(false)
  const [saving, setSaving] = useState(false)
  const [reload, setReload] = useState(false)

  const [selectedPlanDateIndexN0, setSelectedPlanDateIndexN0] = useState(6)
  const [selectedPlanDateIndexN1, setSelectedPlanDateIndexN1] = useState(6)
  const [targetParamsN1, setTargetParamsN1] = useState('')
  const [targetPathnameN1, setTargetPathnameN1] = useState('')
  const [unsavedChanges, setUnsavedChanges] = useState(false)
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false)
  const [headerNavigationItems, setHeaderNavigationItems] = useState(headerMainMenus.bakeryNavs)

  let block: () => void

  const apiClient = useApiClient()
  const store = getUserStore()

  useEffect(() => {
    setIsLoading(true)

    dispatch(setPlanner(PlannerName.Bakery))

    const getHeaderMainMenusTask = GetHeaderMainMenus(apiClient, store.storeId, PlannerName.Bakery)
    const getControlGroupsTask = getControlGroups(apiClient)
    const getTutorialTokenTask = apiClient.getTutorialAccessToken()

    // Do not change the order of the promises in the Promise.all
    // Once the default tutorial not related with a feature is rendered,
    // it will not re-render again so all set states should be done before tutorial token is set
    Promise.all([
      getHeaderMainMenusTask,
      getControlGroupsTask,
      getTutorialTokenTask,
    ])
      .then((responses: [HeaderNavItem[], IControlGroup[], string|null]) => {
        setHeaderNavigationItems(responses[0])
        setControlGroups(responses[1])
        setSelectedControlGroupId(responses[1][0].controlGroupId)
        setTutorialToken(responses[2])
      })
      .catch((e) => {
        if (process.env.NODE_ENV !== 'production') {
          // eslint-disable-next-line no-console
          console.log(e)
        }
        setErrorMessage(`${ErrorMessage.ForecastView.FailedToLoad} - ${(e as Error).message}`)
      })
      .finally(() => {
        setIsLoading(false)
      })

    doBlock(false)

    return () => {
      block()
    }

    // doBlock and block should not be added to the dependency array
    // this is because the block function should only be created once
    // and the doBlock function should only be called when the component is mounted
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiClient, dispatch, store.storeId])

  useEffect(() => {
    setIsLoading(true)
    const getPlanTask = getPlan(apiClient, dayjs(planDate).format('YYYY-MM-DD'))

    Promise.all([getPlanTask])
      .then((responses: [IPlan]) => {
        setPlan(responses[0])
      })
      .catch((e) => {
        setErrorMessage(`${ErrorMessage.ForecastView.FailedToLoad} - ${(e as Error).message}`)
      })
      .finally(() => {
        setIsLoading(false)
        setReload(false)
      })

  }, [apiClient, planDate, reload])

  useEffect(() => {
    // componentDidUpdate functionality
    if (allowScroll === true) {
      const el = document.querySelector('.c-bakery-plan-item-input')
      setAllowScroll(false)
      if (el !== null) {
        el.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
      }
    }
  }, [allowScroll])

  const isPastForecast = (): boolean => {
    if (plan.planId === 0) {
      return false
    }

    return dayjs(plan.planDate).diff(dayjs(), 'day') < 0
  }

  const isEditButtonDisabled = (): boolean => {
    if (saving === true) {
      return true
    }

    if (editing === true) {
      return false
    }

    return isLoading === true || plan.planControlGroups === null || !hasEditableSkus()
  }

  const hasEditableSkus = (): boolean => (
    plan.planControlGroups!.filter((planControlGroup) => {
      if (planControlGroup.controlGroupId !== selectedControlGroupId) {
        return false
      }

      return (
        planControlGroup.plannerGroups.filter((plannerGroup) =>
          plannerGroup.planItems.filter((planItem) => planItem.isSkuEditable === true
          ).length > 0
        ).length > 0
      )
    }).length > 0
  )

  const doPlanDateChange = (dateIndex: number) => {
    setPlanDate(planDateOptions[dateIndex])
    setEditing(false)
    setSelectedPlanDateIndexN0(dateIndex)
  }

  const doBlock = (unsaved: boolean) => {
    setUnsavedChanges(unsaved)

    /*  Block leaving the component if there are unsaved changes,
        and let saving modal pop-up to ask save or not.
     */
    block = history.block((target) => {
      if (unsaved) {
        setTargetParamsN1(target.search)
        setTargetPathnameN1(target.pathname)
        setIsSaveModalOpen(unsaved)
        return false
      }
    })
  }

  const checkAndDoNavigation = (): boolean => {
    if (targetPathnameN1 !== '') {
      doBlock(false)
      history.push(`${targetPathnameN1}${targetParamsN1}`)
      return true
    }
    return false
  }

  const handlePlanDateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const dateIndex = Number(e.target.value)
    setSelectedPlanDateIndexN1(dateIndex)

    if (!unsavedChanges) {
      doPlanDateChange(dateIndex)
    }
  }

  const handleControlGroupChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setAllowScroll(true)
    setSelectedControlGroupId(Number(e.target.value))
  }

  const handleEdit = () => {
    if (editing === true) {
      setSaving(true)
      const planItemUpdateList: IPlanItemUpdate[] = []
      plan.planControlGroups!.forEach((planControlGroup) => {
        planControlGroup.plannerGroups.forEach((plannerGroup) => {
          plannerGroup.planItems
            .filter((planItem) => planItem.modified === true)
            .forEach((planItem) => {
              planItemUpdateList.push({
                planItemId: planItem.planItemId,
                produceQuantity: planItem.produceQuantity,
                skuId: planItem.skuId,
                totalSalesForecast: planItem.totalSalesForecast,
              })
            })
        })
      })

      if (planItemUpdateList.length === 0) {
        setEditing(false)
        setSaving(false)
        setSelectedPlanDateIndexN0(selectedPlanDateIndexN1)
        doBlock(false)
        return
      }

      updatePlanItems(apiClient, plan.planId, planItemUpdateList)
        .then((result) => {
          const nextPlanDateIndex = selectedPlanDateIndexN1
          setEditing(false)
          setSaving(false)
          setSelectedPlanDateIndexN0(nextPlanDateIndex)
          doBlock(false)

          if (result.isSuccess === false) {
            setErrorMessage(`${ErrorMessage.ForecastView.FailedToUpdate} - ${result.message}`)
          }
          if (checkAndDoNavigation() === false) {
            doPlanDateChange(nextPlanDateIndex)
          }

          // fetch the plan data again from the server once being updated
          // so that the updatedAt and updatedBy will be fetched from server.
          setReload(true)
        })
        .catch((e: Error) => {
          setErrorMessage(`${ErrorMessage.ForecastView.FailedToUpdate} - ${e.message}`)
          setSaving(false)
        })
    } else {
      setEditing(!editing)
      setAllowScroll(true)
    }
  }

  const handleProductionTargetChange = (planItemId: number, updatedQuantity: number) => {
    doBlock(true)

    const targetControlGroup = plan.planControlGroups?.find((pcg: IPlanControlGroup) =>
      pcg.controlGroupId === selectedControlGroupId)
    const updatedPlannerGroups = targetControlGroup?.plannerGroups.reduce((
      accPgs: IPlannerGroup[], curPg: IPlannerGroup
    ) => {
      if (curPg.planItems.find((p) => p.planItemId === planItemId)) {
        const updatedPlanItems = curPg.planItems.reduce((accPis: IPlanItem[], curPi: IPlanItem) => {
          if (curPi.planItemId === planItemId) {
            return [...accPis, {
              ...curPi,
              totalSalesForecast: updatedQuantity,
              modified: true,
            }]
          }
          return [...accPis, curPi]
        }, [])
        return [...accPgs, {...curPg, planItems: updatedPlanItems}]
      }
      return [...accPgs, curPg]
    }, [])

    const updatedControlGroups = plan.planControlGroups?.reduce((xs: IPlanControlGroup[], y: IPlanControlGroup) => {
      if (targetControlGroup !== undefined && y.controlGroupId === targetControlGroup.controlGroupId) {
        return [...xs, {...y, plannerGroups: updatedPlannerGroups as IPlannerGroup[]}]
      }
      return [...xs, y]
    }, [])

    setPlan({...plan, planControlGroups: updatedControlGroups as IPlanControlGroup[]})
  }

  const handleSaveAndContinue = () => {
    setIsSaveModalOpen(false)
    handleEdit()
  }

  const handleNoSaveAndContinue = () => {
    const nextPlanDateIndex = selectedPlanDateIndexN1
    setEditing(false)
    setIsSaveModalOpen(false)
    setSelectedPlanDateIndexN0(nextPlanDateIndex)
    doBlock(false)

    if (checkAndDoNavigation() === false) {
      doPlanDateChange(nextPlanDateIndex)
    }
  }

  const handleCancel = () => {
    setIsSaveModalOpen(false)
    setSelectedPlanDateIndexN1(selectedPlanDateIndexN0)
    setTargetParamsN1('')
    setTargetPathnameN1('')
  }

  const renderDateOptions = () => {
    dayjs.extend(advancedFormat)

    return planDateOptions.map((d: Date, i: number) => {
      let dateText = dayjs(d).format('ddd Do MMMM')

      if (i === 6) {
        dateText = dateText.concat(' (Today)')
      }

      return (
        <option key={i} value={i}>
          {dateText}
        </option>
      )
    })
  }

  const renderControlGroupOptions = () => {
    if (controlGroups.length > 0) {
      return controlGroups.map((controlGroup: IControlGroup) => (
        <option key={controlGroup.controlGroupId} value={controlGroup.controlGroupId}>
          {controlGroup.controlGroupName}
        </option>
      ))
    }
  }


  return (
    <>
      { !tutorialToken ? (
        <Loading message='Tutorial Loading' />
      ) : (
        <TutorialWrapper
          state={state}
          token={tutorialToken || ''}
        >
          <div className='c-common-main-view-content'>
            <Header
              title={headerPlannerTitles.bakery}
              navItems={headerNavigationItems}
              activeMenuType={Menu.ForecastView}
            />

            {(isLoading || saving) && <Loading />}

            {!isLoading && errorMessage && <ErrorRefresh message={errorMessage} />}

            {!isLoading && !errorMessage &&
              <>
                <div className='c-common-main-view-content'>
                  <div className='ln-c-card u-soft-sm'>
                    <div className='ln-o-container'>

                      <GridWrapper>
                        <GridItem size={{ xs: '1/3', lg: '1/4', default: '1/1' }}>
                          <select
                            id='plan-date-select'
                            className='ln-c-select'
                            onChange={handlePlanDateChange}
                            value={String(selectedPlanDateIndexN0)}
                          >
                            {renderDateOptions()}
                          </select>
                        </GridItem>
                        <GridItem size={{ xs: '1/3', lg: '1/4', default: '1/1' }}>
                          <select
                            id='control-group-select'
                            className='ln-c-select'
                            onChange={handleControlGroupChange}
                            value={selectedControlGroupId}
                          >
                            {renderControlGroupOptions()}
                          </select>
                        </GridItem>
                        <GridItem size={{ xs: '1/3', lg: '2/4', default: '1/1' }} className='ln-u-text-align-right'>
                          {!isPastForecast() && (
                            <OutlinedButton
                              disabled={isEditButtonDisabled()}
                              onClick={() => handleEdit()}
                            >
                              {(editing === true ? 'Save Forecast' : 'Edit Forecast')}
                            </OutlinedButton>
                          )}
                        </GridItem>
                      </GridWrapper>
                    </div>
                  </div>

                  <Container soft className='ln-u-push-top o-common-loading-overlay'>
                    {isPastForecast() && <InfoBanner message='This forecast is in the past' />}
                    <SalesForecastTable
                      plan={plan}
                      controlGroupId={selectedControlGroupId}
                      editing={editing}
                      onProductionTargetChange={handleProductionTargetChange}
                    />
                  </Container>

                </div>
                {isSaveModalOpen && (
                  <SaveModal
                    isOpen={isSaveModalOpen}
                    modalBody={"You've made changes to the production plan. Would you like to save these changes?"}
                    modalTitle={'Save changes?'}
                    onClose={() => handleCancel()}
                    onNo={handleNoSaveAndContinue}
                    onYes={handleSaveAndContinue}
                  />
                )}
              </>
            }
          </div>
        </TutorialWrapper>
      )}
    </>
  )
}

export default ForecastView
