import React from 'react'
import { GridItem, GridWrapper, TableBody, TableCell, TableContainer, TableRow } from '@jsluna/react'
import { ITemperatureData } from '../../types/ITemperatureData'

interface IProps {
  temperatures: ITemperatureData[]
}

const TemperatureCheckRow = (props: IProps) =>
  <GridWrapper>
    {props.temperatures.map((temperature, i) =>
      <GridItem key={i} size={{ ss: `1/${props.temperatures.length}`, xs: '1/1' }}>
        <TableContainer>
          <TableBody>
            <TableRow>
              <TableCell className='c-hotfood-report-temp-check-col-index ln-u-font-weight-bold'>
                {i === 0 ? '1st' : i === 1 ? '2nd' : '3rd'}
              </TableCell>
              <TableCell
                className={`c-hotfood-report-temp-check-col-value
                  ${parseFloat(temperature.temperature) < 75.0 ? 'ln-u-color-red' : ''}`}>
                {temperature.temperature}℃
              </TableCell>
            </TableRow>
          </TableBody>
        </TableContainer>
      </GridItem>
    )}
  </GridWrapper>

export default TemperatureCheckRow
