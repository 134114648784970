import { connectRouter } from 'connected-react-router'
import { History } from 'history'
import { combineReducers } from 'redux'
import scrollPositionsReducer from '../../common/store/scrollPositions/reducer'
import userReducer from './user/reducer'

const rootReducer = (history: History) => combineReducers({
  router: connectRouter(history),
  scrollPositionsReducer,
  userReducer,
})

export default rootReducer
