import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ErrorCircle } from '@jsluna/icons'
import { Container, GridItem, GridWrapper, OutlinedButton } from '@jsluna/react'
import { isFeatureEnabled } from '../../../common/api/featureApi'
import { getStoreCounters } from '../../../common/api/storeApi'
import { ErrorMessage } from '../../../common/enums/ErrorMessage'
import { useApiClient } from '../../../common/AppContext/appContext'
import ErrorRefresh from '../../../common/components/ErrorRefresh'
import Header from '../../../common/components/Header'
import { GetHeaderMainMenus, headerMainMenus, headerPlannerTitles } from '../../../common/enums/HeaderItems'
import Loading from '../../../common/components/Loading'
import { Context } from '../../../common/Context/context'
import { Feature } from '../../../common/enums/FeatureEnum'
import { Menu } from '../../../common/enums/MenuEnum'
import { IStorePlanners } from '../../../common/types/IStorePanners'
import { getUserStore } from '../../../utils/localStore'
import { getBatchItems, getPizzaAndIsbProducts } from '../../api/displayApi'
import { getStore } from '../../api/storeApi'
import { IBatchItemDisplay } from '../../types/IBatchItemDisplay'
import { ISkuHeader } from '../../types/ISkuHeader'
import { IStore } from '../../types/IStore'
import TutorialWrapper from '../../../common/components/TutorialWrapper'
import { setBannerMessages, setPlanner } from '../../../common/Context/commonDispatch'
import { IStackBanner } from '../../types/IStackBanner'
import BannerHolder from '../../../common/components/Banners/BannerHolder'
import { PlannerName } from '../../../common/enums/PlannerNameEnum'
import BatchItemCard from './BatchItemCard'

const DisplayView = () => {
  const apiClient = useApiClient()
  const history = useHistory()
  const { state, dispatch } = useContext(Context)
  const [tutorialToken, setTutorialToken] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const [batchItems, setBatchItems] = useState<IBatchItemDisplay[]>([])
  const [isForecastingEnabled, setIsForecastingEnabled] = useState<boolean | null>(null)
  const [isPizzaAndIsbProductsEnabled, setIsPizzaAndIsbProductsEnabled] = useState(false)
  const [headerNavigationItems, setHeaderNavigationItems] = useState(headerMainMenus.hotfoodcounterNavs)

  useEffect(() => {
    GetHeaderMainMenus(apiClient, getUserStore().storeId, PlannerName.HotFood).then((res) => setHeaderNavigationItems(res))
  }, [])

  useEffect(() => {
    dispatch(setPlanner(PlannerName.HotFood))
    const storeId = getUserStore().storeId

    const getBatchItemsTask = getBatchItems(apiClient, storeId, new Date())
    const getPizzaAndIsbProductsTask = getPizzaAndIsbProducts(apiClient, storeId)
    const getStoreCountersTask = getStoreCounters(apiClient, storeId)
    const getStoreTask = getStore(apiClient, storeId)
    const isForecastingEnabledTask = isFeatureEnabled(apiClient, Feature.HotFoodBatches, storeId)

    apiClient.getTutorialAccessToken()
      .then((response: string | null) => setTutorialToken(response))
      .catch((e) =>
      {
        setErrorMessage(`${ErrorMessage.BatchDisplayView.FailedToLoad} - ${(e as Error).message}`)
        setTutorialToken('')
        setIsLoading(false)
      })

    Promise.all([getBatchItemsTask,
      getPizzaAndIsbProductsTask,
      getStoreCountersTask,
      getStoreTask,
      isForecastingEnabledTask])
      .then((response: [IBatchItemDisplay[], ISkuHeader[], IStorePlanners, IStore, boolean]) => {
        setBatchItems(response[0])
        setIsForecastingEnabled(response[4])

        const isb = response[3].turboServe && response[1].filter((p) => p.isIsbProduct).length > 0
        const pizza = response[2].hasPizza &&
        (
          (response[1].filter((p) => p.isPizzaSlice).length > 0 && response[3].turboServe)
          ||
          (response[1].filter((p) => !p.isIsbProduct && !p.isPizzaSlice).length > 0)
        )
        setIsPizzaAndIsbProductsEnabled(isb || pizza)

      })
      .catch((e) => setErrorMessage(`${ErrorMessage.BatchDisplayView.FailedToLoad} - ${(e as Error).message}`))
      .finally(() => setIsLoading(false))
  }, [apiClient, dispatch])

  const removeBatchItem = (batchItemId: number, turboServe: boolean) => {
    setBatchItems(batchItems.filter((x) => x.batchItemId !== batchItemId || x.turboServe !== turboServe))
  }

  const refreshBatchItems = () => {
    getBatchItems(apiClient, getUserStore().storeId, new Date())
      .then((response: IBatchItemDisplay[]) =>{
        setBatchItems(response)
      })
      .catch((e) => setErrorMessage(`${ErrorMessage.BatchDisplayView.FailedToLoad} - ${(e as Error).message}`))
  }

  const saveBanners = (banners: IStackBanner[]) => {
    dispatch(setBannerMessages(banners))
  }

  return (
    <>
      {!tutorialToken ? (
        <Loading message='Tutorial Loading' />
      ) : (
        <TutorialWrapper
          state={state}
          token={tutorialToken || ''}
          enabledFeatures={{forecasting: isForecastingEnabled || false}}
        >
          <div className='c-common-main-view-content'>
            <Header
              title={headerPlannerTitles.hotfood}
              navItems={headerNavigationItems}
              activeMenuType={Menu.Display}
            />
            <Container soft className='ln-u-push-top-sm'>

              <BannerHolder banners={state.bannerMessages} setBanners={saveBanners} />
              {isLoading && <Loading />}
              {errorMessage && <ErrorRefresh message={errorMessage} />}

              {!isLoading && !errorMessage &&
                <>
                  <div className='ln-u-margin-bottom*2'></div>
                  <GridWrapper>
                    <GridItem size='1/2'>
                      <h4>On display</h4>
                      <div className='ln-u-body-1-fixed ln-u-push-bottom-sm'>
                        Keep track of products currently on display.
                      </div>
                    </GridItem>
                    {isPizzaAndIsbProductsEnabled &&
                      <GridItem size='1/2'>
                        <OutlinedButton className='c-display-add-pizza-button ln-u-margin-bottom*2'
                          onClick={() => history.push('/hotfoodpizza')}
                        >
                          Add pizza and ISB products
                        </OutlinedButton>
                      </GridItem>
                    }
                  </GridWrapper>

                  {batchItems.length === 0 &&
                    <div className='c-display-no-batch'>
                      <ErrorCircle className='c-display-no-batch-icon' />
                      <span className='c-display-no-batch-header-text'>We have no products to display</span>
                      <div className='c-display-no-batch-text'>
                        You don't currently have any products on display. Products will be shown here once
                        temperature checks (and display selections if applicable) have been completed.
                      </div>
                    </div>
                  }

                  {batchItems.sort((a: IBatchItemDisplay, b: IBatchItemDisplay) =>
                    new Date(a.toBeDisposedAt).getTime() - new Date(b.toBeDisposedAt).getTime()
                    || a.skuName.localeCompare(b.skuName)
                  ).map((item, i) =>
                    <GridItem
                      key={i}
                      size={{ default: '1/1', md: '1/2', lg: '1/3' }}
                      className='ln-u-hard'>
                      <BatchItemCard
                        batchItem={item}
                        removeBatchItem={() => removeBatchItem(item.batchItemId, item.turboServe)}
                        refreshBatchItems={() => refreshBatchItems()}
                      />
                    </GridItem>
                  )}
                </>
              }
            </Container>
          </div>
        </TutorialWrapper>
      )}
    </>
  )
}

export default DisplayView
