import React, { useState } from 'react'
import { Tooltip, TooltipIcon, TooltipMessage } from '@jsluna/labs'
import { onKeyPress } from '@jsluna/utils'

interface IAppTooltipProps {
  message?: string
  value?: any
}

const AppTooltip = ({ message, value }: IAppTooltipProps) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Tooltip className='c-common-hide-in-print' open={isOpen} tooltipId='tooltip' position='top'>
        <TooltipIcon
          data-testid='tooltip-button'
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            setIsOpen(!isOpen)
            e.preventDefault()
          }}
          className='c-common-tooltip-blue'
          onBlur={() => {
            setIsOpen(false)
          }}
          onKeyDown={onKeyPress([{ name: 'Escape', code: 27 }], () =>
            setIsOpen(false),
          )}
        />
        <TooltipMessage>
          {message}{value}
        </TooltipMessage>
      </Tooltip>
    </>
  )
}

export default AppTooltip
