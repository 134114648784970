import { Minus, Plus } from '@jsluna/icons'
import { IconButton, TextInputField } from '@jsluna/react'
import React from 'react'
import { selectCurrentTarget } from '../../../utils/selectCurrentTarget'

interface IProps {
  id: number
  step: number
  value: number
  setValue: (skuId: number, value: number, turboServe?: boolean) => void
  turboServe?: boolean
}

const Incrementor = (props: IProps) => {

  const isMinusDisabled = () => props.value === 0
  const isPlusDisabled = () => props.value >= 999

  const withinValue = (event: React.FormEvent<HTMLInputElement>) => {
    const regexZeroToThreeDigitsOnly = /^\d{0,3}$/
    if (regexZeroToThreeDigitsOnly.test(event.currentTarget.value)) {
      props.setValue(props.id, +event.currentTarget.value, props.turboServe)
    }
  }

  return (
    <div className='c-common-incrementor'>
      <div className='c-common-incrementor__button c-batch-card-no-left-padding c-batch-table-incrementor-button'>
        <IconButton variant='outlined' label='Decrement' hideLabel
          disabled={isMinusDisabled()}
          onClick={() => props.setValue(props.id, props.value - props.step, props.turboServe)}
        >
          <Minus />
        </IconButton>
      </div>
      <div className='c-common-incrementor__input-text-box'>
        <TextInputField
          name='actualProductionInput'
          autoComplete='off'
          onClick={selectCurrentTarget}
          type='number'
          onChange={(e: React.FormEvent<HTMLInputElement>) => withinValue(e)}
          value={props.value.toString()}
          className='ln-u-flush-bottom'
        />
      </div>
      <div className='c-common-incrementor__button c-batch-table-incrementor-button'>
        <IconButton variant='outlined' label='Increment' hideLabel
          disabled={isPlusDisabled()}
          onClick={() => props.setValue(props.id, props.value + props.step, props.turboServe)}
        >
          <Plus fixed />
        </IconButton>
      </div>
    </div>
  )
}

export default Incrementor
