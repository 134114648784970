import { Card } from '@jsluna/react'

interface IProps {
  text: string
  value: string
  style: string
}

export const SummaryCard = (props: IProps) =>
  <Card className='c-hotfood-report-summary'>
    <div className='ln-c-label c-hotfood-report-summary-title'>{props.text}</div>
    <div className={`ln-c-label c-hotfood-report-summary-value ${props.style}`}>{props.value}</div>
  </Card>
