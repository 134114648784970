import dayjs from 'dayjs'
import 'dayjs/locale/en-gb'
import advancedFormat from 'dayjs/plugin/advancedFormat'

const toIsoDateStringFrom = (date: Date): string => dayjs(date).format('YYYY-MM-DD')

const toUkTimeDateStringFrom = (date?: Date): string => {
  if (date === undefined) {
    return ''
  }

  dayjs.locale('en-gb')
  return dayjs(date).format('HH:mm, DD/MM/YYYY')
}

const toNthDayOfWeekFullDatetimeString = (): string => {
  dayjs.extend(advancedFormat)

  return dayjs().format('dddd Do MMMM YYYY')
}

export {
  toIsoDateStringFrom,
  toUkTimeDateStringFrom,
  toNthDayOfWeekFullDatetimeString,
}
