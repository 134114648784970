import { AlertLevelEnum } from '../enums/AlertLevelEnum'
import { AlertMessageKey } from '../enums/AlertMessageKey'

export interface ICounterTemperatureAlertMessage {
  actionButtonText?: string
  cancelButtonText?: string
  instruction: string
  title: string
}

export const TemperatureAlertMessages = [
  {
    alertLevel: AlertLevelEnum.Advisory,
    instruction: `Please return the entire batch back in the oven for a boost.
      You'll need to complete another temperature check once that's done.`,
    title: 'This batch needs a boost, it is not safe for sale',
  },
  {
    alertLevel: AlertLevelEnum.Watch,
    instruction: `Please return the entire batch back in the oven for another boost.
      You'll need to complete another temperature check once that's done.`,
    title: 'This batch needs another boost, it is still not safe for sale',
  },
  {
    alertLevel: AlertLevelEnum.Warning,
    instruction: `This batch is under temperature.
      You need to dispose of it and report the faulty oven over on FM Assist.`,
    title: 'This entire batch needs to be disposed of',
  },
]

export const CounterTemperatureAlertMessages = new Map<string, ICounterTemperatureAlertMessage>()
  .set(AlertMessageKey.CabinetUnder75DegTemperatureNotFixed,
    {
      actionButtonText: 'I’ll re-log it as faulty',
      instruction: `The cabinet has not reached 75°C and can't be marked as fixed.
        Please re-log it as faulty. 
        If no other cabinet is available, 
        please dispose of all prepped and cooked items and close the counter.`,
      title: 'Cabinet under temperature,\nDO NOT USE',
    })
  .set(AlertMessageKey.CabinetUnder75DegTemperature,
    {
      actionButtonText: 'I’ll log it as faulty',
      instruction: `The cabinet has not reached 75°C and must not be used. 
        Please log it as faulty. 
        If no other cabinet is available, 
        please dispose of all prepped and cooked items and close the counter.`,
      title: 'Cabinet under temperature,\nDO NOT USE',
    })
  .set(AlertMessageKey.CabinetBetween63And55DegTemperature,
    {
      actionButtonText: 'I’ll log it as faulty',
      instruction: `The cabinet is not at the required temperature. 
        All products can be kept in the counter for a maximum of two hours (including whole chickens). 
        Please log it as faulty.`,
      title: 'Cabinet under temperature,\nUSE FOR 2 HOURS MAX',
    })
  .set(AlertMessageKey.CabinetBelow55DegTemperature,
    {
      actionButtonText: 'I’ll log it as faulty',
      instruction: `The cabinet is not at the minimum temperature of 55°C and must not be used. 
      Please log it as faulty and dispose of any items on display. 
      Close counter if no other cabinets are available.`,
      title: 'Cabinet under temperature,\nDO NOT USE',
    })
  .set(AlertMessageKey.ShelfUnder75DegTemperatureNotFixed,
    {
      actionButtonText: 'I’ll re-log it as faulty',
      instruction: `The turbo-serve is not at the required temperature and can't be marked as fixed.
        Please re-log it as faulty. 
        If no other cabinet is available, 
        please dispose of all prepped and cooked items and close the counter.`,
      title: 'Turbo-serve under temperature,\nDO NOT USE',
    })
  .set(AlertMessageKey.ShelfUnder75DegTemperature,
    {
      actionButtonText: 'I’ll log it as faulty',
      instruction: `The turbo-serve is not at the required temperature so it must not be used.
        Please log it as faulty. If no other cabinet is available, please dispose of all prepped and
        cooked items and close the counter.`,
      title: 'Turbo-serve under temperature,\nDO NOT USE',
    })
  .set(AlertMessageKey.ShelfBetween63And55DegTemperature,
    {
      actionButtonText: 'I’ll log it as faulty',
      instruction: `The turbo-serve is not at the required temperature. 
        All products can be kept in the counter for a maximum of two hours (including whole chickens). 
        Please log it as faulty.`,
      title: 'Turbo-serve under temperature,\nUSE FOR 2 HOURS MAX',
    })
  .set(AlertMessageKey.ShelfBelow55DegTemperature,
    {
      actionButtonText: 'I’ll log it as faulty',
      instruction: `The turbo-serve is not at the minimum temperature of 55°C and must not be used. 
        Please log it as faulty and dispose of any items on display. 
        Close counter if no other cabinets are available.`,
      title: 'Turbo-serve under temperature,\nDO NOT USE',
    })
  .set(AlertMessageKey.TopShelfOverMaxTemperature,
    {
      actionButtonText: 'I’ll double check',
      instruction: `You can continue to use the turbo-serve but please double check that the
        top shelf is set to 65°C.`,
      title: 'The top shelf is warmer than expected',
    })
  .set(AlertMessageKey.MiddleFirstShelfOverMaxTemperature,
    {
      actionButtonText: 'I’ll double check',
      instruction: `You can continue to use the turbo-serve but please double check that the
        middle shelf is set to 65°C.`,
      title: 'The middle shelf is warmer than expected',
    })
  .set(AlertMessageKey.MiddleSecondShelfOverMaxTemperature,
    {
      actionButtonText: 'I’ll double check',
      instruction: `You can continue to use the turbo-serve but please double check that the
        second middle shelf is set to 65°C.`,
      title: 'The second middle shelf is warmer than expected',
    })
  .set(AlertMessageKey.BottomShelfOverMaxTemperature,
    {
      actionButtonText: 'I’ll double check',
      instruction: `You can continue to use the turbo-serve but please double check that the
        bottom shelf is set to 65°C.`,
      title: 'The bottom shelf is warmer than expected',
    })
  .set(AlertMessageKey.InValidInputData,
    {
      instruction: 'Input data is not valid.',
      title: 'Counter invalid temperature',
    })
  .set(AlertMessageKey.SomethingWrong,
    {
      instruction: 'Please try again.',
      title: 'Something wrong',
    })
  .set(AlertMessageKey.NoMessageFound,
    {
      instruction: 'No message found.',
      title: 'Something wrong',
    })
