import { actions } from './actions'

const intialState = {
  username: '',
}

interface IUserReducer {
  type: string
  username: string
}

const userReducer = (state = intialState, action: IUserReducer) => {
  switch  (action.type) {
    case actions.setUsername:
      return { ...state, username: action.username }
    default:
      return state
  }
}

export default userReducer
