import React from 'react'
import { IOvenSettingDetail } from '../../types/IOvenSettingDetail'
import OvenTypeDescription from './OvenTypeDescription'

interface IMixProps {
  plannerGroupName: string
  ovenSettingDetail?: IOvenSettingDetail
}

const WaveMixCardTitle = ({ plannerGroupName, ovenSettingDetail }: IMixProps) => {
  return (
    <>
      <h5>
        {(!ovenSettingDetail) ? plannerGroupName : ovenSettingDetail?.ovenSettingDescription}
      </h5>

      <OvenTypeDescription ovenSettingDetail={ovenSettingDetail} />
    </>
  )
}

export default WaveMixCardTitle
