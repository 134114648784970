import { Minus, Plus } from '@jsluna/icons'
import { IconButton, TextInputField } from '@jsluna/react'
import React, { useContext } from 'react'
import { selectCurrentTarget } from '../../../utils/selectCurrentTarget'
import { Context } from '../../../common/Context/context'
import { setSalesForecastValue } from '../../../common/Context/forecastDispatch'
import { IItem } from '../../types/IItem'

const Incrementor = (planItem: IItem, plannerGroupIndex: number, planItemIndex: number) => {
  const { dispatch } = useContext(Context)

  const isMinusDisabled = (toMake: number) => toMake === 0
  const isPlusDisabled = (toMake: number) => toMake >= 50
  const changeValue = (value: number) =>
    dispatch(setSalesForecastValue(plannerGroupIndex, planItemIndex, value))

  const withinValue = (event: React.FormEvent<HTMLInputElement>) => {
    const regexZeroToTwoDigitsOnly = /^\d{0,2}$/
    if (regexZeroToTwoDigitsOnly.test(event.currentTarget.value) === true
      && (+event.currentTarget.value <= 50)) {
      changeValue(+event.currentTarget.value)
    }
  }

  return (
    <>
      <div className='c-common-incrementor__button'>
        <IconButton variant='outlined' label='decrement' hideLabel
          disabled={isMinusDisabled(planItem.totalSalesForecastN1)}
          onClick={() => changeValue(planItem.totalSalesForecastN1 - 1)}>
          <Minus />
        </IconButton>
      </div>
      <div className='c-common-incrementor__input-text-box'>
        <TextInputField
          name={`productionMade-${planItem.planItemId}`}
          className='ln-u-flush-bottom ln-u-margin-top'
          style={{ textAlign: 'center' }}
          autoComplete='off'
          onClick={selectCurrentTarget}
          type='number'
          onChange={(e: React.FormEvent<HTMLInputElement>) => withinValue(e)}
          value={planItem.totalSalesForecastN1.toString()}
        />
      </div>
      <div className='c-common-incrementor__button'>
        <IconButton variant='outlined' label='increment' hideLabel
          disabled={isPlusDisabled(planItem.totalSalesForecastN1)}
          onClick={() => changeValue(planItem.totalSalesForecastN1 + 1)}>
          <Plus fixed />
        </IconButton>
      </div>
    </>
  )
}

export default Incrementor
