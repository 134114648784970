import { FlagBody, FlagComponent, FlagWrapper } from '@jsluna/react'
import moment from 'moment'
import React from 'react'
import { getColleagueName } from '../../../utils/colleagueDetails'
import AppTooltip from '../Tooltip/AppTooltip'

const editedForecastMessage = (colleageName: string, date: string, message: string) =>
  <>
    Last edited by:<br/>
    {colleageName} {date}.<br/><br />
    {message}
  </>

export const LastEditedTooltip = (username: string, date: Date, baseForecast: number, message: string) =>
  <div className='c-pizza-border c-common-inline'>
    <FlagWrapper>
      <FlagComponent>
        <AppTooltip value= {editedForecastMessage(getColleagueName(username).fullName,
          moment(date).format('[at] HH:mm [on] DD/MM/YYYY'),
          message)}/>
      </FlagComponent>
      <FlagBody className='ln-u-soft-right c-common-inline c-lastedited-tooltip-initials'>
        {getColleagueName(username).initials} ({baseForecast})
      </FlagBody>
    </FlagWrapper>
  </div>
