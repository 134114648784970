import React from 'react'
import { IOvenSettingDetail } from '../../types/IOvenSettingDetail'
import OvenTypeDescription from './OvenTypeDescription'

interface IMixSkuCardTitleProps {
  plannerGroupName: string
  ovenSettingDetail?: IOvenSettingDetail
}

const MixSkusTitle = ({ plannerGroupName, ovenSettingDetail }: IMixSkuCardTitleProps) => {
  return (
    <>
      <span className='ln-u-display-4-fixed'>
        {(!ovenSettingDetail) ? plannerGroupName : ovenSettingDetail.ovenSettingDescription}
      </span>

      <OvenTypeDescription ovenSettingDetail={ovenSettingDetail} />
    </>
  )
}

export default MixSkusTitle
