import { jwtDecode } from 'jwt-decode'
import { ApiClient } from '../common/api/apiClient'

interface IToken {
  name: string
}

export const getColleagueName = (email: string) => {
  const  name = email.split('@')[0]
  const firstname = name.split('.')[0]
  const surname = name.split('.')[1]
  if (surname === undefined) {
    return {fullName: `${firstname}`, initials: `${firstname.charAt(0).toUpperCase()}`}
  } else {
    return {fullName: `${firstname} ${surname}`,
      initials: `${firstname.charAt(0).toUpperCase()}${surname.charAt(0).toUpperCase()}`}
  }
}

export const getUsername = async (apiClient: ApiClient) => {
  const tokenStr = await apiClient.getAccessToken() || ''
  const tokenJwt = jwtDecode(tokenStr)
  return (tokenJwt as IToken).name
}

export const getFirstName = (name: string) => {
  if (!name) {
    return ''
  }

  const adhocName = name.split(' ')[0]
  if (adhocName.indexOf('.') === -1) {
    return adhocName
  }

  const firstname = adhocName.split('.')[0]

  return firstname.charAt(0).toUpperCase() + firstname.slice(1)
}
