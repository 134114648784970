import { Moment } from 'moment'
import { IContext } from '../types/IContext'
import { ISetUser, ISetUnsavedChanges, ISetPlanner, ISetBannerMessages, IAddBannerMessage } from './commonDispatch'
import {
  ISetForecastEdit,
  ISetForecastisOpen,
  ISetForecastPlanOnSave,
  ISetForecastSaveModal,
  ISetIsFuture,
  ISetPizzaPlan,
  ISetPlanDates,
  ISetSalesForecastValue,
  ISetSelectedPlanDateIndex,
} from './forecastDispatch'
import { ISetIngredients } from './ingredientDispatch'
import {
  ISetAddToTotal,
  ISetProductionisFrozenOpen,
  ISetProductionisOpen,
  ISetProductionPlan,
  ISetProductionPlanOnSave,
  ISetYouDefrostedValue,
  ISetYouMadeValue,
} from './productionDispatch'

export enum ActionType {
  // Common
  setUnsavedChanges,
  setBannerMessages,
  addBannerMessage,
  setPlanner,
  setUser,
  // Production
  production_setProductionPlan,
  production_setYouDefrostedValue,
  production_setYouMadeValue,
  production_setAddToTotalStatus,
  production_setProductionPlanOnSave,
  production_setIsFrozenOpen,
  production_setIsOpen,
  // Forecast
  forecast_setPlanDates,
  forecast_setForecastPlan,
  forecast_setSelectedPlanDateIndex,
  forecast_setEditing,
  forecast_setForecastSaveModal,
  forecast_setIsFuture,
  forecast_setSalesForecastValue,
  forecast_setForecastPlanOnSave,
  forecast_setIsOpen,
  // Ingredient
  ingredient_setIngredients,
}

export type Actions =
  // Common
  | ISetUnsavedChanges
  | ISetBannerMessages
  | IAddBannerMessage
  | ISetPlanner
  | ISetUser
  // Production
  | ISetProductionPlan
  | ISetYouDefrostedValue
  | ISetYouMadeValue
  | ISetAddToTotal
  | ISetProductionPlanOnSave
  | ISetProductionisFrozenOpen
  | ISetProductionisOpen
  // Forecast
  | ISetPlanDates
  | ISetPizzaPlan
  | ISetSelectedPlanDateIndex
  | ISetForecastEdit
  | ISetForecastSaveModal
  | ISetSalesForecastValue
  | ISetIsFuture
  | ISetForecastisOpen
  | ISetForecastPlanOnSave
  // Ingredient
  | ISetIngredients

export const Reducer = (
  state: IContext,
  action: Actions,
): IContext => {
  switch (action.type) {
    // Common
    case ActionType.setUnsavedChanges:
      state.unsavedChanges = action.payload
      return state
    case ActionType.setBannerMessages:
      state.bannerMessages = action.payload
      return state
    case ActionType.addBannerMessage:
      state.bannerMessages.push({banner:action.payload, time: new Date()})
      return state
    case ActionType.setPlanner:
      state.planner = action.payload
      return state
    case ActionType.setUser:
      state.user = action.payload
      return state
    // Production
    case ActionType.production_setProductionPlan:
      state.production = action.payload
      return state
    case ActionType.production_setYouDefrostedValue:
      state.production.frozenProductionPlannerGroups[action.payload.plannerGroupIndex]
        .items[action.payload.planItemIndex].youMade = action.payload.value
      return state
    case ActionType.production_setYouMadeValue:
      state.production.productionPlannerGroups[action.payload.plannerGroupIndex]
        .items[action.payload.planItemIndex].youMade = action.payload.value
      return state
    case ActionType.production_setAddToTotalStatus:
      state.production.addToTotal = action.payload
      return state
    case ActionType.production_setProductionPlanOnSave:
      state.production = action.payload.productionPlan
      return state
    case ActionType.production_setIsFrozenOpen:
      state.production.frozenProductionPlannerGroups[action.payload.plannerGroupIndex]
        .isFrozenOpen = action.payload.value
      return state
    case ActionType.production_setIsOpen:
      state.production.productionPlannerGroups[action.payload.plannerGroupIndex]
        .isOpen = action.payload.value
      return state
      // Forecast
    case ActionType.forecast_setPlanDates:
      state.forecast.planDates = action.payload as Moment[]
      return state
    case ActionType.forecast_setForecastPlan:
      state.forecast.forecastPlan = action.payload
      return state
    case ActionType.forecast_setForecastPlanOnSave:
      state.forecast.forecastPlan = action.payload.forecastPlan
      return state
    case ActionType.forecast_setIsFuture:
      state.forecast.isFuture = action.payload
      return state
    case ActionType.forecast_setSelectedPlanDateIndex:
      state.forecast.selectedPlanDateIndex = action.payload
      return state
    case ActionType.forecast_setEditing:
      state.forecast.editing = action.payload
      return state
    case ActionType.forecast_setForecastSaveModal:
      state.forecast.isSaveModalOpen = action.payload
      return state
    case ActionType.forecast_setSalesForecastValue:
      state.forecast.forecastPlan.forecastPlannerGroups[action.payload.plannerGroupIndex]
        .items[action.payload.planItemIndex].totalSalesForecastN1 = action.payload.value
      return state
    case ActionType.forecast_setIsOpen:
      state.forecast.forecastPlan.forecastPlannerGroups[
        action.payload.plannerGroupIndex
      ].isOpen = action.payload.value
      return state
    // Ingredients
    case ActionType.ingredient_setIngredients:
      state.ingredients = action.payload
      return state

    default:
      return state
  }
}
